import axios from "axios";
import qs from "qs";
import config from "../config";

interface IURL {
  pk?: string | number;
  action?: string;
  params?: object;
}

interface IRequest<T> extends IURL {
  data?: Partial<T>;
}

const paramsSerializer = (params: object) =>
  qs.stringify(params, { arrayFormat: "repeat" });

const handler = <T>(resource: string, namespace: string | undefined) => {
  /**
   *
   * @param namespace: could contains api version, user's role, module name, or all of them.
   *                   E.g: /admin/core/portfolio, /v2/admin/core/portfolio, /pm/core/portfolio, /v2/pm/core/portfolio
   * @param resource: the resource.
   */

  let { API_BASE_URL: baseURL } = config;
  while (baseURL.endsWith("/")) baseURL = baseURL.slice(0, -1);

  const url =
    namespace === undefined
      ? `${baseURL}/${resource}`
      : `${baseURL}/${namespace}/${resource}`;
  const prefix = url;

  const getURL = ({ pk, action }: Partial<IURL>) => {
    let url = prefix;
    if (pk !== undefined) {
      url = `${url}/${pk}`;
    }
    if (action !== undefined) {
      url = `${url}/${action}`;
    }
    // console.log(url);
    return `${url}/`;
  };

  const retrieve = ({ pk, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.get(url, { params, paramsSerializer });
  };

  const create = ({ data, params }: Partial<IRequest<T>>) => {
    const url = getURL({});
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  const list = ({ params }: Partial<IRequest<T>>) => {
    const url = getURL({});
    return axios.get(url, { params, paramsSerializer });
  };

  const update = ({ pk, data, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.patch(url, { ...data }, { params, paramsSerializer });
  };

  const destroy = ({ pk, params }: IRequest<T>) => {
    const url = getURL({ pk });
    return axios.delete(url, { params, paramsSerializer });
  };

  const getDetailAction = ({ pk, action, params }: IRequest<T>) => {
    const url = getURL({ pk, action });
    return axios.get(url, { params, paramsSerializer });
  };

  const postDetailAction = ({ pk, action, data, params }: IRequest<T>) => {
    const url = getURL({ pk, action });
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  const getListAction = ({ pk, action, params }: Partial<IRequest<T>>) => {
    const url = getURL({ pk, action });
    return axios.get(url, { params, paramsSerializer });
  };

  const postListAction = ({ action, data, params }: Partial<IRequest<T>>) => {
    const url = getURL({ action });
    return axios.post(url, { ...data }, { params, paramsSerializer });
  };

  return {
    retrieve,
    list,
    update,
    destroy,
    create,
    getDetailAction,
    postDetailAction,
    getListAction,
    postListAction,
  };
};
export default handler;
