import {
  Button,
  Form,
  Input,
  Layout,
  DatePicker,
  Spin,
  Row,
  Upload,
  message,
  UploadFile,
  InputNumber,
} from "antd";
import type { GetProp, UploadProps } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import config from "../config";

import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { IRegistrationData } from "../auth/models";

import { useAuth, useRegister } from "../auth";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import FooterDTL from "./footer";
import HeaderDTL from "./header";
import { academic_domains } from "./academic_domains";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

/**
 * Validates if `email` is a valid email AND if `email` has an academic domain.
 *
 * note: callback() is required on ALL exit paths by ant-design
 * https://github.com/ant-design/ant-design/issues/5155
 */
function academicEmailValidator(
  _rule: any,
  email: string | undefined,
  callback: (error?: string) => void,
): void {
  if (email === undefined || email === "") {
    // required=True validates for this case
    callback();
    return;
  }

  // good enough regex pattern
  // https://stackoverflow.com/a/1373724
  const pattern = RegExp(
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@((?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/,
  );

  const result = pattern.exec(email);

  if (result === null) {
    callback("Invalid email format!");
    return;
  }

  const domain = result[1];
  const isAcademicDomain: boolean = academic_domains.some((academic_domain) =>
    domain.endsWith(academic_domain),
  );
  if (!isAcademicDomain) {
    callback(
      "Not an education email! If you believe this is an error, please contact us at careers@dytechlab.com.",
    );
    return;
  }

  callback();
}

const { Content } = Layout;
const App: React.FC = () => {
  const auth = useAuth();
  const register = useRegister();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const from = Object(location.state)?.from?.pathname || "/login";
  const [formRegister] = Form.useForm();

  useEffect(() => {
    formRegister.setFieldsValue({
      contest: register?.contest?.id,
    });
  }, [register, formRegister]);

  const onFinish = (values: IRegistrationData) => {
    register.register(
      values,
      () => {
        setLoading(true);
      },
      () => {
        setLoading(false);
        message.success(
          "Successfully registered! Please check your email for login credentials.",
        );
        navigate(from, { replace: true });
      },
      () => {
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [navigate, from, auth.isAuthenticated]);

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.log("Failed:", errorInfo);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const currentDate = dayjs();
    const maxDate = currentDate.add(3, "year").endOf("year");
    return current && (current < currentDate || current > maxDate);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [fileListResume, setFileListResume] = useState<any[]>([]);

  const onFileChange = (
    info: any,
    setState: React.Dispatch<React.SetStateAction<any[]>>,
  ) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setState((prevFileList: any[]) => [...prevFileList, info.file]);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onDeleteFile = (
    file: UploadFile,
    type: string,
    setState: React.Dispatch<React.SetStateAction<any[]>>,
  ) => {
    const fileUuid = file?.response?.uuid;

    // Call the delete API with the file UUID
    if (fileUuid) {
      fetch(`${config.API_BASE_URL}/api/upload/${type}/${fileUuid}/`, {
        method: "DELETE",
        // Add necessary headers or authentication if required
      })
        .then((response) => {
          if (response.ok) {
            // File deleted successfully
            console.log("File deleted successfully");
            message.success(`${file?.name} file deleted successfully`);

            // Update the fileList state by filtering out the removed file
            setState((prevFileList: any[]) =>
              prevFileList.filter((f) => f.uid !== fileUuid),
            );
          } else {
            console.log("Cannot delete file");
            message.error(`${file?.name} file deletion failed.`);
          }
        })
        .catch((error) => {
          console.log("Cannot delete file");
          message.error(`${file?.name} file deletion failed. Error: ${error}`);
        });
    }
  };

  const currentDate = new Date();

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Spin spinning={loading}>
        <HeaderDTL />
        <Content>
          <div className="registration-page">
            <div className="registration-box">
              {currentDate < register.registrationStartDate ? (
                <div style={{ margin: "auto" }}>
                  <p>
                    You can only register after{" "}
                    {register.registrationStartDate.toLocaleString()}.
                  </p>
                </div>
              ) : register.registrationEndDate < currentDate ? (
                <div style={{ margin: "auto" }}>
                  <p>
                    Registration has already ended, please{" "}
                    <Link to="/quant/login" className="login-link">
                      login here
                    </Link>{" "}
                    if you have registered.
                  </p>
                </div>
              ) : (
                <Form
                  form={formRegister}
                  name="registration-form"
                  initialValues={{
                    contest: register?.contest?.id,
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  encType="multipart/form-data"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: register?.contest?.brief_description,
                    }}
                  ></div>
                  <div className="already-signed-in">
                    Already registered?{" "}
                    <Link to="/quant/login" className="login-link">
                      Login
                    </Link>
                  </div>
                  <div className="registration-mandatory-fields-message">
                    Fields marked with a{" "}
                    <span className="registration-red-asterisk">*</span> are
                    mandatory and must be completed before submittal.
                  </div>

                  <Form.Item name="contest" hidden={true}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" />
                  </Form.Item>

                  <Form.Item
                    name="educational_email_address"
                    label="Educational Institution supplied email (i.e. .edu, .ac)"
                    extra="Make sure you can access the inbox as login details will be sent to this email"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please input your Educational email!",
                      },
                      {
                        validator: academicEmailValidator,
                      },
                    ]}
                  >
                    <Input placeholder="Your college email" />
                  </Form.Item>

                  <Form.Item
                    name="location" // for compatibility with backend
                    label="Country"
                    rules={[
                      {
                        required: true,
                        message: "Please input your country of study!",
                      },
                    ]}
                  >
                    <Input placeholder="Your country of study" />
                  </Form.Item>

                  <Form.Item
                    name="university"
                    label="University"
                    rules={[
                      {
                        required: true,
                        message: "Please input your University!",
                      },
                    ]}
                  >
                    <Input placeholder="Your University" />
                  </Form.Item>

                  <Form.Item
                    name="major"
                    label="Major"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Major!",
                      },
                    ]}
                  >
                    <Input placeholder="Your Major" />
                  </Form.Item>

                  <Form.Item
                    name="gpa"
                    label="GPA (percentage)"
                    rules={[
                      {
                        required: true,
                        message: "Please input your GPA as a percentage!",
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      max={100}
                      decimalSeparator={"."}
                      step={0.1}
                      placeholder="Your GPA as a percentage"
                      addonAfter="%"
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    name="graduation_year"
                    label="Graduation Date"
                    rules={[
                      {
                        type: "object" as const,
                        required: true,
                        message: "Please input your college graduation date!",
                      },
                    ]}
                  >
                    <DatePicker
                      picker="month"
                      placeholder="Select year"
                      disabledDate={disabledDate}
                    />
                  </Form.Item>

                  <Form.Item
                    name="resume"
                    label="Upload Resume (Max size 2MB)"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const resumeFiles = getFieldValue("resume");

                          const hasDoneFiles =
                            resumeFiles &&
                            resumeFiles.some(
                              (file: { status: string }) =>
                                file.status === "done",
                            );
                          if (!hasDoneFiles) {
                            return Promise.reject(
                              new Error(
                                "Please successfully upload one file for resume.",
                              ),
                            );
                          }

                          if (!value && !resumeFiles) {
                            return Promise.reject(
                              new Error(
                                "Please upload at least one file for resume.",
                              ),
                            );
                          }

                          return Promise.resolve();
                        },
                      }),
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Upload
                      maxCount={1}
                      accept=".pdf,.doc,.docx,.png,.jpeg,.jpg"
                      fileList={fileListResume}
                      onChange={(info) => onFileChange(info, setFileListResume)}
                      action={`${config.API_BASE_URL}/api/upload/resume/`} // Update the action to the corresponding URL
                      progress={{
                        strokeColor: {
                          "0%": "#108ee9",
                          "100%": "#87d068",
                        },
                        strokeWidth: 3,
                        format: (percent) =>
                          percent && `${parseFloat(percent.toFixed(2))}%`,
                      }}
                      onRemove={(file) =>
                        onDeleteFile(file, "resume", setFileListResume)
                      }
                      beforeUpload={(file: FileType): boolean => {
                        const limit = 2 * 1024 * 1024; // 2MB
                        if (file.size > limit) {
                          message.error(
                            "This file is too big. The maximum size is 2MB.",
                          );
                          return false;
                        }

                        return true;
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    <Row>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="registration-form-button"
                      >
                        SUBMIT
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
              )}
            </div>
          </div>
        </Content>
      </Spin>
      <FooterDTL />
    </Layout>
  );
};

export default App;
