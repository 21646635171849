import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LineChartOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  FloatButton,
  Form,
  Input,
  message,
  Rate,
  Row,
} from "antd";

import "../common/styles.css";

import Layout, { Content } from "antd/es/layout/layout";
import { useAuth } from "../../auth";
import config from "../../config";
import FooterDTL from "../../layout/footer";
import Header from "../../layout/header";
import { axiosErrorHandler } from "../../utilities";
import {
  CodeView,
  CountDownView,
  SubmissionResultView,
  SubmissionTableView,
} from "../common/components";
import { SubmissionProps } from "./models";
import { contestFeedbackSubmission, insampleSubmission } from "./resources";

const ContestView: React.FC<{}> = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const contestStartDate = auth.contestStartDate;
  const contestEndDate = auth.contestEndDate;
  const currentDate = new Date();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [defaultCode, setDefaultCode] = useState<string>("");
  const [form] = Form.useForm();
  const [dataResultsSubmission, setDataResultsSubmission] = useState<any>();
  const [typeSubmission, setTypeSubmission] = useState<string>();
  const resultsRef = React.useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    // Fetch the content of the default.py file
    setLoading(true);
    fetch(`${config.API_BASE_URL}/django_static/python/default.py`)
      .then((response) => response.text())
      .then((data) => setDefaultCode(data))
      .catch((error) => message.error("Error fetching default code:", error));
    setLoading(false);
  }, [dataResultsSubmission, loadingSubmit, resultsRef]);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      contestFeedbackSubmission.create({
        data: { ...values, user: auth.userName, contest: auth.contestName },
      });
      message.success("Feedback submitted successfully!");
      navigate("/quant/public_leaderboard");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      message.error("Error submitting feedback. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleTypeSubmission = (type: string): void => {
    setTypeSubmission(type);
  };
  const handleLoadingSubmit = (values: boolean): void => {
    setLoadingSubmit(values);
  };

  const handleSkip = () => {
    navigate("/quant/public_leaderboard");
  };

  const getDataSubmission = (data: any): void => {
    setDataResultsSubmission(data);
  };

  const handleBackResults = (): void => {
    if (resultsRef) {
      setTimeout(
        () => resultsRef.current?.scrollIntoView({ behavior: "smooth" }),
        0,
      );
    }
  };

  return (
    <>
      {contestStartDate > currentDate ? (
        <CountDownView props={{ deadline: contestStartDate }} />
      ) : (
        <Layout style={{ height: "100vh" }}>
          <Header></Header>
          {currentDate <= contestEndDate ? (
            <Content style={{ overflow: "hidden", overflowY: "scroll" }}>
              <Col
                span={20}
                offset={2}
                style={{
                  height: "95%",
                  marginTop: "1rem",
                  boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CodeView
                  props={{
                    view: "contest",
                    data: dataResultsSubmission,
                    default: defaultCode,
                    username: auth.userName,
                    dataSubmission: getDataSubmission,
                    loading_submit: handleLoadingSubmit,
                    typeSubmit: handleTypeSubmission,
                    backToResults: handleBackResults,
                  }}
                />
              </Col>
              <Col
                span={20}
                offset={2}
                ref={resultsRef}
                style={{
                  boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                }}
              >
                {dataResultsSubmission && (
                  <SubmissionResultView
                    props={{
                      title: "Insample Results",
                      is_running: false, // dataResultsSubmission short circuit hides the entire View
                      has_data: true,
                      cumpnl: dataResultsSubmission?.cumpnl,
                      score: dataResultsSubmission?.score,
                      stdout: dataResultsSubmission?.stdout,
                      stderr: dataResultsSubmission?.error_output,
                      show_log: true,
                    }}
                  />
                )}

                {dataResultsSubmission &&
                  typeSubmission &&
                  typeSubmission === "outsample" && (
                    <SubmissionResultView
                      props={{
                        title: "Outsample Results",
                        is_running: false, // dataResultsSubmission short circuit hides the entire View
                        has_data: true,
                        score: dataResultsSubmission?.outsample_score,
                        cumpnl: dataResultsSubmission?.outsample_cumpnl,
                      }}
                    />
                  )}
              </Col>
              {dataResultsSubmission && (
                <FloatButton
                  icon={<LineChartOutlined />}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    resultsRef?.current?.scrollIntoView({ behavior: "smooth" });
                  }}
                  style={{ bottom: 56 }}
                  tooltip={<div>Go to results</div>}
                  shape="circle"
                />
              )}
            </Content>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "85vh",
              }}
            >
              <Form
                form={form}
                layout="vertical"
                style={{
                  maxWidth: "500px",
                  width: "95%",
                  padding: "24px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
                onFinish={handleSubmit}
              >
                <h1 style={{ textAlign: "center", marginBottom: "24px" }}>
                  DTL Quant Contest Feedback
                </h1>

                <Form.Item
                  name="rating"
                  label={<b>Rating</b>}
                  labelCol={{ span: 24 }}
                  style={{ textAlign: "center" }}
                  rules={[
                    { required: true, message: "Please provide a rating" },
                  ]}
                >
                  <Rate style={{ fontSize: "60px" }} />
                </Form.Item>

                <Form.Item
                  name="feedback"
                  label={<b>Feedback</b>}
                  labelCol={{ span: 24 }}
                  rules={[
                    { required: true, message: "Please provide feedback" },
                  ]}
                >
                  <Input.TextArea rows={6} placeholder="Enter your feedback" />
                </Form.Item>

                <Form.Item>
                  <Button
                    style={{ margin: "2px", width: "48%" }}
                    onClick={handleSkip}
                  >
                    Skip
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: "48%", margin: "2px" }}
                  >
                    Submit Feedback
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          <FooterDTL />
        </Layout>
      )}
    </>
  );
};

const SubmissionView: React.FC = () => {
  const auth = useAuth();

  const { id } = useParams();
  const contestStartDate = auth.contestStartDate;
  const currentDate = new Date();
  const [data, setData] = useState<SubmissionProps>();

  useEffect(() => {
    insampleSubmission
      .retrieve({ pk: String(id) })
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        axiosErrorHandler(err);
      });
  }, [id]);
  const resultsRef = React.useRef<null | HTMLInputElement>(null);
  const handleBackResults = (): void => {
    if (resultsRef) {
      setTimeout(
        () => resultsRef.current?.scrollIntoView({ behavior: "smooth" }),
        0,
      );
    }
  };

  const getDataSubmission = (data: any): void => {
    setData(data);
  };

  return (
    <>
      {contestStartDate > currentDate ? (
        <CountDownView props={{ deadline: contestStartDate }} />
      ) : (
        <Layout style={{ height: "100vh" }}>
          <Header></Header>
          <Content style={{ overflow: "hidden", overflowY: "scroll" }}>
            <Row style={{ height: "100%" }}>
              <Col
                span={20}
                offset={2}
                style={{
                  height: "95%",
                  marginTop: "1rem",
                  boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CodeView
                  props={{
                    view: "submission",
                    default: data?.code,
                    button_type: "outsample",
                    data: data,
                    username: auth.userName,
                    dataSubmission: getDataSubmission,
                    backToResults: handleBackResults,
                  }}
                />
              </Col>
              <Col
                span={20}
                offset={2}
                ref={resultsRef}
                style={{
                  height: "95%",
                  boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
                }}
              >
                <SubmissionResultView
                  props={{
                    title: "Insample Results",
                    is_running: ["running_insample", undefined].includes(
                      data?.submission_status,
                    ),
                    has_data: true,
                    cumpnl: data?.cumpnl,
                    score: data?.score,
                    stdout: data?.stdout,
                    stderr: data?.error_output,
                    show_log: true,
                  }}
                />
                {data?.submission_type === "outsample" && (
                  <SubmissionResultView
                    props={{
                      title: "Outsample Results",
                      is_running: [
                        "running_insample",
                        "complete_insample",
                        "running_outsample",
                        undefined,
                      ].includes(data?.submission_status),
                      has_data: true,
                      cumpnl: data?.outsample_cumpnl,
                      score: data?.outsample_score,
                      // intentionally don't show logs for outsample
                    }}
                  />
                )}
              </Col>
            </Row>
          </Content>
          <FloatButton
            icon={<LineChartOutlined />}
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              resultsRef?.current?.scrollIntoView({ behavior: "smooth" });
            }}
            style={{ bottom: 56 }}
            tooltip={<div>Go to results</div>}
            shape="circle"
          />
          <FooterDTL />
        </Layout>
      )}
    </>
  );
};

const SubmissionList: React.FC = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Header></Header>
      <Content style={{ overflow: "auto" }}>
        <Col
          span={20}
          offset={2}
          style={{
            height: "100%",
            boxShadow: "0 0 30px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <SubmissionTableView props={{ type: "insample" }} />
          </div>
        </Col>
      </Content>
      <FooterDTL />
    </Layout>
  );
};

export { ContestView, SubmissionList, SubmissionView };
