import React, { useEffect } from "react";

import { axiosErrorHandler } from "../utilities";
import { IBasicAuth } from "./models";
import {
  basicAuthRestful,
  basicLogoutRestful,
  tokenRestful,
} from "./resources";

import { AxiosError } from "axios";

type EvaluationState = "none" | "running" | "done";
const EvaluationStateTable = {
  1: "none",
  2: "running",
  3: "done",
} as const;

interface AuthContextType {
  signin: (
    basicAuth: IBasicAuth,
    beforeSignIn: VoidFunction,
    callback: VoidFunction,
  ) => Promise<void>;
  signout: (
    BeforeSignOut: VoidFunction,
    callback: VoidFunction,
  ) => Promise<void>;
  isAuthenticated: boolean | null;
  contestStartDate: Date;
  contestEndDate: Date;
  contestGracePeriodEndDate: Date;
  contestName: string;
  evaluationState: EvaluationState;
  userName: string;
  showPrivateLeaderboard: boolean;
}

const defaultAuthContext: AuthContextType = {
  signin: async () => {
    console.log("dummy signin");
  },
  signout: async () => {
    console.log("dummy signout");
  },
  isAuthenticated: null,
  contestStartDate: new Date(),
  contestEndDate: new Date(),
  contestGracePeriodEndDate: new Date(),
  contestName: "DTL Quant Challenge@2024",
  userName: "",
  evaluationState: "none",
  showPrivateLeaderboard: false,
};

const AuthContext = React.createContext<AuthContextType>(defaultAuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean | null>(
    null,
  );
  const [userName, setUserName] = React.useState<string>("");
  const [contestName, setContestName] = React.useState<string>(
    "DTL Quant Challenge@2024",
  );
  const [contestStartDate, setContestStartDate] = React.useState<Date>(
    new Date(),
  );
  const [contestEndDate, setContestEndDate] = React.useState<Date>(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
  ); // the registration end date is not fetchable then the end date will always be current date + 2 days, which means the registration never ends
  const [contestGracePeriodEndDate, setContestGracePeriodEndDate] =
    React.useState<Date>(
      new Date(Date.now() + 1000 * 60 * 60 * 24 * 2 + 15 * 60000),
    ); // set to 15 minutes after end date
  const [evaluationState, setEvaluationState] =
    React.useState<EvaluationState>("none");
  const [showPrivateLeaderboard, setShowPrivateLeaderboard] =
    React.useState<boolean>(false);

  useEffect(() => {
    const verify = async () => {
      try {
        const res = await tokenRestful.list({});
        setIsAuthenticated(true);
        setUserName(res.data.first_name);
        setContestName(res.data.contest.name);
        setContestStartDate(new Date(res.data.contest.contest_start_date));
        setContestEndDate(new Date(res.data.contest.contest_end_date));
        setContestGracePeriodEndDate(
          new Date(res.data.contest.contest_grace_period_end_date),
        );
        setEvaluationState(
          EvaluationStateTable[
            res.data.contest.evaluation_state as 1 | 2 | 3
          ] ?? "none",
        );
        setShowPrivateLeaderboard(
          res.data.contest.show_private_leaderboard ?? false,
        );
      } catch (e) {
        setIsAuthenticated(false);
        // const err = e as AxiosError;
        // console.error(err);
      }
    };

    verify();
  }, []);

  const signin = async (
    basicAuth: IBasicAuth,
    beforeSignIn: VoidFunction,
    callback: VoidFunction,
  ) => {
    beforeSignIn();
    try {
      const res = await basicAuthRestful.create({ data: basicAuth });
      setIsAuthenticated(true);
      setUserName(res.data.user.first_name);
      setContestName(res.data.user.contest.name);
      setContestStartDate(new Date(res.data.user.contest.contest_start_date));
      setContestEndDate(new Date(res.data.user.contest.contest_end_date));
      setContestGracePeriodEndDate(
        new Date(res.data.user.contest.contest_grace_period_end_date),
      );
      setEvaluationState(
        EvaluationStateTable[
          res.data.user.contest.evaluation_state as 1 | 2 | 3
        ] ?? "none",
      );
      setShowPrivateLeaderboard(
        res.data.user.contest.show_private_leaderboard ?? false,
      );
    } catch (e) {
      setIsAuthenticated(false);
      const err = e as AxiosError;
      console.error(err);
      axiosErrorHandler(err);
    } finally {
      callback();
    }
  };

  const signout = async (
    beforeSignOut: VoidFunction,
    callback: VoidFunction,
  ) => {
    beforeSignOut();
    try {
      await basicLogoutRestful.create({});
      setIsAuthenticated(false);
    } catch (e) {
      setIsAuthenticated(true);
      const err = e as AxiosError;
      console.error(err);
      axiosErrorHandler(err);
    } finally {
      callback();
    }
  };

  const value = {
    isAuthenticated,
    signin,
    signout,
    contestStartDate,
    contestEndDate,
    contestGracePeriodEndDate,
    contestName,
    userName,
    evaluationState,
    showPrivateLeaderboard,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
