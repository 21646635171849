import { AxiosError } from "axios";
import { message } from "antd";

const errorHandler = (error: AxiosError) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const errors: any = error.response.data;
    if (typeof errors === "string") {
      message.error(errors);
      return;
    }

    for (const key in errors) {
      if (typeof errors[key] === "object" && errors[key].length > 0) {
        for (const err_msg of errors[key]) {
          message.error(`${err_msg}`);
        }
      } else {
        message.error(`${errors[key]}`);
      }
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    message.error(`No response was received: ${error.request}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
    message.error(
      `Something happened in setting up the request that triggered an Error: ${error.message}`,
    );
  }
};
export default errorHandler;
