const extra_domains = [
  "u.nus.edu",
  "dytechlab.com",
  "edu",
  "ac.in",
  "edu.vn",
  "edu.in",
  "ac.ir",
  "sharif.ir",
  "edu.pl",
  "ac.kr",
  "ac.rs",
  "bme.hu",
  "elte.hu",
  "ens-paris-saclay.fr",
];

// from: https://github.com/Hipo/university-domains-list/tree/master
// curl -H "Accept: appliciation/json" https://raw.githubusercontent.com/Hipo/university-domains-list/master/world_universities_and_domains.json | jq "[ .[].domains ] | flatten" > list.txt
export const academic_domains = extra_domains.concat([
  "unochapeco.edu.br",
  "student.westherts.ac.uk",
  "westherts.ac.uk",
  "insa-toulouse.fr",
  "bugemauniv.ac.ug",
  "mbzuai.ac.ae",
  "sempreceub.com",
  "uniceub.br",
  "student.karazin.ua",
  "karazin.ua",
  "usm.cl",
  "ieseg.fr",
  "mail2.sysu.edu.cn",
  "mail.sysu.edu.cn",
  "rhul.ac.uk",
  "hnu.edu.cn",
  "www-en.hnu.edu.cn",
  "myport.ac.uk",
  "myport.port.ac.uk",
  "ac-paris.fr",
  "ac-aix-marseille.fr",
  "ac-creteil.fr",
  "marywood.edu",
  "upes.ac.in",
  "ashoka.edu.in",
  "iiits.in",
  "cstj.qc.ca",
  "lindenwood.edu",
  "davietjal.org",
  "lpu.in",
  "sullivan.edu",
  "fscj.edu",
  "xavier.edu",
  "tusculum.edu",
  "cst.edu",
  "somaiya.edu",
  "columbiasc.edu",
  "clpccd.edu",
  "keller.edu",
  "monroecollege.edu",
  "smccd.edu",
  "losrios.edu",
  "digipen.edu",
  "upmc.edu",
  "upmc.com",
  "cgu.edu",
  "utrgv.edu",
  "mountsaintvincent.edu",
  "uasys.edu",
  "ecpi.edu",
  "umw.edu",
  "bw.edu",
  "csuci.edu",
  "brandman.edu",
  "uscga.edu",
  "athens.edu",
  "scripps.edu",
  "easternflorida.edu",
  "wne.edu",
  "king.edu",
  "ggc.edu",
  "trident.edu",
  "alliant.edu",
  "mvsu.edu",
  "roosevelt.edu",
  "itt-tech.info",
  "itt-tech.edu",
  "iecc.edu",
  "park.edu",
  "mssm.edu",
  "uvu.edu",
  "wlc.edu",
  "rccd.edu",
  "wakehealth.edu",
  "umb.edu",
  "floridapoly.edu",
  "wagner.edu",
  "wilmu.edu",
  "itu.edu",
  "yhc.edu",
  "findlay.edu",
  "pcom.edu",
  "yosemite.edu",
  "coastalalabama.edu",
  "pnw.edu",
  "columbiabasin.edu",
  "seattlecolleges.edu",
  "lipscomb.edu",
  "tiffin.edu",
  "ncuindia.edu",
  "troy.edu",
  "student.42.us.org",
  "calbaptist.edu",
  "stevenson.edu",
  "mbl.edu",
  "maryville.edu",
  "aii.edu",
  "umc.edu",
  "uco.edu",
  "hallmarkuniversity.edu",
  "keiseruniversity.edu",
  "sjrstate.edu",
  "irsc.edu",
  "barnard.edu",
  "calsouthern.edu",
  "laverne.edu",
  "washjeff.edu",
  "uopeople.edu",
  "touro.edu",
  "njcu.edu",
  "lander.edu",
  "sunymaritime.edu",
  "lesley.edu",
  "lauruscollege.edu",
  "np.edu",
  "oc.edu",
  "sdcc.edu",
  "sans.edu",
  "phsc.edu",
  "ncf.edu",
  "gcu.edu",
  "cshl.edu",
  "asurams.edu",
  "lasell.edu",
  "4cd.edu",
  "msmary.edu",
  "robertmorris.edu",
  "nwfsc.edu",
  "wvwc.edu",
  "utica.edu",
  "ccnn.edu",
  "csn.edu",
  "ung.edu",
  "principia.edu",
  "lynn.edu",
  "cccs.edu",
  "valenciacollege.edu",
  "spcollege.edu",
  "msbcollege.edu",
  "everest.edu",
  "swri.edu",
  "apus.edu",
  "fullsail.edu",
  "yti.edu",
  "dliflc.edu",
  "columbusstate.edu",
  "moreheadstate.edu",
  "csuglobal.edu",
  "ndus.edu",
  "uhd.edu",
  "stratford.edu",
  "cpp.edu",
  "baker.edu",
  "tcnj.edu",
  "acu.edu",
  "adelphi.edu",
  "agnesscott.edu",
  "atc.edu",
  "afit.edu",
  "aamu.edu",
  "alasu.edu",
  "aloma.edu",
  "acofi.edu",
  "albion.edu",
  "alfred.edu",
  "alleg.edu",
  "allencol.edu",
  "alma.edu",
  "alverno.edu",
  "ambassador.edu",
  "amercoastuniv.edu",
  "aic.edu",
  "american.edu",
  "amherst.edu",
  "andrews.edu",
  "angelo.edu",
  "aacc.edu",
  "antiochne.edu",
  "antioch.edu",
  "antiochla.edu",
  "seattleantioch.edu",
  "appstate.edu",
  "aquinas.edu",
  "arcadia.edu",
  "asu.edu",
  "azwestern.edu",
  "astate.edu",
  "atu.edu",
  "armstrong.edu",
  "ashland.edu",
  "assumption.edu",
  "auburn.edu",
  "aum.edu",
  "augsburg.edu",
  "augustana.edu",
  "augie.edu",
  "augusta.edu",
  "gru.edu",
  "aurora.edu",
  "austincollege.edu",
  "austincc.edu",
  "apsu.edu",
  "averett.edu",
  "avila.edu",
  "apu.edu",
  "babson.edu",
  "bakeru.edu",
  "bsu.edu",
  "bbc.edu",
  "bard.edu",
  "barry.edu",
  "bastyr.edu",
  "bates.edu",
  "bcm.edu",
  "baylor.edu",
  "bellevue.edu",
  "belmont.edu",
  "beloit.edu",
  "bemidjistate.edu",
  "benedictine.edu",
  "bennington.edu",
  "bentley.edu",
  "berea.edu",
  "berklee.edu",
  "bethanywv.edu",
  "bethelks.edu",
  "bethel.edu",
  "biola.edu",
  "bsc.edu",
  "bhsu.edu",
  "bloomu.edu",
  "bluffton.edu",
  "bju.edu",
  "boisestate.edu",
  "bc.edu",
  "bgsp.edu",
  "bu.edu",
  "bowdoin.edu",
  "bowiestate.edu",
  "bgsu.edu",
  "bradley.edu",
  "brandeis.edu",
  "brenau.edu",
  "briar-cliff.edu",
  "bridgewater.edu",
  "byu.edu",
  "byui.edu",
  "byuh.edu",
  "broward.edu",
  "brown.edu",
  "bryant.edu",
  "brynmawr.edu",
  "bucknell.edu",
  "bvu.edu",
  "butler.edu",
  "calcoast.edu",
  "caltech.edu",
  "callutheran.edu",
  "csum.edu",
  "cnuas.edu",
  "calpoly.edu",
  "csub.edu",
  "csubak.edu",
  "csuchico.edu",
  "csudh.edu",
  "csufresno.edu",
  "fresnostate.edu",
  "fullerton.edu",
  "csuhayward.edu",
  "csulb.edu",
  "calstatela.edu",
  "csumb.edu",
  "csun.edu",
  "csus.edu",
  "csusb.edu",
  "csusm.edu",
  "csustan.edu",
  "calstate.edu",
  "cup.edu",
  "calvin.edu",
  "cameron.edu",
  "campbellsville.edu",
  "campbell.edu",
  "canisius.edu",
  "capella.edu",
  "capellauniversity.edu",
  "captechu.edu",
  "carleton.edu",
  "carlow.edu",
  "cmu.edu",
  "carroll.edu",
  "carrollu.edu",
  "cn.edu",
  "carthage.edu",
  "case.edu",
  "castleton.edu",
  "cua.edu",
  "cedarville.edu",
  "centenary.edu",
  "central.edu",
  "ccsu.edu",
  "cmc.edu",
  "cmich.edu",
  "cwu.edu",
  "centre.edu",
  "csc.edu",
  "champlain.edu",
  "chapman.edu",
  "chatham.edu",
  "chesapeake.edu",
  "cheyney.edu",
  "csopp.edu",
  "cbu.edu",
  "cts.edu",
  "cnu.edu",
  "cityu.edu",
  "cuny.edu",
  "cgs.edu",
  "claremontmckenna.edu",
  "clarion.edu",
  "clarke.edu",
  "clarkson.edu",
  "clarku.edu",
  "clayton.edu",
  "clemson.edu",
  "csuohio.edu",
  "clinch.edu",
  "coastal.edu",
  "cccd.edu",
  "coe.edu",
  "coker.edu",
  "colby.edu",
  "colgate.edu",
  "cofc.edu",
  "ceu.edu",
  "cnr.edu",
  "csbsju.edu",
  "stkate.edu",
  "strose.edu",
  "stfrancis.edu",
  "css.edu",
  "coa.edu",
  "holycross.edu",
  "wm.edu",
  "wooster.edu",
  "ccu.edu",
  "coloradocollege.edu",
  "coloradomesa.edu",
  "mines.edu",
  "colostate.edu",
  "coloradotech.edu",
  "ctuonline.edu",
  "cuanschutz.edu",
  "colum.edu",
  "colsouth.edu",
  "cuc.edu",
  "columbia.edu",
  "ccbcmd.edu",
  "ccaa.edu",
  "cord.edu",
  "ccsn.edu",
  "csp.edu",
  "conncoll.edu",
  "ct.edu",
  "cooper.edu",
  "coppin.edu",
  "allencollege.edu",
  "cornell-iowa.edu",
  "cornell.edu",
  "corning-cc.edu",
  "creighton.edu",
  "curry.edu",
  "daemen.edu",
  "dsu.edu",
  "dwu.edu",
  "dbu.edu",
  "dcccd.edu",
  "dana.edu",
  "dwc.edu",
  "dartmouth.edu",
  "davenport.edu",
  "davidson.edu",
  "dne.wvnet.edu",
  "daytonastate.edu",
  "desu.edu",
  "deltastate.edu",
  "denison.edu",
  "depaul.edu",
  "depauw.edu",
  "devry.edu",
  "ddu.ac.in",
  "dickinson.edu",
  "dickinsonstate.edu",
  "dillard.edu",
  "dominican.edu",
  "dordt.edu",
  "dowling.edu",
  "drake.edu",
  "drew.edu",
  "drexel.edu",
  "drury.edu",
  "duq.edu",
  "earlham.edu",
  "ecu.edu",
  "ecok.edu",
  "easternct.edu",
  "eiu.edu",
  "eku.edu",
  "emu.edu",
  "emich.edu",
  "enmu.edu",
  "eou.edu",
  "ewu.edu",
  "esu.edu",
  "etsu.edu",
  "edgewood.edu",
  "edinboro.edu",
  "ecsu.edu",
  "etown.edu",
  "elmhurst.edu",
  "elon.edu",
  "erau.edu",
  "emerson.edu",
  "emmanuel.edu",
  "emory.edu",
  "ehc.edu",
  "emporia.edu",
  "ecc.edu",
  "lecom.edu",
  "etbu.edu",
  "evergreen.edu",
  "fdu.edu",
  "fairmontstate.edu",
  "uncfsu.edu",
  "ferris.edu",
  "fielding.edu",
  "fisk.edu",
  "fsc.edu",
  "famu.edu",
  "fau.edu",
  "fgcu.edu",
  "fit.edu",
  "fiu.edu",
  "fsu.edu",
  "fontbonne.edu",
  "fordham.edu",
  "fhsu.edu",
  "fortlewis.edu",
  "fountainheadcollege.edu",
  "fandm.edu",
  "fplc.edu",
  "franklin.edu",
  "frederick.edu",
  "fresno.edu",
  "friends.edu",
  "frostburg.edu",
  "fuller.edu",
  "furman.edu",
  "futureworks.ac.uk",
  "gallaudet.edu",
  "gannon.edu",
  "geneva.edu",
  "gfc.edu",
  "gmu.edu",
  "georgetown.edu",
  "gwu.edu",
  "gcsu.edu",
  "gatech.edu",
  "georgian.edu",
  "georgiasouthern.edu",
  "gsw.edu",
  "gsu.edu",
  "gettysburg.edu",
  "gmi.edu",
  "ggu.edu",
  "gbc.edu",
  "gonzaga.edu",
  "goshen.edu",
  "goucher.edu",
  "govst.edu",
  "grace.edu",
  "graceland.edu",
  "gvsu.edu",
  "greenleaf.edu",
  "grinnell.edu",
  "guilford.edu",
  "gac.edu",
  "gustavus.edu",
  "hagerstowncc.edu",
  "hamilton.edu",
  "hamline.edu",
  "hsc.edu",
  "hampshire.edu",
  "hamptonu.edu",
  "hanover.edu",
  "harding.edu",
  "harford.edu",
  "hartwick.edu",
  "harvard.edu",
  "hmc.edu",
  "haskell.edu",
  "hastings.edu",
  "haverford.edu",
  "hpu.edu",
  "heidelberg.edu",
  "hendrix.edu",
  "hesston.edu",
  "highpoint.edu",
  "hillsdale.edu",
  "hiram.edu",
  "hws.edu",
  "hofstra.edu",
  "hollins.edu",
  "hood.edu",
  "hope.edu",
  "howardcc.edu",
  "howard.edu",
  "humboldt.edu",
  "huntingdon.edu",
  "huntington.edu",
  "ici.edu",
  "isu.edu",
  "ibc.edu",
  "iit.edu",
  "illinoisstate.edu",
  "ilstu.edu",
  "indianatech.edu",
  "indianatech.net",
  "indstate.edu",
  "iu.edu",
  "indiana.edu",
  "iub.edu",
  "bloomington.iu.edu",
  "iusb.edu",
  "iup.edu",
  "iupuc.edu",
  "ipfw.edu",
  "iupui.edu",
  "ius.edu",
  "indwes.edu",
  "iwp.edu",
  "iona.edu",
  "iastate.edu",
  "ivc.edu",
  "ithaca.edu",
  "jsums.edu",
  "jsu.edu",
  "ju.edu",
  "jmu.edu",
  "jbu.edu",
  "jfku.edu",
  "jh.edu",
  "jhu.edu",
  "johnshopkins.edu",
  "jhuapl.edu",
  "jwu.edu",
  "jbc.edu",
  "jcsu.edu",
  "jones.edu",
  "judson.edu",
  "juniata.edu",
  "kzoo.edu",
  "ksu.edu",
  "k-state.edu",
  "kwu.edu",
  "kaplan.edu",
  "kean.edu",
  "keene.edu",
  "kennesaw.edu",
  "kent.edu",
  "kctcs.edu",
  "kenyon.edu",
  "kettering.edu",
  "kings.edu",
  "knox.edu",
  "kutztown.edu",
  "lafayette.edu",
  "lagrange.edu",
  "lfc.edu",
  "lsc.edu",
  "lssu.edu",
  "lamar.edu",
  "lambtoncollege.ca",
  "mylambton.ca",
  "langston.edu",
  "lasalle.edu",
  "lasierra.edu",
  "ltu.edu",
  "lawrence.edu",
  "lvc.edu",
  "lehigh.edu",
  "lemoyne.edu",
  "lenoircc.edu",
  "lrc.edu",
  "letu.edu",
  "lclark.edu",
  "lcsc.edu",
  "lewisu.edu",
  "liberty.edu",
  "lincoln.edu",
  "linfield.edu",
  "lhup.edu",
  "lockhaven.edu",
  "llu.edu",
  "liunet.edu",
  "longwood.edu",
  "loras.edu",
  "laccd.edu",
  "lacollege.edu",
  "lsu.edu",
  "lsua.edu",
  "latech.edu",
  "loyola.edu",
  "lmu.edu",
  "luc.edu",
  "luther.edu",
  "luthersem.edu",
  "lycoming.edu",
  "lynchburg.edu",
  "lyndonstate.edu",
  "lyon.edu",
  "macalester.edu",
  "mum.edu",
  "malone.edu",
  "manhattan.edu",
  "mansfield.edu",
  "marietta.edu",
  "marist.edu",
  "marlboro.edu",
  "marquette.edu",
  "marshall.edu",
  "mbc.edu",
  "mmm.edu",
  "marymount.edu",
  "mwc.edu",
  "mit.edu",
  "mcdaniel.edu",
  "mcm.edu",
  "mcneese.edu",
  "mcg.edu",
  "mcw.edu",
  "mccc.edu",
  "mercer.edu",
  "mercymavericks.edu",
  "mercy.edu",
  "mercyhurst.edu",
  "meredith.edu",
  "messiah.edu",
  "mcckc.edu",
  "mccneb.edu",
  "msudenver.edu",
  "denvercollegeofnursing.edu",
  "metrostate.edu",
  "miamioh.edu",
  "msu.edu",
  "mtu.edu",
  "manc.edu",
  "middlebury.edu",
  "mga.edu",
  "mtsu.edu",
  "mwsu.edu",
  "millersville.edu",
  "milligan.edu",
  "millikin.edu",
  "millsaps.edu",
  "msoe.edu",
  "mcad.edu",
  "mnsu.edu",
  "mnstate.edu",
  "minotstateu.edu",
  "mc.edu",
  "msstate.edu",
  "muw.edu",
  "mssc.edu",
  "mst.edu",
  "mwsc.edu",
  "molloy.edu",
  "monmouthcollege.edu",
  "monmouth.edu",
  "msubillings.edu",
  "montana.edu",
  "msun.edu",
  "mtech.edu",
  "montclair.edu",
  "montgomerycollege.edu",
  "montreat.edu",
  "moravian.edu",
  "morgan.edu",
  "mcc.edu",
  "mtholyoke.edu",
  "msj.edu",
  "msmc.edu",
  "mscfs.edu",
  "muc.edu",
  "mursuky.edu",
  "muskingum.edu",
  "nscc.edu",
  "ndu.edu",
  "nl.edu",
  "nrao.edu",
  "nu.edu",
  "nps.edu",
  "naz.edu",
  "nevada.edu",
  "newberry.edu",
  "newcollege.edu",
  "neit.edu",
  "nhc.edu",
  "njit.edu",
  "nmhu.edu",
  "nmt.edu",
  "nmsu.edu",
  "nyit.edu",
  "nyu.edu",
  "niagara.edu",
  "nicholls.edu",
  "nsu.edu",
  "na.edu",
  "northampton.edu",
  "ncat.edu",
  "nccu.edu",
  "ncsu.edu",
  "ncwc.edu",
  "ncbc.edu",
  "ndsu.edu",
  "nlu.edu",
  "nsuok.edu",
  "neu.edu",
  "northeastern.edu",
  "nau.edu",
  "niu.edu",
  "nku.edu",
  "nmu.edu",
  "northern.edu",
  "northland.edu",
  "npcts.edu",
  "nwciowa.edu",
  "nsula.edu",
  "northwestern.edu",
  "nwmissouri.edu",
  "nnc.edu",
  "norwich.edu",
  "nova.edu",
  "oakland.edu",
  "oakwood.edu",
  "oberlin.edu",
  "oxy.edu",
  "odc.edu",
  "onu.edu",
  "osu.edu",
  "ohio.edu",
  "owu.edu",
  "okbu.edu",
  "okcu.edu",
  "okstate.edu",
  "odu.edu",
  "olivet.edu",
  "oru.edu",
  "ogi.edu",
  "ohsu.edu",
  "oit.edu",
  "oregonstate.edu",
  "otterbein.edu",
  "ollusa.edu",
  "pace.edu",
  "plu.edu",
  "puc.edu",
  "pacificu.edu",
  "pwu.com",
  "pbac.edu",
  "palmbeachstate.edu",
  "peace.edu",
  "pembroke.edu",
  "pct.edu",
  "psu.edu",
  "sl.psu.edu",
  "pcci.edu",
  "pensacolastate.edu",
  "pepperdine.edu",
  "peru.edu",
  "pfw.edu",
  "philau.edu",
  "phillips.edu",
  "pittstate.edu",
  "pitzer.edu",
  "platt.edu",
  "plymouth.edu",
  "poly.edu",
  "pupr.edu",
  "pomona.edu",
  "pcc.edu",
  "pdx.edu",
  "pvamu.edu",
  "pratt.edu",
  "presby.edu",
  "prescott.edu",
  "princeton.edu",
  "providence.edu",
  "purdue.edu",
  "purduecal.edu",
  "pnc.edu",
  "quincy.edu",
  "quinnipiac.edu",
  "radford.edu",
  "ramapo.edu",
  "rmc.edu",
  "rmwc.edu",
  "reed.edu",
  "regent.edu",
  "regis.edu",
  "rpi.edu",
  "ric.edu",
  "rhodes.edu",
  "rice.edu",
  "stockton.edu",
  "rider.edu",
  "ripon.edu",
  "riv.edu",
  "roanoke.edu",
  "rmu.edu",
  "rit.edu",
  "rockefeller.edu",
  "rockford.edu",
  "rockhurst.edu",
  "rocky.edu",
  "rwu.edu",
  "rollins.edu",
  "rosary.edu",
  "rose-hulman.edu",
  "rowan.edu",
  "rutgers.edu",
  "sacredheart.edu",
  "mail.sacredheart.edu",
  "sage.edu",
  "svsu.edu",
  "anselm.edu",
  "stcloudstate.edu",
  "stedwards.edu",
  "sfc.edu",
  "stjohns.edu",
  "saintjoe.edu",
  "sju.edu",
  "saintleo.edu",
  "slu.edu",
  "smcks.edu",
  "saintmarys.edu",
  "stmarys-ca.edu",
  "smumn.edu",
  "smcvt.edu",
  "stolaf.edu",
  "stvincent.edu",
  "sxu.edu",
  "salisbury.edu",
  "skc.edu",
  "samford.edu",
  "shsu.edu",
  "sdsu.edu",
  "sfsu.edu",
  "sjsu.edu",
  "scu.edu",
  "slc.edu",
  "saic.edu",
  "seattlecentral.edu",
  "spu.edu",
  "seattleu.edu",
  "shu.edu",
  "sewanee.edu",
  "shawnee.edu",
  "su.edu",
  "ship.edu",
  "shorter.edu",
  "simmons.edu",
  "simons-rock.edu",
  "simpson.edu",
  "sinclair.edu",
  "skidmore.edu",
  "sru.edu",
  "smith.edu",
  "sonoma.edu",
  "sdsmt.edu",
  "sdstate.edu",
  "selu.edu",
  "semo.edu",
  "southern.edu",
  "sct.edu",
  "southernct.edu",
  "siu.edu",
  "siue.edu",
  "smu.edu",
  "snu.edu",
  "snhu.edu",
  "sou.edu",
  "subr.edu",
  "suu.edu",
  "stcl.edu",
  "sbuniv.edu",
  "swac.edu",
  "sagu.edu",
  "sckans.edu",
  "southwestern.edu",
  "smsu.edu",
  "swt.edu",
  "auc.edu",
  "arbor.edu",
  "shc.edu",
  "sau.edu",
  "sapc.edu",
  "stanford.edu",
  "albany.edu",
  "binghamton.edu",
  "buffalo.edu",
  "farmingdale.edu",
  "fredonia.edu",
  "oswego.edu",
  "plattsburgh.edu",
  "stonybrook.edu",
  "fsl.cs.sunysb.edu",
  "cs.sunysb.edu",
  "lmc.cs.sunysb.edu",
  "stonybrookmedicine.edu",
  "brockport.edu",
  "buffalostate.edu",
  "cortland.edu",
  "geneseo.edu",
  "newpaltz.edu",
  "potsdam.edu",
  "cobleskill.edu",
  "esf.edu",
  "alfredtech.edu",
  "sunyit.edu",
  "sunycentral.edu",
  "sbu.edu",
  "sfasu.edu",
  "stephens.edu",
  "stetson.edu",
  "stevens.edu",
  "sjca.edu",
  "sjcsf.edu",
  "sjc.edu",
  "sjcme.edu",
  "stlawu.edu",
  "stlcop.edu",
  "stmartin.edu",
  "smcm.edu",
  "strayer.edu",
  "stu.edu",
  "suffolk.edu",
  "sulross.edu",
  "summitunivofla.edu",
  "susqu.edu",
  "swarthmore.edu",
  "sbc.edu",
  "syr.edu",
  "tabor.edu",
  "tarleton.edu",
  "tayloru.edu",
  "temple.edu",
  "tnstate.edu",
  "tntech.edu",
  "tccd.edu",
  "tamiu.edu",
  "tamu.edu",
  "tamuc.edu",
  "tamucc.edu",
  "tamuk.edu",
  "tcu.edu",
  "tsu.edu",
  "txstate.edu",
  "ttu.edu",
  "ttuhsc.edu",
  "twu.edu",
  "citadel.edu",
  "jtsa.edu",
  "thomas.edu",
  "tesc.edu",
  "tesu.edu",
  "tju.edu",
  "thomasmore.edu",
  "thunderbird.edu",
  "towson.edu",
  "transy.edu",
  "trenton.edu",
  "trine.edu",
  "trincoll.edu",
  "trinitydc.edu",
  "trinity.edu",
  "tsufl.edu",
  "truman.edu",
  "tucsonu.edu",
  "tufts.edu",
  "tulane.edu",
  "tuskegee.edu",
  "usuhs.mil",
  "union.edu",
  "tui.edu",
  "uu.edu",
  "usafa.af.mil",
  "usafa.edu",
  "usiu.edu",
  "usmma.edu",
  "usma.edu",
  "usna.edu",
  "uat.edu",
  "uakron.edu",
  "uab.edu",
  "uah.edu",
  "alaska.edu",
  "uaa.alaska.edu",
  "uaf.edu",
  "uas.alaska.edu",
  "arizona.edu",
  "uark.edu",
  "uafs.edu",
  "uams.edu",
  "ualr.edu",
  "uamont.edu",
  "ubalt.edu",
  "bridgeport.edu",
  "berkeley.edu",
  "ucdavis.edu",
  "uci.edu",
  "ucla.edu",
  "ucmerced.edu",
  "ucop.edu",
  "ucr.edu",
  "ucsd.edu",
  "ucsf.edu",
  "ucsb.edu",
  "ucsc.edu",
  "uca.edu",
  "ucf.edu",
  "ucmo.edu",
  "uchastings.edu",
  "uchaswv.edu",
  "uchicago.edu",
  "uc.edu",
  "colorado.edu",
  "uccs.edu",
  "ucdenver.edu",
  "hsc.colorado.edu",
  "uconn.edu",
  "udallas.edu",
  "udayton.edu",
  "udel.edu",
  "du.edu",
  "udmercy.edu",
  "dbq.edu",
  "evansville.edu",
  "ufl.edu",
  "uga.edu",
  "ugf.edu",
  "hartford.edu",
  "hilo.hawaii.edu",
  "manoa.hawaii.edu",
  "hawaii.edu",
  "uh.edu",
  "uidaho.edu",
  "uic.edu",
  "uis.edu",
  "uiuc.edu",
  "illinois.edu",
  "uindy.edu",
  "uiowa.edu",
  "uj.edu",
  "ku.edu",
  "kumc.edu",
  "uky.edu",
  "louisiana.edu",
  "ulm.edu",
  "louisville.edu",
  "umaine.edu",
  "umf.maine.edu",
  "umfk.maine.edu",
  "umm.maine.edu",
  "umpi.maine.edu",
  "maine.edu",
  "umaryland.edu",
  "mayo.edu",
  "umbc.edu",
  "umd.edu",
  "umes.edu",
  "umuc.edu",
  "umass.edu",
  "umassd.edu",
  "uml.edu",
  "student.uml.edu",
  "memphis.edu",
  "miami.edu",
  "umich.edu",
  "umdearborn.edu",
  "umn.edu",
  "crk.umn.edu",
  "d.umn.edu",
  "mrs.umn.edu",
  "olemiss.edu",
  "missouri.edu",
  "umsystem.edu",
  "umkc.edu",
  "umr.edu",
  "umsl.edu",
  "umt.edu",
  "unk.edu",
  "unl.edu",
  "unomaha.edu",
  "unlv.edu",
  "unr.edu",
  "une.edu",
  "unh.edu",
  "newhaven.edu",
  "unm.edu",
  "uno.edu",
  "unca.edu",
  "unc.edu",
  "ad.unc.edu",
  "uncc.edu",
  "uncg.edu",
  "uncw.edu",
  "und.edu",
  "nodak.edu",
  "unco.edu",
  "uni.edu",
  "unf.edu",
  "unt.edu",
  "hsc.unt.edu",
  "unthsc.edu",
  "nd.edu",
  "ou.edu",
  "uoregon.edu",
  "upenn.edu",
  "seas.upenn.edu",
  "phoenix.edu",
  "email.uophx.edu",
  "pitt.edu",
  "upj.pitt.edu",
  "upb.pitt.edu",
  "up.edu",
  "upr.edu",
  "ups.edu",
  "uor.edu",
  "uri.edu",
  "urich.edu",
  "rochester.edu",
  "stthomas.edu",
  "stthom.edu",
  "sandiego.edu",
  "usfca.edu",
  "usao.edu",
  "scranton.edu",
  "usouixfalls.edu",
  "southalabama.edu",
  "sc.edu",
  "andersonuniversity.edu",
  "usca.edu",
  "usd.edu",
  "usc.edu",
  "isi.edu",
  "usi.edu",
  "usm.maine.edu",
  "usm.edu",
  "usf.edu",
  "usl.edu",
  "ut.edu",
  "utc.edu",
  "utk.edu",
  "utm.edu",
  "uta.edu",
  "utexas.edu",
  "utb.edu",
  "utd.edu",
  "utdallas.edu",
  "utep.edu",
  "utsa.edu",
  "uttyler.edu",
  "utmb.edu",
  "panam.edu",
  "swmed.edu",
  "utsouthwestern.edu",
  "utpb.edu",
  "arts.ac.uk",
  "udc.edu",
  "uiw.edu",
  "uiwtx.edu",
  "ozarks.edu",
  "uop.edu",
  "pacific.edu",
  "utoledo.edu",
  "utulsa.edu",
  "utah.edu",
  "uvm.edu",
  "virginia.edu",
  "uw.edu",
  "washington.edu",
  "westal.edu",
  "uwf.edu",
  "uwec.edu",
  "uwgb.edu",
  "uwlax.edu",
  "wisc.edu",
  "uwm.edu",
  "uwosh.edu",
  "uwp.edu",
  "uwplatt.edu",
  "uwrf.edu",
  "uwsp.edu",
  "uwstout.edu",
  "uwsuper.edu",
  "uwsa.edu",
  "uww.edu",
  "uwyo.edu",
  "uiu.edu",
  "ursinus.edu",
  "usu.edu",
  "uvsc.edu",
  "valdosta.edu",
  "vcsu.nodak.edu",
  "valpo.edu",
  "vgcc.edu",
  "vanderbilt.edu",
  "vassar.edu",
  "vtc.edu",
  "vjc.edu",
  "villanova.edu",
  "vcu.edu",
  "vccs.edu",
  "vic.edu",
  "vmi.edu",
  "vt.edu",
  "vsu.edu",
  "vwc.edu",
  "wabash.edu",
  "wfu.edu",
  "waldenu.edu",
  "wallawalla.edu",
  "walshcollege.edu",
  "warren-wilson.edu",
  "wartburg.edu",
  "wuacc.edu",
  "wlu.edu",
  "bible.edu",
  "washcoll.edu",
  "wsu.edu",
  "vancouver.wsu.edu",
  "wustl.edu",
  "waubonsee.edu",
  "waynesburg.edu",
  "wayne.edu",
  "weber.edu",
  "webster.edu",
  "wellesley.edu",
  "wells.edu",
  "wit.edu",
  "wesleyan.edu",
  "wesley.edu",
  "wcupa.edu",
  "westcoastuniversity.edu",
  "wcu.edu",
  "wcsu.edu",
  "wgu.edu",
  "wiu.edu",
  "wku.edu",
  "wmich.edu",
  "wmc.edu",
  "wnec.edu",
  "wnmu.edu",
  "western.edu",
  "wwu.edu",
  "westfield.mass.edu",
  "westga.edu",
  "wlsc.wvnet.edu",
  "westminster.edu",
  "westminster-mo.edu",
  "wcslc.edu",
  "wts.edu",
  "westmont.edu",
  "wtamu.edu",
  "wvu.edu",
  "westliberty.edu",
  "wheaton.edu",
  "wheatonma.edu",
  "wjc.edu",
  "whitman.edu",
  "whittier.edu",
  "whitworth.edu",
  "wichita.edu",
  "widener.edu",
  "wilberforce.edu",
  "wilkes.edu",
  "willamette.edu",
  "taftu.edu",
  "jewell.edu",
  "wmitchell.edu",
  "wpunj.edu",
  "wmpenn.edu",
  "williams.edu",
  "wmwoods.edu",
  "wilmington.edu",
  "winona.edu",
  "winthrop.edu",
  "wittenberg.edu",
  "wofford.edu",
  "woodbury.edu",
  "wpi.edu",
  "wright.edu",
  "xula.edu",
  "yale.edu",
  "yu.edu",
  "ycp.edu",
  "ysu.edu",
  "afghanuniversity.edu.af",
  "aria.edu.af",
  "auaf.edu.af",
  "au.edu.af",
  "badakhshan.edu.af",
  "ba.edu.af",
  "baghlan.edu.af",
  "bakhtar.edu.af",
  "bost.edu.af",
  "bu.edu.af",
  "dawat.edu.af",
  "dunya.edu.af",
  "faryab.edu.af",
  "gu.edu.af",
  "hu.edu.af",
  "ibnesina.edu.af",
  "ju.edu.af",
  "kaboora.edu.af",
  "kabuli.edu.af",
  "kan.edu.af",
  "kardan.edu.af",
  "karwan.edu.af",
  "kateb.edu.af",
  "ken.edu.af",
  "keu.edu.af",
  "kmu.edu.af",
  "ku.edu.af",
  "kumsa.net",
  "maryam.edu.af",
  "nmaa.edu.af",
  "nu.edu.af",
  "pamir.edu.af",
  "parwan.edu.af",
  "polytechnic-kabul.org",
  "pu.edu.af",
  "rihs.edu.af",
  "sadat.edu.af",
  "salam.edu.af",
  "szu.edu.af",
  "tu.edu.af",
  "aefs.edu.al",
  "akademiaearteve.edu.al",
  "beder.edu.al",
  "epoka.edu.al",
  "uat.edu.al",
  "ubt.edu.al",
  "uniel.edu.al",
  "unipavaresia.edu.al",
  "unishk.edu.al",
  "unitir.edu.al",
  "univlora.edu.al",
  "unkorce.edu.al",
  "unyt.edu.al",
  "uogj.edu.al",
  "upt.al",
  "epitech.al",
  "lagh-univ.dz",
  "umbb.dz",
  "umc.edu.dz",
  "ummto.dz",
  "univ-alger3.dz",
  "univ-alger.dz",
  "univ-annaba.dz",
  "univ-batna.dz",
  "univ-bejaia.dz",
  "univ-biskra.dz",
  "univ-blida.dz",
  "univ-chlef.dz",
  "univ-emir.dz",
  "univ-guelma.dz",
  "univ-jijel.dz",
  "univ-mosta.dz",
  "univ-msila.dz",
  "univ-oeb.dz",
  "univ-oran.dz",
  "univ-ouargla.dz",
  "univ-saida.dz",
  "univ-sba.dz",
  "univ-setif.dz",
  "univ-skikda.dz",
  "univ-tebessa.dz",
  "univ-tiaret.dz",
  "univ-tlemcen.dz",
  "univ-usto.dz",
  "usthb.dz",
  "uda.ad",
  "uan.ao",
  "ucan.edu",
  "ugs.ed.ao",
  "ulangola.net",
  "uma.co.ao",
  "unia.ao",
  "unimetroangola.com",
  "utanga.co.ao",
  "auamed.org",
  "uhsa.ag",
  "atlantida.edu.ar",
  "austral.edu.ar",
  "caece.edu.ar",
  "cema.edu.ar",
  "iese.edu.ar",
  "inun.edu.ar",
  "itba.edu.ar",
  "iua.edu.ar",
  "iucs.uncoma.edu.ar",
  "iuna.edu.ar",
  "kennedy.edu.ar",
  "maimonides.edu.ar",
  "mdp.edu.ar",
  "palermo.edu.ar",
  "salvador.edu.ar",
  "uade.edu.ar",
  "uader.edu.ar",
  "uapar.edu",
  "uba.ar",
  "ub.edu.ar",
  "ubp.edu.ar",
  "uca.edu.ar",
  "ucalp.edu.ar",
  "ucasal.net",
  "uccor.edu.ar",
  "uccuyo.edu.ar",
  "ucel.edu.ar",
  "uces.edu.ar",
  "uch.edu.ar",
  "ucine.edu.ar",
  "ucongres.edu.ar",
  "ucp.edu.ar",
  "ucse.edu.ar",
  "ucsf.edu.ar",
  "ucu.edu.ar",
  "uda.edu.ar",
  "udemm.edu.ar",
  "udesa.edu.ar",
  "uesiglo21.edu.ar",
  "ufasta.edu.ar",
  "uflo.edu.ar",
  "umaza.edu.ar",
  "um.edu.ar",
  "umsa.edu.ar",
  "unam.edu.ar",
  "unca.edu.ar",
  "unc.edu.ar",
  "uncoma.edu.ar",
  "uncu.edu.ar",
  "uner.edu.ar",
  "unf.edu.ar",
  "ungs.edu.ar",
  "unibo.edu.ar",
  "unicen.edu.ar",
  "unimoron.edu.ar",
  "universidadnotarial.edu.ar",
  "universidad-policial.edu.ar",
  "unju.edu.ar",
  "unla.edu.ar",
  "unlam.edu.ar",
  "unlar.edu.ar",
  "unl.edu.ar",
  "unlpam.edu.ar",
  "unlp.edu.ar",
  "unlu.edu.ar",
  "unlz.edu.ar",
  "unne.edu.ar",
  "unnoba.edu.ar",
  "unpa.edu.ar",
  "unp.edu.ar",
  "unq.edu.ar",
  "unrc.edu.ar",
  "unr.edu.ar",
  "unsa.edu.ar",
  "unsam.edu.ar",
  "uns.edu.ar",
  "unse.edu.ar",
  "unsj.edu.ar",
  "unsl.edu.ar",
  "unsta.edu.ar",
  "unt.edu.ar",
  "untref.edu.ar",
  "utdt.edu",
  "utn.edu.ar",
  "vaneduc.edu.ar",
  "webs.satlink.com",
  "armmed.am",
  "asau.am",
  "asue.am",
  "aua.am",
  "eiu.am",
  "eriicta.am",
  "haybusak.org",
  "rau.am",
  "seua.am",
  "ufar.am",
  "ysmu.am",
  "ysu.am",
  "acs.edu.au",
  "acu.edu.au",
  "adelaide.edu.au",
  "adfa.oz.au",
  "alc.edu.au",
  "amc.edu.au",
  "anu.edu.au",
  "avondale.edu.au",
  "federation.edu.au",
  "bond.edu.au",
  "canberra.edu.au",
  "cdu.edu.au",
  "cqu.edu.au",
  "csu.edu.au",
  "curtin.edu.au",
  "deakin.edu.au",
  "ecu.edu.au",
  "flinders.edu.au",
  "griffith.edu.au",
  "holmes.edu.au",
  "hotelschool.com.au",
  "icthm.edu.au",
  "iota.edu.au",
  "jcu.edu.au",
  "latrobe.edu.au",
  "marcusoldham.vic.edu.au",
  "monash.edu",
  "monash.edu.au",
  "mq.edu.au",
  "murdoch.edu.au",
  "student.murdoch.edu.au",
  "nd.edu.au",
  "newcastle.edu.au",
  "nt.gov.au",
  "qut.edu.au",
  "rmit.edu.au",
  "scu.edu.au",
  "swin.edu.au",
  "une.edu.au",
  "unimelb.edu",
  "unimelb.edu.au",
  "unisa.edu.au",
  "unsw.edu.au",
  "uow.edu.au",
  "uowmail.edu.au",
  "uq.edu.au",
  "usc.edu.au",
  "usq.edu.au",
  "sydney.edu.au",
  "usyd.edu.au",
  "uni.sydney.edu.au",
  "utas.edu.au",
  "uts.edu.au",
  "student.uts.edu.au",
  "uwa.edu.au",
  "uws.edu.au",
  "vu.edu.au",
  "akbild.ac.at",
  "boku.ac.at",
  "donau-uni.ac.at",
  "fh-burgenland.at",
  "fh-hagenberg.at",
  "fh-joanneum.at",
  "fh-kaernten.ac.at",
  "fh-krems.ac.at",
  "fh-kufstein.ac.at",
  "fh-sbg.ac.at",
  "fh-steyr.at",
  "fh-stpoelten.ac.at",
  "fhv.at",
  "fh-vie.ac.at",
  "fhw.at",
  "fh-wels.at",
  "fhwn.ac.at",
  "imc-krems.ac.at",
  "i-med.ac.at",
  "ist.ac.at",
  "kfunigraz.ac.at",
  "khs-linz.ac.at",
  "kth-linz.ac.at",
  "kug.ac.at",
  "mci.edu",
  "mdw.ac.at",
  "meduni-graz.at",
  "meduniwien.at",
  "moz.ac.at",
  "ns2.uni-ak.ac.at",
  "sbg.ac.at",
  "technikum-wien.at",
  "tugraz.at",
  "tuwien.ac.at",
  "uibk.ac.at",
  "umit.at",
  "uni-klu.ac.at",
  "unileoben.ac.at",
  "jku.at",
  "univie.ac.at",
  "vu-wien.ac.at",
  "webster.ac.at",
  "wu-wien.ac.at",
  "wu.ac.at",
  "ada.edu.az",
  "adau.edu.az",
  "ascca.gov.az",
  "asoiu.edu.az",
  "adda.edu.az",
  "adpu.edu.az",
  "azmiu.edu.az",
  "adu.edu.az",
  "amu.edu.az",
  "unec.edu.az",
  "admiu.edu.az",
  "au.edu.az",
  "az-npu.org",
  "aztu.edu.az",
  "musicacademy.edu.az",
  "bbu.edu.az",
  "bsu.edu.az",
  "bsu-edu.org",
  "conservatory.az",
  "dia.edu.az",
  "gdu.edu.az",
  "khazar.org",
  "lsu.edu.az",
  "mdu.edu.az",
  "naa.edu.az",
  "ndu.edu.az",
  "oyu.edu.az",
  "pa.edu.az",
  "qu.edu.az",
  "sdu.edu.az",
  "tourism.edu.az",
  "wu.edu.az",
  "cob.edu.bs",
  "agu.edu.bh",
  "ahliauniversity.org",
  "amaiu.edu.bh",
  "aou.org.bh",
  "asu.edu.bh",
  "gulfuniversity.net",
  "ku.edu.bh",
  "nyit.edu.bh",
  "rcsi-mub.com",
  "ucb.edu.bh",
  "uob.edu.bh",
  "asb.bh",
  "aiub.edu",
  "asaub.edu.bd",
  "atishdipankaruniversity.edu.bd",
  "aub-bd.org",
  "aust.edu",
  "bau.edu.bd",
  "bgctrustbd.org",
  "bou.edu.bd",
  "bracuniversity.net",
  "brur.ac.bd",
  "bsmmu.edu.bd",
  "bsmrau.edu.bd",
  "bubt.edu.bd",
  "bu.edu.bd",
  "buet.ac.bd",
  "bup.edu.bd",
  "butex.edu.bd",
  "cityuniversity.edu.bd",
  "cou.ac.bd",
  "cu.ac.bd",
  "cuet.ac.bd",
  "daffodilvarsity.edu.bd",
  "diu.edu.bd",
  "diu.ac.bd",
  "diu.net.bd",
  "du.ac.bd",
  "duet.ac.bd",
  "eastdelta.edu.bd",
  "easternuni.edu.bd",
  "ewubd.edu",
  "green.edu.bd",
  "hstu.ac.bd",
  "ibaisuniv.edu.bd",
  "icu-edu.org",
  "iiuc.ac.bd",
  "iu.ac.bd",
  "iubat.edu",
  "iub.edu.bd",
  "iutoic-dhaka.edu",
  "jkkniu.edu.bd",
  "jnu.ac.bd",
  "juniv.edu",
  "ku.ac.bd",
  "kuet.ac.bd",
  "lus.ac.bd",
  "manarat.ac.bd",
  "mbstu.ac.bd",
  "metrouni.edu.bd",
  "mist.ac.bd",
  "neub.edu.bd",
  "northsouth.edu",
  "nstu.edu.bd",
  "nub.ac.bd",
  "nu.edu.bd",
  "presidency.edu.bd",
  "pstu.ac.bd",
  "pub.ac.bd",
  "puc.ac.bd",
  "pust.ac.bd",
  "queensuniversity.edu.bd",
  "ru.ac.bd",
  "ruet.ac.bd",
  "sau.ac.bd",
  "sec.ac.bd",
  "seu.ac.bd",
  "siu.edu.bd",
  "southern.edu.bd",
  "stamforduniversity.edu.bd",
  "sust.edu",
  "uap-bd.edu",
  "uits.edu.bd",
  "uiu.ac.bd",
  "ulab.edu.bd",
  "uoda.edu.bd",
  "ustc.ac.bd",
  "wub.edu",
  "uwichill.edu.bb",
  "academy.mia.by",
  "baa.by",
  "batu.edu.by",
  "belsut.gomel.by",
  "bgam.edu.by",
  "bntu.by",
  "brsu.brest.by",
  "bru.mogilev.by",
  "bseu.by",
  "bsmu.by",
  "bspu.unibel.by",
  "bstu.by",
  "bstu.unibel.by",
  "bsu.by",
  "bsuir.by",
  "buk.by",
  "grsmu.by",
  "grsu.by",
  "gstu.gomel.by",
  "gsu.unibel.by",
  "iseu.by",
  "kii.gov.by",
  "meduniver.gomel.by",
  "mgpu.gomel.by",
  "miu.by",
  "mslu.by",
  "msu.mogilev.by",
  "pac.by",
  "psu.by",
  "uni-agro.grodno.by",
  "vgmu.vitebsk.by",
  "vsavm.com",
  "vstu.vitebsk.by",
  "vsu.by",
  "brexgata.eu",
  "carolus-magnus-university.eu",
  "coleurope.eu",
  "ctsem.edu",
  "ecam.be",
  "ehb.be",
  "ehsal.be",
  "etf.edu",
  "europeaniu.org",
  "fpms.ac.be",
  "fsagx.ac.be",
  "fucam.ac.be",
  "ful.ac.be",
  "fundp.ac.be",
  "fusl.ac.be",
  "ha.be",
  "howest.be",
  "hzs.be",
  "ichec.be",
  "kdg.be",
  "khk.be",
  "khleuven.be",
  "khlim.be",
  "kubrussel.ac.be",
  "kulak.kuleuven.be",
  "kuleuven.be",
  "rma.ac.be",
  "ugent.be",
  "rug.ac.be",
  "thierryschool.org",
  "ua.ac.be",
  "uantwerpen.be",
  "uams.be",
  "ubi.edu",
  "ucl.ac.be",
  "ufsia.ac.be",
  "uhasselt.be",
  "ulb.be",
  "ulb.ac.be",
  "umh.ac.be",
  "vesalius.edu",
  "vlekho.be",
  "vlerick.be",
  "vub.ac.be",
  "vub.be",
  "wenk.be",
  "odisee.be",
  "hogent.be",
  "thomasmore.be",
  "aucmed.edu",
  "cahsu.edu",
  "giuedu.bz",
  "ub.edu.bz",
  "westcoastuniversity.bz",
  "espam-formationuc.org",
  "hnaubenin.org",
  "uac.bj",
  "bercol.bm",
  "rub.edu.bt",
  "emi.edu.bo",
  "nur.edu",
  "uab.edu.bo",
  "uabjb.edu.bo",
  "uagrm.edu.bo",
  "uajms.edu.bo",
  "uapnet.edu.bo",
  "uasb.edu.bo",
  "uatf.edu.bo",
  "ucbcba.edu.bo",
  "ucb.edu.bo",
  "ucbscz.edu.bo",
  "ucbtja.edu.bo",
  "udabol.edu.bo",
  "udelosandes.edu.bo",
  "umsanet.edu.bo",
  "umss.edu.bo",
  "unicen.edu.bo",
  "unifranz.edu.bo",
  "unikuljis.edu.bo",
  "univalle.edu",
  "unslp.edu.bo",
  "unsxx.edu.bo",
  "upal.edu",
  "upb.edu",
  "upds.edu.bo",
  "upsa.edu.bo",
  "usalesiana.edu.bo",
  "usfx.edu.bo",
  "usfx.info",
  "uto.edu.bo",
  "aubih.ba",
  "ibu.edu.ba",
  "ius.edu.ba",
  "iu-travnik.com",
  "sfa.ba",
  "spu.ba",
  "sve-mo.ba",
  "unbi.ba",
  "unibl.org",
  "unmo.ba",
  "unsa.ba",
  "unssa.rs.ba",
  "untz.ba",
  "unvi.edu.ba",
  "unze.ba",
  "abm.ac.bw",
  "bac.ac.bw",
  "bca.bw",
  "biust.ac.bw",
  "bothocollege.ac.bw",
  "guc.co.bw",
  "neweracollege.ac.bw",
  "ub.bw",
  "baraodemaua.br",
  "brazcubas.br",
  "candidomendes.br",
  "castelobranco.br",
  "claretiano.edu.br",
  "creupi.br",
  "emescam.br",
  "epm.br",
  "estacio.br",
  "faap.br",
  "faculdadescuritiba.br",
  "fae.edu",
  "feituverava.com.br",
  "fic.br",
  "fua.br",
  "furb.rct-sc.br",
  "furg.br",
  "impa.br",
  "ime.eb.mil.br",
  "ita.br",
  "insper.edu.br",
  "italo.br",
  "mackenzie.br",
  "maua.br",
  "newtonpaiva.br",
  "pop-to.rnp.br",
  "puccamp.br",
  "pucminas.br",
  "pucpr.br",
  "puc-rio.br",
  "pucrs.br",
  "pucsp.br",
  "saojudas.br",
  "smarcos.br",
  "sp.senac.br",
  "stcecilia.br",
  "technet.com.br",
  "toledo.br",
  "ucb.br",
  "ucg.br",
  "ucp.br",
  "ucpel.tche.br",
  "ucsal.br",
  "ucsal.edu.br",
  "ucs.tche.br",
  "udesc.br",
  "uece.br",
  "uefs.br",
  "uel.br",
  "uema.br",
  "uem.br",
  "uenf.br",
  "uepb.edu.br",
  "uepg.br",
  "uerj.br",
  "uern.br",
  "uesb.br",
  "uesc.br",
  "uespi.br",
  "ufabc.edu.br",
  "ufac.br",
  "ufal.br",
  "ufba.br",
  "ufc.br",
  "ufcg.edu.br",
  "ufes.br",
  "uff.br",
  "ufg.br",
  "ufgd.edu.br",
  "ufjf.br",
  "ufla.br",
  "ufma.br",
  "ufmg.br",
  "dcc.ufmg.br",
  "ufvjm.edu.br",
  "unifei.edu.br",
  "ufsj.edu.br",
  "uftm.edu.br",
  "ufms.br",
  "ufmt.br",
  "ufop.br",
  "ufpa.br",
  "ufpb.br",
  "ufpe.br",
  "ufpel.tche.br",
  "ufpi.br",
  "ufpr.br",
  "ufrgs.br",
  "ufrj.br",
  "ufrn.br",
  "ufrpe.br",
  "ufrrj.br",
  "ufs.br",
  "ufscar.br",
  "ufsc.br",
  "ufsm.br",
  "ufu.br",
  "ufv.br",
  "ugf.br",
  "ulbra.br",
  "umc.br",
  "unaerp.br",
  "unama.br",
  "unb.br",
  "uneb.br",
  "unesc.rct-sc.br",
  "unesp.br",
  "ung.br",
  "uniara.edu.br",
  "uniara.com.br",
  "uniban.br",
  "unib.br",
  "unibosco.br",
  "unibrasil.com.br",
  "unicamp.br",
  "unicap.br",
  "unicastelo.br",
  "unicid.br",
  "unicruz.tche.br",
  "unicsul.br",
  "unidavi.rct-sc.br",
  "unifacs.br",
  "unifap.br",
  "unifenas.br",
  "unifeso.edu.br",
  "unifor.br",
  "unifran.br",
  "unig.br",
  "unigranrio.br",
  "unijui.tche.br",
  "unimar.br",
  "unimep.br",
  "unimes.com.br",
  "unimonte.br",
  "unimontes.br",
  "unioeste.br",
  "unipe.br",
  "unipli.com.br",
  "unip-objetivo.br",
  "unir.br",
  "unirio.br",
  "unisa.br",
  "unisantos.com.br",
  "unisc.br",
  "unisinos.br",
  "unisul.br",
  "unitau.br",
  "unit.br",
  "uniube.br",
  "univale.br",
  "univali.rct-sc.br",
  "univap.br",
  "univasf.edu.br",
  "universo.br",
  "unoesc.edu.br",
  "unoeste.br",
  "unp.br",
  "upe.br",
  "upf.tche.br",
  "upis.br",
  "urca.br",
  "urcamp.tche.br",
  "uri.br",
  "usc.br",
  "usf.br",
  "usp.br",
  "usu.br",
  "uva.br",
  "uvanet.br",
  "web.prover.com.br",
  "itb.edu.bn",
  "ubd.edu.bn",
  "unissa.edu.bn",
  "amti.hit.bg",
  "aubg.bg",
  "au-plovdiv.bg",
  "bfu.bg",
  "btu.bg",
  "cityu.bg",
  "ltu.bg",
  "meduniversity-plovdiv.bg",
  "mgu.bg",
  "mu-pleven.bg",
  "mu-sofia.bg",
  "mu-varna.bg",
  "natfiz.bg",
  "nbu.bg",
  "nha-bg.org",
  "nma.bg",
  "nsa.bg",
  "pv-ma.bg",
  "ru.acad.bg",
  "shu-bg.net",
  "swu.bg",
  "tugab.bg",
  "tu-sofia.bg",
  "tu-varna.acad.bg",
  "uacg.bg",
  "uctm.edu",
  "ue-varna.bg",
  "uft-plovdiv.bg",
  "uni-plovdiv.bg",
  "uni-sofia.bg",
  "uni-svishtov.bg",
  "uni-sz.bg",
  "uni-vt.bg",
  "unwe.acad.bg",
  "vfu.bg",
  "vumk.eu",
  "univ-ouaga.bf",
  "itbmu.org.mm",
  "most.gov.mm",
  "ucsy.edu.mm",
  "um1ygn.edu.mm",
  "ummg.edu.mm",
  "hopeafricauniversity.org",
  "ub.edu.bi",
  "ulbu.bi",
  "aeu.edu.kh",
  "angkor.edu.kh",
  "bbu.edu.kh",
  "cup.edu.kh",
  "cus.edu.kh",
  "ine.edu.kh",
  "iu.edu.kh",
  "mekong.edu.kh",
  "norton.edu.kh",
  "num.edu.kh",
  "puc.edu.kh",
  "rua.edu.kh",
  "rufa.edu.kh",
  "rule.edu.kh",
  "rupp.edu.kh",
  "sru.edu.kh",
  "uc.edu.kh",
  "univ-sante.edu.kh",
  "utpp.edu.kh",
  "western.edu.kh",
  "zamanuniversity.edu.kh",
  "bamendauniversity.com",
  "fomicgroup.cm",
  "ubuea.cm",
  "udesmontagnes.org",
  "unibda.net",
  "univ-douala.com",
  "univ-dschang.org",
  "universite-yde2.org",
  "univ-ndere.cm",
  "uy1.uninet.cm",
  "acadiau.ca",
  "algonquincollege.com",
  "ashtoncollege.com",
  "assumptionu.ca",
  "athabascau.ca",
  "augustana.ab.ca",
  "bcit.ca",
  "bcou.ca",
  "boothcollege.ca",
  "borealc.on.ca",
  "bowvalleycollege.ca",
  "brandonu.ca",
  "brocku.ca",
  "cal.devry.ca",
  "camosun.bc.ca",
  "capcollege.bc.ca",
  "cariboo.bc.ca",
  "carleton.ca",
  "ccbc.ca",
  "centennialcollege.ca",
  "cmu.ca",
  "cnc.bc.ca",
  "columbiacollege.ca",
  "concordia.ab.ca",
  "concordia.ca",
  "conestogac.on.ca",
  "cotr.bc.ca",
  "cus.ca",
  "cuslm.ca",
  "dal.ca",
  "douglas.bc.ca",
  "dcmail.ca",
  "eciad.bc.ca",
  "enap.uquebec.ca",
  "etsmtl.ca",
  "firstnationsuniversity.ca",
  "gbrownc.on.ca",
  "hec.ca",
  "humber.ca",
  "humbermail.ca",
  "huronuc.on.ca",
  "iaf.inrs.ca",
  "inrs.uquebec.ca",
  "kingston.edu",
  "kingsu.ab.ca",
  "kwantlen.bc.ca",
  "lakeheadu.ca",
  "langara.bc.ca",
  "langara.ca",
  "lansbridge.com",
  "laurentian.ca",
  "macewan.ca",
  "mala.bc.ca",
  "mcgill.ca",
  "mcmaster.ca",
  "msvu.ca",
  "mta.ca",
  "mtroyal.ca",
  "nait.ca",
  "nic.bc.ca",
  "nipissingu.ca",
  "nlc.bc.ca",
  "nsac.ns.ca",
  "nscad.ns.ca",
  "nvit.bc.ca",
  "ocad.ca",
  "okanagan.bc.ca",
  "op.org",
  "picollege.ca",
  "polymtl.ca",
  "queensu.ca",
  "questu.ca",
  "redeemer.ca",
  "regent-college.edu",
  "rmc.ca",
  "royalroads.ca",
  "ryerson.ca",
  "sait.ca",
  "saskweb.com",
  "sauder.ubc.ca",
  "scar.utoronto.ca",
  "selkirk.bc.ca",
  "senecacollege.ca",
  "myseneca.ca",
  "sfu.ca",
  "sheridancollege.ca",
  "sifc.edu",
  "stclairc.on.ca",
  "stclairconnect.ca",
  "myscc.ca",
  "stfx.ca",
  "stmarys.ca",
  "smu.ca",
  "stthomasu.ca",
  "teluq.uquebec.ca",
  "trentu.ca",
  "trinity.utoronto.ca",
  "tru.ca",
  "twu.ca",
  "ualberta.ca",
  "ubc.ca",
  "ubishops.ca",
  "ucalgary.ca",
  "uccb.ns.ca",
  "ufv.ca",
  "ucs.mun.ca",
  "ukings.ns.ca",
  "ulaval.ca",
  "uleth.ca",
  "umanitoba.ca",
  "umoncton.ca",
  "umontreal.ca",
  "unb.ca",
  "unbc.ca",
  "unbsj.ca",
  "universitycanadawest.ca",
  "uoguelph.ca",
  "uoit.ca",
  "uottawa.ca",
  "upei.ca",
  "uqac.ca",
  "uqam.ca",
  "uqar.uquebec.ca",
  "uqat.uquebec.ca",
  "uqo.ca",
  "uqtr.uquebec.ca",
  "uquebec.ca",
  "uregina.ca",
  "usask.ca",
  "usherb.ca",
  "usjc.uwaterloo.ca",
  "ustanne.ednet.ns.ca",
  "ustboniface.mb.ca",
  "ustpaul.ca",
  "usudbury.com",
  "utm.utoronto.ca",
  "utoronto.ca",
  "toronto.edu",
  "uvic.ca",
  "uwaterloo.ca",
  "uwindsor.ca",
  "uwinnipeg.ca",
  "uwo.ca",
  "vcc.ca",
  "vicu.utoronto.ca",
  "wlu.ca",
  "mylaurier.ca",
  "yorku.ca",
  "yukoncollege.yk.ca",
  "cv.unipiaget.org",
  "icci.edu.ky",
  "stmatthews.edu",
  "univ-bangui.net",
  "univ-ndjamena.org",
  "academia.cl",
  "arquitecturaucv.cl",
  "uc.cl",
  "uach.cl",
  "uaconcagua.cl",
  "uahurtado.cl",
  "uai.cl",
  "uamericas.cl",
  "uandes.cl",
  "uantof.cl",
  "uas.cl",
  "ubiobio.cl",
  "ubohiggins.cl",
  "ubolivariana.cl",
  "ubritanica.cl",
  "ucentral.cl",
  "ucervantes.cl",
  "uchile.cl",
  "ucinf.cl",
  "ucm.cl",
  "ucn.cl",
  "ucsc.cl",
  "ucsh.cl",
  "uctem.cl",
  "ucv.cl",
  "uda.cl",
  "udec.cl",
  "udelmar.cl",
  "udesarrollo.cl",
  "udp.cl",
  "ufinis.cl",
  "ufro.cl",
  "ufromail.cl",
  "ugm.cl",
  "uisek.cl",
  "ujso.cl",
  "ulagos.cl",
  "ulare.cl",
  "umag.cl",
  "umaritima.cl",
  "umayor.cl",
  "umce.cl",
  "ume.cl",
  "unab.cl",
  "unachile.cl",
  "unap.cl",
  "unfa.cl",
  "uniacc.cl",
  "unicit.cl",
  "universidadarcis.cl",
  "upacifico.cl",
  "upa.cl",
  "usach.cl",
  "usanandres.cl",
  "userena.cl",
  "uss.cl",
  "ust.cl",
  "uta.cl",
  "utalca.cl",
  "utem.cl",
  "utfsm.cl",
  "uv.cl",
  "uvipro.cl",
  "uvm.cl",
  "ahmu.edu.cn",
  "ahnu.edu.cn",
  "ahsdxy.ah.edu.cn",
  "ahtcm.edu.cn",
  "ahu.edu.cn",
  "aufe.edu.cn",
  "bac.edu.cn",
  "bfsu.edu.cn",
  "bhu.edu.cn",
  "bisu.edu.cn",
  "bit.edu.cn",
  "biti.edu.cn",
  "bjtu.edu.cn",
  "bjaeu.edu.cn",
  "bjfu.edu.cn",
  "bjmu.edu.cn",
  "bjpeu.edu.cn",
  "bjpu.edu.cn",
  "bjucmp.edu.cn",
  "blcu.edu.cn",
  "bnu.edu.cn",
  "bsu.edu.cn",
  "buaa.edu.cn",
  "buct.edu.cn",
  "bupt.edu.cn",
  "cafa.edu.cn",
  "cauc.edu",
  "cau.edu.cn",
  "ccnu.edu.cn",
  "ccut.edu.cn",
  "cdu.edu.cn",
  "cdutcm.edu.cn",
  "cdut.edu.cn",
  "cfau.edu.cn",
  "chinaacademyofart.com",
  "cisisu.edu.cn",
  "cmu.edu.cn",
  "cncnc.edu.cn",
  "cnu.edu.cn",
  "cppsu.edu.cn",
  "cpu.edu.cn",
  "cpums.edu.cn",
  "cqec.net.cn",
  "cqgyzy.com",
  "cqmu.edu.cn",
  "cqnu.edu.cn",
  "cqtcedu.com",
  "cquc.edu.cn",
  "cqu.edu.cn",
  "cqupt.edu.cn",
  "cqust.cn",
  "cqut.edu.cn",
  "cqwu.net",
  "crtvu.edu.cn",
  "csfu.edu.cn",
  "csru.edu.cn",
  "csu.edu.cn",
  "csuep.edu.cn",
  "ctbu.edu.cn",
  "ctgu.edu.cn",
  "cubu.edu",
  "cuc.edu.cn",
  "cueb.edu.cn",
  "cufe.edu.cn",
  "cugb.edu.cn",
  "cug.edu.cn",
  "cumtb.edu.cn",
  "cumt.edu.cn",
  "cun.edu.cn",
  "cupl.edu.cn",
  "cyc.edu.cn",
  "dhu.edu.cn",
  "dlmedu.edu.cn",
  "dlmu.edu.cn",
  "dlu.edu.cn",
  "dlufl.edu.cn",
  "dlut.edu.cn",
  "dufe.edu.cn",
  "ecjtu.jx.cn",
  "ecnu.edu.cn",
  "ecust.edu.cn",
  "emsfj.com",
  "fimmu.edu.cn",
  "fjau.edu.cn",
  "fjmu.edu.cn",
  "fjnu.edu.cn",
  "fjtcm.edu.cn",
  "fmmu.edu.cn",
  "fosu.edu.cn",
  "fspu.edu.cn",
  "fudan.edu.cn",
  "fzu.edu.cn",
  "gdin.edu.cn",
  "gdrtvu.edu.cn",
  "gdufs.edu.cn",
  "gdut.edu.cn",
  "gsau.edu.cn",
  "gsut.edu.cn",
  "guangztc.edu.cn",
  "gxmu.edu.cn",
  "gxnu.edu.cn",
  "gxtcmu.edu.cn",
  "gxu.edu.cn",
  "gxun.edu.cn",
  "gzarts.edu.cn",
  "gzhtcm.edu.cn",
  "gzhu.edu.cn",
  "gznu.edu.cn",
  "gzsums.edu.cn",
  "gzu.edu.cn",
  "hainnu.edu.cn",
  "hainu.edu.cn",
  "hbafa.com",
  "hbu.edu",
  "hbu.edu.cn",
  "hdpu.edu.cn",
  "hebau.edu.cn",
  "hebeiuniteduniversity.com",
  "hebmu.edu.cn",
  "hebnu.edu.cn",
  "hebust.edu.cn",
  "hebut.edu.cn",
  "henannu.edu.cn",
  "henau.edu.cn",
  "henu.edu.cn",
  "heuet.edu.cn",
  "hfut.edu.cn",
  "hhit.edu.cn",
  "hhrtu.com",
  "hhstu.edu.cn",
  "hhuc.edu.cn",
  "hhu.edu.cn",
  "hit.edu.cn",
  "hlaflru.edu.cn",
  "hljcu.edu.cn",
  "hlju.edu.cn",
  "hnfjxy.cn",
  "hqgc.net",
  "hqu.edu.cn",
  "hrbeu.edu.cn",
  "hrbmu.edu.cn",
  "hrbnu.edu.cn",
  "hrbucea.edu.cn",
  "hrbust.edu.cn",
  "hubu.edu.cn",
  "hunau.net",
  "hunnu.edu.cn",
  "hust.edu.cn",
  "hzau.edu.cn",
  "hzu.edu.cn",
  "ibub.edu.cn",
  "imau.edu.cn",
  "imnu.edu.cn",
  "impu.edu.cn",
  "imu.edu.cn",
  "ipdbuu.com.cn",
  "jci.edu.cn",
  "jlau.edu.cn",
  "jlu.edu.cn",
  "jmu.edu.cn",
  "jnu.edu.cn",
  "jsu.edu.cn",
  "jsust.edu.cn",
  "jutcm.com",
  "jut.edu.cn",
  "jxau.edu.cn",
  "jxnu.edu.cn",
  "jxufe.edu.cn",
  "jyu.edu.cn",
  "kmust.edu.cn",
  "lctu.edu.cn",
  "lidapoly.com",
  "lixin.edu.cn",
  "lnnu.edu.cn",
  "lntu.edu.cn",
  "lnu.edu.cn",
  "lumei.edu.cn",
  "lzmc.edu.cn",
  "lzu.edu.cn",
  "nbu.edu.cn",
  "nbut.edu.cn",
  "ncepubj.edu.cn",
  "ncu.edu.cn",
  "ncut.edu.cn",
  "neau.edu.cn",
  "nefu.edu.cn",
  "neijtc.edu.cn",
  "nenu.edu.cn",
  "neu.edu.cn",
  "neuq.edu.cn",
  "newasia.bj.edu.cn",
  "njau.edu.cn",
  "njfu.edu.cn",
  "njim.edu.cn",
  "njmu.edu.cn",
  "njnu.edu.cn",
  "njtu.edu.cn",
  "njuct.edu.cn",
  "nju.edu.cn",
  "njue.edu.cn",
  "njupt.edu.cn",
  "njust.edu.cn",
  "njutcm.edu.cn",
  "njuts.cn",
  "nku.nankai.edu.cn",
  "nottingham.edu.cn",
  "nsmc.edu.cn",
  "nuaa.edu.cn",
  "nudt.edu.cn",
  "nwau.edu.cn",
  "nwnu.edu.cn",
  "nwpu.edu.cn",
  "nwu.edu.cn",
  "nxmc.edu.cn",
  "nxu.edu.cn",
  "ouc.edu.cn",
  "peizheng.com.cn",
  "pku.edu.cn",
  "pzhu.edu.cn",
  "qdu.edu.cn",
  "qfnu.edu.cn",
  "qhnu.edu.cn",
  "qhrtvu.edu.cn",
  "qhu.edu.cn",
  "qust.edu.cn",
  "qzu.edu.cn",
  "ruc.edu.cn",
  "sandau.edu.cn",
  "sanxiau.edu.cn",
  "sbs.edu.cn",
  "scau.edu.cn",
  "sccu.edu.cn",
  "scnu.edu.cn",
  "scu.edu.cn",
  "scut.edu.cn",
  "sdada.edu.cn",
  "sdau.edu.cn",
  "sdie.edu.cn",
  "sdju.edu.cn",
  "sdmu.edu.cn",
  "sdnu.edu.cn",
  "sdu.edu.cn",
  "sdust.edu.cn",
  "sdutcm.edu.cn",
  "sdut.edu.cn",
  "sdufe.edu.cn",
  "seu.edu.cn",
  "sgu.edu.cn",
  "shcc.edu.cn",
  "shfu.edu.cn",
  "shift.edu.cn",
  "shisu.edu.cn",
  "shmtu.edu.cn",
  "shmu.edu.cn",
  "shouhua.net.cn",
  "sh-sipopolytechnic.com",
  "shsmu.edu.cn",
  "shspu.edu.cn",
  "shtdu.edu.cn",
  "shtu.edu.cn",
  "shtvu.edu.cn",
  "shu.edu.cn",
  "shanghaitech.edu.cn",
  "shufe.edu.cn",
  "shutcm.edu.cn",
  "shzu.edu.cn",
  "sicau.edu.cn",
  "sicnu.edu.cn",
  "sisu.edu.cn",
  "sjtu.edu.cn",
  "sjzu.edu.cn",
  "smmu.edu.cn",
  "snnu.edu.cn",
  "stu.edu.cn",
  "suda.edu.cn",
  "sues.edu.cn",
  "sustc.edu.cn",
  "swau.edu.cn",
  "swfc.edu.cn",
  "swjtu.edu.cn",
  "swnu.edu.cn",
  "swpu.edu.cn",
  "swsm.cn",
  "swufe.edu.cn",
  "swun.edu.cn",
  "swupl.edu.cn",
  "swust.edu.cn",
  "swuyc.edu.cn",
  "sxau.edu.cn",
  "sxu.edu.cn",
  "syict.edu.cn",
  "syphu.edu.cn",
  "sypu.edu.cn",
  "sytu.edu.cn",
  "syu.edu.cn",
  "szu.edu.cn",
  "tijmu.edu.cn",
  "tjarts.edu.cn",
  "tjcu.edu.cn",
  "tjmu.edu.cn",
  "tjnu.edu.cn",
  "tjpu.edu.cn",
  "tjrtvu.edu.cn",
  "tju.edu.cn",
  "tjufe.edu.cn",
  "tjut.edu.cn",
  "tmmu.edu.cn",
  "tongji.edu.cn",
  "tsinghua.edu.cn",
  "ttmc.edu.cn",
  "tyut.edu.cn",
  "tzc.edu.cn",
  "uestc.edu.cn",
  "uheeyc.edu.cn",
  "uibe.edu.cn",
  "ujn.edu.cn",
  "umcollege.com",
  "usc.edu.cn",
  "usst.edu.cn",
  "ustb.edu.cn",
  "ustc.edu.cn",
  "ustc.edu",
  "utibet.edu.cn",
  "uvu.edu.cn",
  "wcums.edu.cn",
  "wdsdjxy.com",
  "whapu.edu.cn",
  "whtu.edu.cn",
  "whu.edu.cn",
  "whut.edu.cn",
  "wsm.whu.edu.cn",
  "wtusm.edu.cn",
  "wuhee.edu.cn",
  "wxuli.edu.cn",
  "wyu.edu.cn",
  "wzmu.net",
  "wzu.edu.cn",
  "xafa.edu.cn",
  "xahu.edu.cn",
  "xauat.edu.cn",
  "xaut.edu.cn",
  "xhcom.edu.cn",
  "xidian.edu.cn",
  "xiju.edu.cn",
  "xisu.edu.cn",
  "xjau.edu.cn",
  "xjnu.edu.cn",
  "xjtlu.edu.cn",
  "eurasia.edu",
  "xjtu.edu.cn",
  "xju.edu.cn",
  "xjufe.edu.cn",
  "xmu.edu.cn",
  "xtnu.edu.cn",
  "xtu.edu.cn",
  "xza.cn",
  "xzmy.edu.cn",
  "xznu.edu.cn",
  "yangtzeu.edu.cn",
  "yau.edu.cn",
  "ybu.edu.cn",
  "ybust.edu.cn",
  "yizhuan.com",
  "ynau.edu.cn",
  "ynnu.edu.cn",
  "ynu.edu.cn",
  "ysu.edu.cn",
  "ytet.edu.cn",
  "ytnc.edu.cn",
  "ytu.edu.cn",
  "yznu.cn",
  "yzu.edu.cn",
  "zdsoft.com.cn",
  "zgfxy.cn",
  "zhnut.edu.cn",
  "zjfc.edu.cn",
  "zjgsu.edu.cn",
  "zjnu.edu.cn",
  "zjou.edu.cn",
  "zju.edu.cn",
  "zjut.edu.cn",
  "znuel.net",
  "znufe.edu.cn",
  "zsb.scfai.edu.cn",
  "zstu.edu.cn",
  "zsu.edu.cn",
  "zzgc.edu.cn",
  "zzut.edu.cn",
  "autonoma.edu.co",
  "bellasartes.edu.co",
  "bundenet.com",
  "cesa.edu.co",
  "ces.edu.co",
  "cuao.edu.co",
  "cuc.edu.co",
  "cui.edu.co",
  "cutb.edu.co",
  "eafit.edu.co",
  "ean.edu.co",
  "ecci.edu.co",
  "ecr.edu.co",
  "eia.edu.co",
  "esap.edu.co",
  "escueladepolicia.edu.co",
  "escuelaing.edu.co",
  "fuac.edu.co",
  "funandi.edu.co",
  "funlam.edu.co",
  "iberoamericana.edu.co",
  "iceit.edu.co",
  "icesi.edu.co",
  "itm.edu.co",
  "iue.edu.co",
  "javeriana.edu.co",
  "lasalle.edu.co",
  "pedagogica.edu.co",
  "poligran.edu.co",
  "politecjic.edu.co",
  "sanmartin.edu.co",
  "uac.edu.co",
  "uam.edu.co",
  "uamerica.edu.co",
  "uanarino.edu.co",
  "ucaldas.edu.co",
  "ucatolica.edu.co",
  "ucatolicamz.edu.co",
  "ucauca.edu.co",
  "ucc.edu.co",
  "ucentral.edu.co",
  "uceva.edu.co",
  "ucn.edu.co",
  "uco.edu.co",
  "ucpr.edu.co",
  "udca.edu.co",
  "udea.edu.co",
  "udem.edu.co",
  "udenar.edu.co",
  "udes.edu.co",
  "udistrital.edu.co",
  "uexternado.edu.co",
  "ufps.edu.co",
  "ugrancolombia.edu.co",
  "uis.edu.co",
  "umanizales.edu.co",
  "umariana.edu.co",
  "umb.edu.co",
  "umng.edu.co",
  "unab.edu.co",
  "unac.edu.co",
  "unad.edu.co",
  "unal.edu.co",
  "unaula.edu.co",
  "unbosque.edu.co",
  "uniamazonia.edu.co",
  "uniandes.edu.co",
  "uniatlantico.edu.co",
  "uniboyaca.edu.co",
  "unicartagena.edu.co",
  "unicesar.edu.co",
  "unicordoba.edu.co",
  "uniguajira.edu.co",
  "unilibre.edu.co",
  "unillanos.edu.co",
  "unimagdalena.edu.co",
  "unimetro.edu.co",
  "unincca.edu.co",
  "uninorte.edu.co",
  "unipamplona.edu.co",
  "unipaz.edu.co",
  "unipiloto.edu.co",
  "uniquindio.edu.co",
  "unisabana.edu.co",
  "unisimonbolivar.edu.co",
  "unisinucartagena.edu.co",
  "unisucre.edu.co",
  "univalle.edu.co",
  "upb.edu.co",
  "uptc.edu.co",
  "urosario.edu.co",
  "usaca.edu.co",
  "usb.edu.co",
  "usbmed.edu.co",
  "usergioarboleda.edu.co",
  "usta.edu.co",
  "usurcolombia.com",
  "utadeo.edu.co",
  "utch.edu.co",
  "ut.edu.co",
  "utp.edu.co",
  "ucbc.org",
  "ucbukavu.ac.cd",
  "um-rdc.org",
  "uniband.org",
  "unikin.cd",
  "unikis.ac.cd",
  "unilu.ac.cd",
  "unishabunia.org",
  "universitekongo.org",
  "upc-rdc.cd",
  "univ-mngb.net",
  "fundepos.ac.cr",
  "isaacnewtoncr.com",
  "itcr.ac.cr",
  "sjdlc.com",
  "uaca.ac.cr",
  "uca.ac.cr",
  "ucatolica.ac.cr",
  "uci.ac.cr",
  "ucr.ac.cr",
  "ufidelitas.ac.cr",
  "uhispanoamericana.ac.cr",
  "uia.ac.cr",
  "ulacit.ac.cr",
  "ulasalle.ac.cr",
  "ulatina.ac.cr",
  "ulicori.ac.cr",
  "umca.net",
  "una.ac.cr",
  "unadeca.ac.cr",
  "unam.ac.cr",
  "uned.ac.cr",
  "unem.edu",
  "unibe.ac.cr",
  "universidadbrauliocarrillo.com",
  "universidadcentral.com",
  "universidadsanjosecr.com",
  "upeace.org",
  "usam.ac.cr",
  "uspsantapaula.com",
  "utur.ac.cr",
  "uveritas.ac.cr",
  "uabobo.ci",
  "ubouake.ci",
  "univ-cocody.ci",
  "tabari.ac.ir",
  "zut.edu.pl",
  "vistula.edu.pl",
  "unidu.hr",
  "unios.hr",
  "unipu.hr",
  "uniri.hr",
  "unist.hr",
  "unizd.hr",
  "unizg.hr",
  "zsem.hr",
  "vern.hr",
  "tvz.hr",
  "algebra.hr",
  "unin.hr",
  "cujae.edu.cu",
  "ismm.edu.cu",
  "ispcmw.rimed.cu",
  "reduc.edu.cu",
  "ucf.edu.cu",
  "uclv.edu.cu",
  "udg.co.cu",
  "uh.cu",
  "uho.edu.cu",
  "umcc.cu",
  "unica.cu",
  "uo.edu.cu",
  "upr.edu.cu",
  "ac.ac.cy",
  "ciim.ac.cy",
  "ciu.edu.tr",
  "ctleuro.ac.cy",
  "cut.ac.cy",
  "cycollege.ac.cy",
  "emu.edu.tr",
  "euc.ac.cy",
  "frederick.ac.cy",
  "gau.edu.tr",
  "intercollege.ac.cy",
  "lefke.edu.tr",
  "neu.edu.tr",
  "ouc.ac.cy",
  "ucy.ac.cy",
  "unic.ac.cy",
  "aauni.edu",
  "cuni.cz",
  "cvut.cz",
  "fa.cvut.cz",
  "fit.cvut.cz",
  "czu.cz",
  "famu.cz",
  "jcu.cz",
  "mendelu.cz",
  "mpu-prague.cz",
  "muni.cz",
  "osu.cz",
  "praguecollege.cz",
  "slu.cz",
  "tiffinprague.cz",
  "ujep.cz",
  "unva.cz",
  "unyp.cz",
  "upce.cz",
  "upol.cz",
  "utb.cz",
  "vfu.cz",
  "vsb.cz",
  "vscht.cz",
  "vsci.cz",
  "vse.cz",
  "vslib.cz",
  "vsp.cz",
  "vutbr.cz",
  "vut.cz",
  "zcu.cz",
  "a-aarhus.dk",
  "ah.dk",
  "ats.dk",
  "auc.dk",
  "aau.dk",
  "student.aau.dk",
  "au.dk",
  "cbs.dk",
  "db.dk",
  "ddea.dk",
  "dfh.dk",
  "dkdm.dk",
  "dlh.dk",
  "dpu.dk",
  "dtu.dk",
  "hha.dk",
  "hhs.dk",
  "ibss.eu",
  "iha.dk",
  "ihk.dk",
  "ipc.dk",
  "itu.dk",
  "karch.dk",
  "knightsbridgeuniversity.com",
  "ku.dk",
  "kunstakademiet.dk",
  "kvl.dk",
  "musik-kons.dk",
  "ou.dk",
  "ruc.dk",
  "sdu.dk",
  "teologi.dk",
  "tietgen.dk",
  "en.via.dk",
  "univ.edu.dj",
  "dsc.dm",
  "newworld.ac",
  "rossu.edu",
  "icda.edu.do",
  "intec.edu.do",
  "itla.edu.do",
  "pucmm.edu.do",
  "uafam.edu.do",
  "uapa.edu.do",
  "uasd.edu.do",
  "ucateci.edu.do",
  "uce.edu.do",
  "ucne.edu.do",
  "ucsd.edu.do",
  "udoym.edu.do",
  "ufhec.edu.do",
  "unad.edu.do",
  "unapec.edu.do",
  "unefa.edu.do",
  "unev-rd.edu.do",
  "unibe.edu.do",
  "unica.edu.do",
  "unicaribe.edu.do",
  "uniremhos.edu.do",
  "unphu.edu.do",
  "uod.edu.do",
  "ute.edu.do",
  "utesa.edu",
  "utesur.edu.do",
  "brookdale.edu.ec",
  "brookings.edu",
  "whoi.edu",
  "casagrande.edu.ec",
  "epn.edu.ec",
  "espe.edu.ec",
  "espoch.edu",
  "espol.edu.ec",
  "indoamerica.edu.ec",
  "internacional.edu.ec",
  "puce.edu.ec",
  "sek.edu",
  "uagraria.edu.ec",
  "uasb.edu.ec",
  "uazuay.edu.ec",
  "ucaazo.edu.ec",
  "ucacue.edu.ec",
  "ucentral.edu.ec",
  "ucsg.edu.ec",
  "uct.edu.ec",
  "ucuenca.edu.ec",
  "ueb.isfun.net",
  "uees.edu.ec",
  "ug.edu.ec",
  "uisrael.ec",
  "ulaicavr.com",
  "uleam.edu.ec",
  "unach.edu.ec",
  "unita.edu.ec",
  "unl.edu.ec",
  "upacifico.edu.ec",
  "ups.edu.ec",
  "usfq.edu.ec",
  "usm.edu.ec",
  "uta.edu.ec",
  "utb.edu.ec",
  "utc.edu.ec",
  "ute.edu.ec",
  "utelvt.edu.ec",
  "uteq.edu.ec",
  "utmachala.edu.ec",
  "utm.edu.ec",
  "utn.edu.ec",
  "utpl.edu.ec",
  "utsam.edu.ec",
  "aast.edu",
  "akhbaracademy.edu.eg",
  "alex.edu.eg",
  "aou.edu.eg",
  "aucegypt.edu",
  "aun.edu.eg",
  "azhar.edu.eg",
  "bsu.edu.eg",
  "bu.edu.eg",
  "cu.edu.eg",
  "damanhour.edu.eg",
  "du.edu.eg",
  "elshoroukacademy.edu.eg",
  "fayoum.edu.eg",
  "futureuniversity.edu.eg",
  "guc.edu.eg",
  "helwan.edu.eg",
  "hti.edu.eg",
  "kfs.edu.eg",
  "mans.edu.eg",
  "menofia.edu.eg",
  "minia.edu.eg",
  "miuegypt.edu.eg",
  "modern-academy.edu.eg",
  "msa.eun.eg",
  "mtc.edu.eg",
  "mti.edu.eg",
  "must.edu.eg",
  "nileu.edu.eg",
  "nu.edu.eg",
  "o6u.edu.eg",
  "pua.edu.eg",
  "sadatacademy.edu.eg",
  "shams.edu.eg",
  "asu.edu.eg",
  "eng.asu.edu.eg",
  "sohag-univ.edu.eg",
  "su.edu.eg",
  "suez.edu.eg",
  "svu.edu.eg",
  "tanta.edu.eg",
  "ufe.edu.eg",
  "usenghor-francophonie.org",
  "zu.edu.eg",
  "cdb.edu.sv",
  "netcomsa.com",
  "uae.edu.sv",
  "ucad.edu.sv",
  "uca.edu.sv",
  "uees.edu.sv",
  "ues.edu.sv",
  "ufg.edu.sv",
  "ugb.edu.sv",
  "ujmd.edu.sv",
  "uma.edu.sv",
  "umoar.edu.sv",
  "unab.edu.sv",
  "unasa.edu.sv",
  "unico.edu.sv",
  "universidadpedagogica.com",
  "univo.edu.sv",
  "unssa.edu.sv",
  "upan.edu.sv",
  "upes.edu.sv",
  "usam.edu.sv",
  "usin.edu.sv",
  "usonsonate.edu.sv",
  "utec.edu.sv",
  "utla.edu.sv",
  "unge.gq",
  "eit.edu.er",
  "artun.ee",
  "ebs.ee",
  "ema.edu.ee",
  "emu.ee",
  "nooruse.ee",
  "sisekaitse.ee",
  "tktk.ee",
  "tlu.ee",
  "ttu.ee",
  "ut.ee",
  "aastu.org",
  "aau.edu.et",
  "adu.edu.et",
  "aku.edu.et",
  "ambou.edu.et",
  "amu.edu.et",
  "astu.edu.et",
  "asu.edu.et",
  "bdu.edu.et",
  "bulehorauniversity.com",
  "dbu.edu.et",
  "ddu.edu.et",
  "dillauniversity.edu.et",
  "dmu.edu.et",
  "ecsc.edu.et",
  "haramaya.edu.et",
  "hu.edu.et",
  "jju.edu.et",
  "ju.edu.et",
  "mitethiopia.edu.et",
  "mtu.edu.et",
  "mu.edu.et",
  "mwu.edu.et",
  "su.edu.et",
  "uog.edu.et",
  "uuc.edu.et",
  "wku.edu.et",
  "wsu.edu.et",
  "wu.edu.et",
  "wuni.edu.et",
  "setur.fo",
  "fnu.ac.fj",
  "fsm.ac.fj",
  "unifiji.ac.fj",
  "usp.ac.fj",
  "abo.fi",
  "cou.fi",
  "diak.fi",
  "evtek.fi",
  "helsinki.fi",
  "hkkk.fi",
  "hut.fi",
  "joensuu.fi",
  "jyu.fi",
  "kuva.fi",
  "lamk.fi",
  "student.lamk.fi",
  "laurea.fi",
  "student.laurea.fi",
  "lut.fi",
  "student.lut.fi",
  "oamk.fi",
  "oulu.fi",
  "puv.fi",
  "ramk.fi",
  "sabi.eu.com",
  "samk.fi",
  "scp.fi",
  "shh.fi",
  "siba.fi",
  "teak.fi",
  "tpu.fi",
  "tukkk.fi",
  "tut.fi",
  "uiah.fi",
  "uku.fi",
  "ulapland.fi",
  "uta.fi",
  "utu.fi",
  "uwasa.fi",
  "aalto.fi",
  "metropolia.fi",
  "student.42.fr",
  "aea.asso.fr",
  "agroparistech.fr",
  "agro.roazhon.inra.fr",
  "agrosupdijon.fr",
  "ags.edu",
  "aup.fr",
  "bip.lon.ac.uk",
  "ceram.edu",
  "cesi.fr",
  "cfpj.com",
  "cnam.fr",
  "cnearc.fr",
  "cpe.fr",
  "cribx1.u-bordeaux.fr",
  "cust.univ-bpclermont.fr",
  "dauphine.fr",
  "devinci.fr",
  "e3i.univ-tours.fr",
  "eap.fr",
  "ebs-paris.com",
  "ecam.fr",
  "ece.fr",
  "imt-atlantique.net",
  "ec-lille.fr",
  "ec-lyon.fr",
  "ec-nantes.fr",
  "centrale-marseille.fr",
  "ecoles-idrac.com",
  "edu.edhec.com",
  "eerie.fr",
  "efpg.inpg.fr",
  "efrei.fr",
  "eivp-paris.fr",
  "emc-campus.com",
  "em-lyon.com",
  "emse.fr",
  "enac.fr",
  "ena.fr",
  "enic.fr",
  "enim.fr",
  "enise.fr",
  "enitab.fr",
  "enitac.fr",
  "enit.fr",
  "enitiaa-nantes.fr",
  "enm.meteo.fr",
  "enpc.fr",
  "ens2m.fr",
  "ensae.fr",
  "ensaia.u-nancy.fr",
  "ensai.fr",
  "ensait.fr",
  "ensam.inra.fr",
  "ensat.fr",
  "ens-cachan.fr",
  "ensccf.univ-bpclermont.fr",
  "ensc-lille.fr",
  "enscm.fr",
  "enscmu.univ-mulhouse.fr",
  "enscpb.u-bordeaux.fr",
  "enscp.jussieu.fr",
  "ensc-rennes.fr",
  "ensea.fr",
  "enseeiht.fr",
  "ensem.u-nancy.fr",
  "enserb.u-bordeaux.fr",
  "ens-fcl.fr",
  "ens.fr",
  "ensg.ign.fr",
  "ensg.u-nancy.fr",
  "ensica.fr",
  "ensic.u-nancy.fr",
  "ensieta.fr",
  "ensitm.fr",
  "ens-lyon.fr",
  "ensma.fr",
  "ensm-ales.fr",
  "mines-ales.fr",
  "mines-ales.org",
  "ensm-douai.fr",
  "ensmp.fr",
  "enspm.u-3mrs.fr",
  "ensta.fr",
  "enst-bretagne.fr",
  "enst.fr",
  "entpe.fr",
  "envt.fr",
  "epf.fr",
  "esa-purpan.fr",
  "esc-bordeaux.fr",
  "esc-brest.fr",
  "esc-clermont.fr",
  "escdijon.com",
  "escem.fr",
  "esc-lille.fr",
  "esc-marseille.fr",
  "escna.fr",
  "esc-normandie.fr",
  "escom.fr",
  "esc-pau.fr",
  "escp.fr",
  "escp.eu",
  "edu.escp.eu",
  "esc-reims.edu",
  "esc-rouen.fr",
  "esc-toulouse.fr",
  "eseo.fr",
  "esiae.fr",
  "esiea.fr",
  "esiee.fr",
  "esigelec.fr",
  "esim.imt-mrs.fr",
  "esitpa.org",
  "esme.fr",
  "espci.fr",
  "essca.asso.fr",
  "essec.fr",
  "esstin.u-nancy.fr",
  "estp.fr",
  "eudil.fr",
  "fupl.asso.fr",
  "grenoble-em.com",
  "esc-grenoble.fr",
  "groupe-esa.com",
  "hec.fr",
  "hec.edu",
  "hei.fr",
  "icam.fr",
  "icn-nancy.com",
  "icp.fr",
  "ict-toulouse.asso.fr",
  "iep.u-bordeaux.fr",
  "ifp.fr",
  "inp-fc.fr",
  "inpl-nancy.fr",
  "insa-lyon.fr",
  "insa-rennes.fr",
  "insa-rouen.fr",
  "insa-tlse.fr",
  "inseec.fr",
  "int-evry.fr",
  "iota.u-psud.fr",
  "ircam.fr",
  "ireste.fr",
  "isab.fr",
  "isa.fupl.asso.fr",
  "isara.fr",
  "isen.fr",
  "isep.fr",
  "isim.univ-montp2.fr",
  "ismcm-cesti.fr",
  "ismra.fr",
  "itech.fr",
  "jussieu.fr",
  "etu.upmc.fr",
  "upmc.fr",
  "etu.sorbonne-universite.fr",
  "sorbonne-universite.fr",
  "mines.u-nancy.fr",
  "montaigne.u-bordeaux.fr",
  "montesquieu.u-bordeaux.fr",
  "nancy.archi.fr",
  "paris4.sorbonne.fr",
  "paris.ensam.fr",
  "polytech-lille.fr",
  "polytech-marseille.fr",
  "polytechnique.fr",
  "polytechnique.edu",
  "schillerparis.com",
  "schillerstrasbourg.com",
  "sciences-po.fr",
  "sciencespo.fr",
  "sigu7.jussieu.fr",
  "sorbon.fr",
  "supaero.fr",
  "supco-amiens.fr",
  "supco-montpellier.fr",
  "supelec.fr",
  "u-3mrs.fr",
  "u-bordeaux2.fr",
  "ubfc.fr",
  "u-cergy.fr",
  "u-clermont1.fr",
  "uco.fr",
  "u-grenoble3.fr",
  "univ-grenoble-alpes.fr",
  "ujf-grenoble.fr",
  "uhb.fr",
  "uhp-nancy.fr",
  "unicaen.fr",
  "unice.fr",
  "unilim.fr",
  "univ-angers.fr",
  "univ-artois.fr",
  "univ-avignon.fr",
  "univ-bpclermont.fr",
  "univ-brest.fr",
  "univ-catholyon.fr",
  "univ-corse.fr",
  "univ-evry.fr",
  "univ-inpt.fr",
  "univ-lehavre.fr",
  "univ-lemans.fr",
  "univ-lille1.fr",
  "univ-lille2.fr",
  "univ-lille3.fr",
  "univ-littoral.fr",
  "univ-lorraine.fr",
  "univ-lr.fr",
  "univ-lyon1.fr",
  "univ-lyon2.fr",
  "univ-lyon3.fr",
  "univmed.fr",
  "univ-metz.fr",
  "univ-mlv.fr",
  "u-pem.fr",
  "etud.u-pem.fr",
  "univ-montp1.fr",
  "univ-montp2.fr",
  "univ-montp3.fr",
  "univ-mulhouse.fr",
  "uha.fr",
  "univ-nancy2.fr",
  "univ-nantes.fr",
  "univ-orleans.fr",
  "univ-paris12.fr",
  "univ-paris13.fr",
  "univ-paris1.fr",
  "univ-paris3.fr",
  "univ-paris5.fr",
  "univ-paris8.fr",
  "univ-pau.fr",
  "univ-perp.fr",
  "univ-poitiers.fr",
  "univ-reims.fr",
  "univ-rennes1.fr",
  "univ-rouen.fr",
  "univ-savoie.fr",
  "univ-st-etienne.fr",
  "univ-tln.fr",
  "univ-tlse1.fr",
  "univ-tlse2.fr",
  "univ-toulouse.fr",
  "univ-tours.fr",
  "univ-ubs.fr",
  "univ-valenciennes.fr",
  "u-paris10.fr",
  "u-paris2.fr",
  "u-picardie.fr",
  "ups-tlse.fr",
  "u-psud.fr",
  "univ-amu.fr",
  "up.univ-mrs.fr",
  "ushs.u-strasbg.fr",
  "utbm.fr",
  "utc.fr",
  "utt.fr",
  "uvsq.fr",
  "vet-alfort.fr",
  "vet-lyon.fr",
  "vet-nantes.fr",
  "www-ecpm.u-strasbg.fr",
  "www-engees.u-strasbg.fr",
  "www-ensais.u-strasbg.fr",
  "grenoble-inp.fr",
  "inpg.fr",
  "www-ensps.u-strasbg.fr",
  "www-ulp.u-strasbg.fr",
  "www-urs.u-strasbg.fr",
  "unistra.fr",
  "etu.unistra.fr",
  "epitech.eu",
  "epitech.net",
  "epita.fr",
  "ipsa.fr",
  "etna.io",
  "supbiotech.fr",
  "univ-ag.fr",
  "upf.pf",
  "uob.ga",
  "aiu.edu.gm",
  "euclid.int",
  "utg.edu.gm",
  "atsu.edu.ge",
  "cu.edu.ge",
  "esm-tbilisi.ge",
  "gruni.edu.ge",
  "gsau.edu.ge",
  "gtu.edu.ge",
  "ibsu.edu.ge",
  "iliauni.edu.ge",
  "saba.edu.ge",
  "sangu.ge",
  "tiaa.edu.ge",
  "tsmu.edu",
  "tsu.edu.ge",
  "akad.de",
  "asfh-berlin.de",
  "augustana.de",
  "bethel.de",
  "bits-iserlohn.de",
  "cbs.de",
  "code.berlin",
  "dhv-speyer.de",
  "diploma.de",
  "dshs-koeln.de",
  "eap.net",
  "eba-muenchen.de",
  "ebs.de",
  "ecla.de",
  "efh-bochum.de",
  "eh-darmstadt.de",
  "efh-freiburg.de",
  "efh-hannover.de",
  "efhlu.de",
  "efh-reutlingen-ludwigsburg.de",
  "ehs-dresden.de",
  "ems-mainz.de",
  "eufh.de",
  "euv-frankfurt-o.de",
  "evfh-berlin.de",
  "evfh-nuernberg.de",
  "fern-fh.de",
  "fernuni-hagen.de",
  "fh-aachen.de",
  "fh-aschaffenburg.de",
  "fh-augsburg.de",
  "fh-bad-honnef.de",
  "fh-biberach.de",
  "fh-bielefeld.de",
  "fh-bingen.de",
  "fh-bochum.de",
  "hochschule-bonn-rhein-sieg.de",
  "h-brs.de",
  "fh-brandenburg.de",
  "th-brandenburg.de",
  "th-deg.de",
  "fh-dortmund.de",
  "hs-duesseldorf.de",
  "fhdw.bib.de",
  "fhdw.de",
  "fh-eberswalde.de",
  "fh-erfurt.de",
  "fh-flensburg.de",
  "fh-frankfurt.de",
  "fh-fresenius.de",
  "fh-furtwangen.de",
  "fh-gelsenkirchen.de",
  "fh-giessen.de",
  "fh-hamburg.de",
  "haw-hamburg.de",
  "fh-hannover.de",
  "fh-heidelberg.de",
  "fh-heilbronn.de",
  "fh-hildesheim.de",
  "fh-hof.de",
  "thi.de",
  "fh-isny.de",
  "fh-jena.de",
  "fh-karlsruhe.de",
  "fh-kempten.de",
  "fh-kiel.de",
  "fh-kl.de",
  "fh-koblenz.de",
  "th-koeln.de",
  "fh-koeln.de",
  "fh-konstanz.de",
  "fhkt.de",
  "fh-landshut.de",
  "fh-lausitz.de",
  "fh-lippe.de",
  "fh-ludwigshafen.de",
  "fh-luebeck.de",
  "fh-mainz.de",
  "fh-mannheim.de",
  "fh-merseburg.de",
  "fhm-mittelstand.de",
  "fh-muenchen.de",
  "hm.edu",
  "fh-muenster.de",
  "fh-nb.de",
  "hs-neu-ulm.de",
  "fh-niederrhein.de",
  "fhnon.de",
  "fh-nordhausen.de",
  "fh-nuernberg.de",
  "fh-nuertingen.de",
  "fhoebb.de",
  "fh-offenburg.de",
  "fh-oow.de",
  "fh-osnabrueck.de",
  "fh-ottersberg.de",
  "fh-pforzheim.de",
  "fh-potsdam.de",
  "fh-regensburg.de",
  "fh-reutlingen.de",
  "fh-riedlingen.de",
  "fh-rosenheim.de",
  "fh-rottenburg.de",
  "fh-schmalkalden.de",
  "fh-schwaebischhall.de",
  "fhs-mannheim.de",
  "fhs-moritzburg.de",
  "fh-stralsund.de",
  "fh-telekom-leipzig.de",
  "fh-trier.de",
  "fht-stuttgart.de",
  "fhtw-berlin.de",
  "htw-berlin.de",
  "hs-ulm.de",
  "fhvr.berlin.de",
  "fhw-berlin.de",
  "fh-wedel.de",
  "fh-weihenstephan.de",
  "fh-weingarten.de",
  "fh-westkueste.de",
  "fh-wiesbaden.de",
  "ostfalia.de",
  "fh-wolfenbuettel.de",
  "fh-worms.de",
  "fhwt.de",
  "fh-wuerzburg.de",
  "fh-zwickau.de",
  "fom.de",
  "fu-berlin.de",
  "hanseuni.de",
  "hcu-hamburg.de",
  "hdm-stuttgart.de",
  "hertie-school.org",
  "hfb.de",
  "hfg-gmuend.de",
  "hfph.mwn.de",
  "hfp.mhn.de",
  "hhl.de",
  "himh.de",
  "hjs.uni-heidelberg.de",
  "hs-albsig.de",
  "hs-anhalt.de",
  "student.hs-anhalt.de",
  "hs-bremen.de",
  "hs-bremerhaven.de",
  "hs-coburg.de",
  "h-da.de",
  "hs-esslingen.de",
  "hs-fulda.de",
  "hs-harz.de",
  "hs-magdeburg.de",
  "hs-wismar.de",
  "hs-zigr.de",
  "htw-dresden.de",
  "htwk-leipzig.de",
  "htwm.de",
  "hs-mittweida.de",
  "campus-m-university.de",
  "campusm21.de",
  "htw-saarland.de",
  "hu-berlin.de",
  "hwp-hamburg.de",
  "ihi-zittau.de",
  "international.fh-aalen.de",
  "ism-dortmund.de",
  "isnm.de",
  "i-u.de",
  "jacobs-university.de",
  "karlshochschule.de",
  "kath-fh-nord.de",
  "kfb-berlin.de",
  "kfh-freiburg.de",
  "kfh-mainz.de",
  "kfhnw.de",
  "kh-berlin.de",
  "khsa.de",
  "ksfh.de",
  "ku-eichstaett.de",
  "kunstakademie-duesseldorf.de",
  "merkur-fh.org",
  "merz-akademie.de",
  "mfh-iserlohn.de",
  "mh-hannover.de",
  "mh-trossingen.de",
  "mu-luebeck.de",
  "muthesius.de",
  "nordakademie.de",
  "paderborn.de",
  "pfh-goettingen.de",
  "ph-erfurt.de",
  "ph-freiburg.de",
  "ph-gmuend.de",
  "ph-heidelberg.de",
  "philtheol-augustin.de",
  "ph-karlsruhe.de",
  "ph-ludwigsburg.de",
  "ph-weingarten.de",
  "popakademie.de",
  "privatfh-da.de",
  "pth-bb.de",
  "pth-muenster.de",
  "pthv.de",
  "rauheshaus.de",
  "rfh-koeln.de",
  "ruhr-uni-bochum.de",
  "rwth-aachen.de",
  "selk.de",
  "siewerth-akademie.de",
  "siu-heidelberg.de",
  "srh-berlin.de",
  "st-georgen.uni-frankfurt.de",
  "stw.de",
  "tfh-berlin.de",
  "tfh-bochum.de",
  "tfh-wildau.de",
  "thh-friedensau.de",
  "tiho-hannover.de",
  "tu-berlin.de",
  "tu-braunschweig.de",
  "tu-bs.de",
  "tu-chemnitz.de",
  "tu-clausthal.de",
  "tu-cottbus.de",
  "tu-darmstadt.de",
  "tu-dresden.de",
  "tu-freiberg.de",
  "tuhh.de",
  "tu-ilmenau.de",
  "tum.de",
  "tum.edu",
  "mytum.de",
  "uni-augsburg.de",
  "uni-bamberg.de",
  "uni-bayreuth.de",
  "uni-bielefeld.de",
  "uni-bonn.de",
  "uni-bremen.de",
  "unibw-hamburg.de",
  "unibw-muenchen.de",
  "tu-dortmund.de",
  "udo.edu",
  "uni-duesseldorf.de",
  "hhu.de",
  "uni-duisburg-essen.de",
  "uni-erfurt.de",
  "fau.de",
  "uni-erlangen.de",
  "uni-flensburg.de",
  "uni-frankfurt.de",
  "uni-freiburg.de",
  "uni-giessen.de",
  "uni-goettingen.de",
  "uni-greifswald.de",
  "uni-halle.de",
  "uni-hamburg.de",
  "uni-hannover.de",
  "uni-heidelberg.de",
  "uni-hildesheim.de",
  "uni-hohenheim.de",
  "uni-jena.de",
  "kit.edu",
  "uni-kassel.de",
  "uni-kiel.de",
  "uni-kl.de",
  "uni-koblenz-landau.de",
  "uni-koeln.de",
  "uni-konstanz.de",
  "uni-leipzig.de",
  "uni-lueneburg.de",
  "uni-magdeburg.de",
  "uni-mainz.de",
  "uni-mannheim.de",
  "uni-marburg.de",
  "lmu.de",
  "uni-muenchen.de",
  "uni-muenster.de",
  "wwu.de",
  "uol.de",
  "uni-oldenburg.de",
  "uni-osnabrueck.de",
  "uni-paderborn.de",
  "uni-passau.de",
  "uni-potsdam.de",
  "uni-polsdam.de",
  "uni-regensburg.de",
  "uni-rostock.de",
  "uni-saarland.de",
  "uni-siegen.de",
  "uni-stuttgart.de",
  "uni-trier.de",
  "uni-tuebingen.de",
  "uni-ulm.de",
  "uni-vechta.de",
  "uni-weimar.de",
  "uni-wh.de",
  "uni-wuerzburg.de",
  "uni-wuppertal.de",
  "whu-koblenz.de",
  "atu.edu.gh",
  "acighana.org",
  "anuc.edu.gh",
  "ashesi.edu.gh",
  "central.edu.gh",
  "cctu.edu.gh",
  "cauc.edu.gh",
  "ghanacu.org",
  "gimpa.edu.gh",
  "gbuc.edu.gh",
  "gtuc.edu.gh",
  "htu.edu.gh",
  "iug.edu.gh",
  "kc.edu.gh",
  "knust.edu.gh",
  "ktu.edu.gh",
  "kstu.edu.gh",
  "lancaster.edu.gh",
  "pacuc.edu.gh",
  "presbyuniversity.edu.gh",
  "regentghana.net",
  "stu.edu.gh",
  "ttu.edu.gh",
  "trinity.edu.gh",
  "ucc.edu.gh",
  "uds.edu.gh",
  "uew.edu.gh",
  "ug.edu.gh",
  "vvu.edu.gh",
  "zucghana.org",
  "acg.gr",
  "act.edu",
  "aegean.gr",
  "aspete.gr",
  "agsm.gr",
  "alba.edu.gr",
  "alba.acg.edu",
  "asfa.gr",
  "aua.gr",
  "aueb.gr",
  "auth.gr",
  "cityu.gr",
  "dei.edu.gr",
  "duth.gr",
  "eap.gr",
  "hua.gr",
  "ihu.gr",
  "ihu.edu.gr",
  "ntua.gr",
  "panteion.gr",
  "sse.army.gr",
  "sse.gr",
  "uniwa.gr",
  "hmu.gr",
  "tuc.gr",
  "ionio.gr",
  "unipi.gr",
  "uoa.gr",
  "uoc.gr",
  "uoi.gr",
  "uom.gr",
  "uop.gr",
  "uowm.gr",
  "upatras.gr",
  "uth.gr",
  "ilisimatusarfik.gl",
  "sgu.edu",
  "univ-antilles.fr",
  "uog.edu",
  "cucv.edu.gt",
  "cunoc.edu.gt",
  "galileo.edu",
  "ufm.edu.gt",
  "umg.edu.gt",
  "unis.edu.gt",
  "upana.edu.gt",
  "url.edu.gt",
  "urural.edu.gt",
  "usac.edu.gt",
  "uvg.edu.gt",
  "uganc.org",
  "ujnk.org",
  "univ-kag.org",
  "utad-petel-edu.org",
  "gemsvilleuniversity.com",
  "greenheartmed.org",
  "tauedu.org",
  "uog.edu.gy",
  "auchaiti.org",
  "ucnh.org",
  "ueh.edu.ht",
  "undh.org",
  "uneph.org",
  "uniq.edu",
  "pust.urbe.it",
  "unigre.urbe.it",
  "upra.org",
  "ups.urbe.it",
  "urbaniana.edu",
  "ujcv.edu.hn",
  "unah.edu.hn",
  "unicah.edu",
  "unimetro.edu.hn",
  "unitec.edu",
  "upnfm.edu.hn",
  "usps.edu.hn",
  "usap.edu",
  "uth.hn",
  "zamorano.edu",
  "chuhai.edu.hk",
  "cityu.edu.hk",
  "um.cityu.edu.hk",
  "my.cityu.edu.hk",
  "cuhk.edu.hk",
  "link.cuhk.edu.hk",
  "hkapa.edu",
  "hkbu.edu.hk",
  "life.hkbu.edu.hk",
  "associate.hkbu.edu.hk",
  "hksyu.edu",
  "hku.hk",
  "ln.edu.hk",
  "ln.hk",
  "hkmu.edu.hk",
  "ouhk.edu.hk",
  "polyu.edu.hk",
  "connect.polyu.hk",
  "ust.hk",
  "connect.ust.hk",
  "s.eduhk.hk",
  "eduhk.hk",
  "agr.unideb.hu",
  "andrassyuni.hu",
  "arts7.hu",
  "avicenna.hu",
  "bdtf.hu",
  "bke.hu",
  "bme.hu",
  "ceu.hu",
  "dote.hu",
  "drk.hu",
  "duf.hu",
  "efe.hu",
  "ehf.hu",
  "elte.hu",
  "filmacademy.hu",
  "hiete.hu",
  "hupe.hu",
  "jpte.hu",
  "kee.hu",
  "kgre.hu",
  "kodolanyi.hu",
  "lfze.hu",
  "lib.klte.hu",
  "mif.hu",
  "nyme.hu",
  "pote.hu",
  "ppke.hu",
  "pte.hu",
  "sote.hu",
  "sze.hu",
  "szie.hu",
  "tkbf.hu",
  "unideb.hu",
  "uni-miskolc.hu",
  "uni-pannon.hu",
  "univet.hu",
  "u-szeged.hu",
  "bifrost.is",
  "hi.is",
  "holar.is",
  "hsvest.is",
  "hvanneyri.is",
  "iti.is",
  "khi.is",
  "lhi.is",
  "ru.is",
  "unak.is",
  "ntruhs.ap.nic.in",
  "aaidu.org",
  "aau.ac.in",
  "ahduni.edu.in",
  "aiims.ac.in",
  "aisectuniversity.ac.in",
  "alagappauniversity.ac.in",
  "alldunivpio.org",
  "altmeduniversity.com",
  "altmedworld.net",
  "amity.edu",
  "amrita.edu",
  "amtuni.com",
  "amu.ac.in",
  "andhrauniversity.info",
  "angrau.net",
  "annamalaiuniversity.ac.in",
  "annauniv.edu.in",
  "annauniv.edu",
  "apsurewa.nic.in",
  "aryainstitutejpr.com",
  "assamuniversity.nic.in",
  "ayurveduniversity.com",
  "bamu.net",
  "bamu.nic.in",
  "banasthali.org",
  "bankurauniv.com",
  "bau.nic.in",
  "bbauindia.org",
  "bckv.edu.in",
  "bdu.ernet.in",
  "becs.ac.in",
  "bengaluruuniversity.com",
  "bhagwantuniversity.com",
  "bharathi.ernet.in",
  "bharathuniv.com",
  "bharatividyapeeth.edu",
  "bhavuni.edu",
  "bhu.ac.in",
  "bhub.ori.nic.in",
  "bitmesraranchi.org",
  "bits-pilani.ac.in",
  "bnmu.in",
  "bput.org",
  "brabu.net",
  "braou.ac.in",
  "nitj.ac.in",
  "brauagra.com",
  "bsauniv.ac.in",
  "bubhopal.nic.in",
  "vitbhopal.ac.in",
  "bundelkhanduniversity.org",
  "burdwanuniversity.ac.in",
  "caluniv.ac.in",
  "cau.org.in",
  "ccsuniversity.ac.in",
  "ccsuniversity.org",
  "cesbm.ac.in",
  "christuniversity.in",
  "ciefl.org",
  "cife.edu.in",
  "cihts.ac.in",
  "cmjuniversity.edu.in",
  "csauk.ac.in",
  "cusat.ac.in",
  "cvru.ac.in",
  "da-iict.org",
  "darulhuda.com",
  "davuniversity.org",
  "davv.ac.in",
  "dbatechuni.org",
  "dbskkv.org",
  "dbs.tn.nic.in",
  "dceonline.net",
  "ddugu.edu.in",
  "deccancollegepune.ac.in",
  "coeptech.ac.in",
  "dei.ac.in",
  "dibru.ernet.in",
  "dravidianuniversity.ac.in",
  "du.ac.in",
  "dypatiluniversity.org",
  "eiilmuniversity.ac.in",
  "fatimacollege.net",
  "fri.icfre.gov.in",
  "gbpuat.ac.in",
  "ggu.ac.in",
  "gipe.ernet.in",
  "gitam.edu",
  "gju.ernet.in",
  "gkvharidwar.org",
  "gnduonline.org",
  "government.ind.in",
  "gtu.ac.in",
  "gujaratuniversity.org.in",
  "gulbargauniversity.kar.nic.in",
  "gu.nic.in",
  "gurukuluniversity.org",
  "hau.ernet.in",
  "hillagric.ernet.in",
  "hnbgu.ac.in",
  "hnluraipur.com",
  "sriramachandra.edu.in",
  "hpuniv.nic.in",
  "iari.ernet.in",
  "iaseduniv.org",
  "icfai.org",
  "iudehradun.edu.in",
  "iutripura.edu.in",
  "iusikkim.edu.in",
  "iumizoram.edu.in",
  "iumeghalaya.edu.in",
  "iujharkhand.edu.in",
  "iunagaland.edu.in",
  "iujaipur.edu.in",
  "iuraipur.edu.in",
  "iuhimachal.edu.in",
  "ifheindia.org",
  "igau.edu.in",
  "igidr.ac.in",
  "igims.org",
  "ignou.ac.in",
  "iiita.com",
  "iiitmk.ac.in",
  "iiitd.ac.in",
  "squareit.edu.in",
  "iiit-bh.ac.in",
  "iiitb.ac.in",
  "iimtrichy.ac.in",
  "iiitg.ac.in",
  "iiitvadodara.ac.in",
  "iiitkota.ac.in",
  "iipsindia.org",
  "iisc.ernet.in",
  "iisc.ac.in",
  "iitb.ac.in",
  "iitd.ernet.in",
  "iitd.ac.in",
  "iitgn.ac.in",
  "iitg.ernet.in",
  "iitg.ac.in",
  "iith.ac.in",
  "iitk.ac.in",
  "iitkgp.ernet.in",
  "iitm.ac.in",
  "itu.edu.pk",
  "mitindia.edu",
  "iitr.ac.in",
  "iitp.ac.in",
  "iiti.ac.in",
  "iitj.ac.in",
  "iksvv.com",
  "ilidelhi.org",
  "indusuni.ac.in",
  "integraluniversity.ac.in",
  "ipu.ac.in",
  "isbm.org.in",
  "isical.ac.in",
  "ismdhanbad.ac.in",
  "itmindia.edu",
  "iur.ac.in",
  "ivri.nic.in",
  "jadavpur.edu",
  "jainuniversity.org",
  "jamiahamdard.edu",
  "jammuvarsity.org",
  "jiit.ac.in",
  "jiwaji.edu",
  "jmi.nic.in",
  "jncasr.ac.in",
  "jnkvv.nic.in",
  "jntuh.ac.in",
  "jnu.ac.in",
  "jnujaipur.ac.in",
  "jodhpurnationaluniversity.com",
  "jpv.bih.nic.in",
  "jrnrvu.org",
  "jvbi.ac.in",
  "kalingauniversity.com",
  "kanchiuniv.ac.in",
  "kannadauniversity.org",
  "kannuruniversity.ac.in",
  "kanpuruniversity.org",
  "kanpuruniversity.org",
  "karnatakastateopenuniversity.in",
  "karpagamuniv.com",
  "karunya.edu",
  "kashmiruniversity.net",
  "kau.edu",
  "keralauniversity.ac.in",
  "kimi.kohinoor.ac.in",
  "klyuniv.ac.in",
  "krishnauniversity.ac.in",
  "ksdsu.edu.in",
  "kslu.ac.in",
  "kud.ac.in",
  "kufos.ac.in",
  "kukinfo.com",
  "kct.ac.in",
  "kumaununiversity.org",
  "kuvempu.ac.in",
  "kuwarangal.com",
  "ldceahd.org",
  "lnipe.edu",
  "lnmu.bih.nic.in",
  "lucknowuniv.org",
  "macfast.org",
  "magadhuniversity.org",
  "mangaloreuniversity.ac.in",
  "manipal.edu",
  "manipur.nic.in",
  "manuu.ac.in",
  "mcu.ac.in",
  "mdsuajmer.ac.in",
  "mdurohtak.com",
  "mgkvp.ac.in",
  "mgsubikaner.ac.in",
  "mgu.ac.in",
  "mjpru.ac.in",
  "mkuhyd.com",
  "mkv2.mah.nic.in",
  "mlsu.ac.in",
  "mnnit.ac.in",
  "mpbou.org",
  "mpkv.mah.nic.in",
  "msubaroda.ac.in",
  "mu.ac.in",
  "nagarjunauniversity.ac.in",
  "nagauniv.org.in",
  "nagpur-university.com",
  "nalandaopenuniversity.info",
  "nationalmuseumindia.org",
  "nbu.ac.in",
  "ndri.nic.in",
  "nduat.nic.in",
  "nehu.ac.in",
  "ngu.ac.in",
  "nimhans.kar.nic.in",
  "nims.ap.nic.in",
  "nims.net.in",
  "nirmauni.ac.in",
  "nitie.edu",
  "nita.ac.in",
  "nitandhra.ac.in",
  "nitap.in",
  "nitc.ac.in",
  "nitdelhi.ac.in",
  "nitdgp.ac.in",
  "nitgoa.ac.in",
  "nith.ac.in",
  "nitjsr.ac.in",
  "nitkkr.net",
  "nitmanipur.ac.in",
  "nitm.ac.in",
  "nitmz.ac.in",
  "nitnagaland.ac.in",
  "nitp.ac.in",
  "nitpy.ac.in",
  "nitrr.ac.in",
  "nitrkl.ac.in",
  "nitsikkim.ac.in",
  "nits.ac.in",
  "nitsri.net",
  "nitk.ac.in",
  "nitk.edu.in",
  "nitt.edu",
  "nituk.ac.in",
  "nitw.ernet.in",
  "nls.ac.in",
  "nmims.edu",
  "nmu.ac.in",
  "nsit.ac.in",
  "osmania.ac.in",
  "padmavatiwomen-univ.org",
  "pdpu.ac.in",
  "pau.edu",
  "pdkv.ac.in",
  "paruluniversity.ac.in",
  "pec.ac.in",
  "pes.edu",
  "pgimer.nic.in",
  "pondiuni.org",
  "psgtech.edu",
  "ptetjnvu.org",
  "ptu.ac.in",
  "puchd.ac.in",
  "punjabiuniversity.ac.in",
  "puonline.bih.nic.in",
  "pusavarsity.org.in",
  "rabindrabharatiuniversity.net",
  "ranchiuniversity.org.in",
  "raubikaner.org",
  "rdunijbpin.org",
  "rgtu.net",
  "rgu.ac.in",
  "riths.ac.in",
  "rmlau.ac.in",
  "rsuniversity.com",
  "rtu.ac.in",
  "ruraluniversity-chitrakoot.org",
  "sagaruniversity.nic.in",
  "sambalpuruniversitypgc.in",
  "sanskrit.nic.in",
  "sastra.edu",
  "sastra.ac.in",
  "sathyabamauniv.ac.in",
  "saurashtrauniversity.edu",
  "sctimst.ker.nic.in",
  "sdau.edu.in",
  "sgpgi.ac.in",
  "sgu.ernet.in",
  "shobhituniversity.ac.in",
  "shridharuniversity.ac.in",
  "simhapuriuniv.ac.in",
  "siu.edu.in",
  "sjsv.nic.in",
  "skmu.edu.in",
  "skuastkashmir.ac.in",
  "skuniv.ap.nic.in",
  "slbsrsv.ac.in",
  "smkfomra.net",
  "smu.edu.in",
  "sndt.edu",
  "soauniversity.ac.in",
  "southasianuniversity.org",
  "spa.ac.in",
  "spuniv.org",
  "spuvvn.edu",
  "srisathyasai.org.in",
  "srkdc.org",
  "srmuniv.ac.in",
  "srtmun.org",
  "ssgmce.org",
  "ssus.ac.in",
  "ssvv.up.nic.in",
  "su.digitaluniversity.ac",
  "svbpuctm.org",
  "svnit.ac.in",
  "svimstpt.ap.nic.in",
  "svuni.ap.nic.in",
  "tamiluniversity.ac.in",
  "tanuvas.com",
  "tce.edu",
  "teluguuniversity.ac.in",
  "tezu.ernet.in",
  "thenewcollege.edu.in",
  "tiet.ac.in",
  "tiss.edu",
  "tmbu.org",
  "tmv.edu.in",
  "tndalu.org",
  "tnmmu.ac.in",
  "tnuniv.ac.in",
  "manit.ac.in",
  "mnit.ac.in",
  "tripurauniv.in",
  "tshmc.org",
  "tumkuruniversity.ac.in",
  "tvuni.in",
  "uasbng.kar.nic.in",
  "uasd.net",
  "uktech.ac.in",
  "uma.ac.in",
  "unical.ac.in",
  "unigoa.ac.in",
  "uni-mysore.ac.in",
  "unipune.ernet.in",
  "uniraj.org",
  "unishivaji.ac.in",
  "unom.ac.in",
  "uohyd.ernet.in",
  "uok.ac.in",
  "uor.org",
  "uou.ac.in",
  "uptu.ac.in",
  "utkaluniv.org",
  "vbspu.ac.in",
  "vbu.co.in",
  "vidyasagar.ac.in",
  "vignanuniversity.org",
  "vikramuniversity.org",
  "vinayakamissions.com",
  "visva-bharati.ac.in",
  "vit.ac.in",
  "vksu-ara.org",
  "vmou.ac.in",
  "vnitnagpur.ac.in",
  "vssut.ac.in",
  "vtu.ac.in",
  "wbuafs.nic.in",
  "wbut.net",
  "ycmou.com",
  "ysparmaruniversity.org",
  "akfarmitseda.ac.id",
  "akprind.ac.id",
  "amikom.ac.id",
  "stikesrsanwarmedika.ac.id",
  "atmajaya.ac.id",
  "bakrie.ac.id",
  "binus.ac.id",
  "stikes-ppni.ac.id",
  "bunghatta.ac.id",
  "dinus.ac.id",
  "gunadarma.ac.id",
  "ibnuchaldun.ac.id",
  "indonusa.ac.id",
  "ipb.ac.id",
  "ista.ac.id",
  "itats.ac.id",
  "itb.ac.id",
  "its.ac.id",
  "janabadra.ac.id",
  "jayabaya.ac.id",
  "machung.ac.id",
  "umaha.ac.id",
  "mahendradatta.org",
  "um.ac.id",
  "maranatha.edu",
  "mercubuana.ac.id",
  "moestopo.ac.id",
  "narotama.ac.id",
  "nommensen.org",
  "pancabudi.ac.id",
  "paramadina.ac.id",
  "pasim.ac.id",
  "perbanas.ac.id",
  "perbanas.id",
  "petra.ac.id",
  "pmbs.ac.id",
  "pnj.ac.id",
  "pnl.ac.id",
  "polban.ac.id",
  "polines.ac.id",
  "polinpdg.ac.id",
  "polnep.ac.id",
  "poltek-malang.ac.id",
  "poltekpos.ac.id",
  "poltesa.ac.id",
  "puterabatam.ac.id",
  "sarjanawiyata.ac.id",
  "sgu.ac.id",
  "sinus.ac.id",
  "stan.ac.id",
  "stptrisakti.ac.id",
  "telkomuniversity.ac.id",
  "unklab.ac.id",
  "trisakti.ac.id",
  "uad.ac.id",
  "uajy.ac.id",
  "ub.ac.id",
  "ubaya.ac.id",
  "ubhara.ac.id",
  "ubharajaya.ac.id",
  "ubm.ac.id",
  "uda.ac.id",
  "ugm.ac.id",
  "ui.ac.id",
  "uii.ac.id",
  "uika-bogor.ac.id",
  "uinjkt.ac.id",
  "uir.ac.id",
  "uisu.ac.id",
  "ukdw.ac.id",
  "uki.ac.id",
  "ukit-tomohon.org",
  "ukrida.ac.id",
  "uksw.edu",
  "umi.ac.id",
  "umi-mdn.edu",
  "umj.ac.id",
  "umk.ac.id",
  "umm.ac.id",
  "ummgl.ac.id",
  "umsida.ac.id",
  "ums.ac.id",
  "umsu.ac.id",
  "umy.ac.id",
  "unai.edu",
  "unair.ac.id",
  "unand.ac.id",
  "unas.ac.id",
  "uncen.ac.id",
  "undana.ac.id",
  "undip.ac.id",
  "unej.ac.id",
  "unesa.ac.id",
  "ung.ac.id",
  "unhalu.ac.id",
  "unhamzah.ac.id",
  "unhas.ac.id",
  "unib.ac.id",
  "unigoro.ac.id",
  "unika.ac.id",
  "unikal.ac.id",
  "unik-kediri.ac.id",
  "unikom.ac.id",
  "unila.ac.id",
  "unilak.ac.id",
  "unima.ac.id",
  "unimed.ac.id",
  "uninus.ac.id",
  "unipdu.ac.id",
  "unira.ac.id",
  "unisba.ac.id",
  "unisma.ac.id",
  "unismuh.ac.id",
  "unisri.ac.id",
  "unissula.ac.id",
  "unistangerang.ac.id",
  "unitomo.ac.id",
  "universitas-trilogi.ac.id",
  "trunojoyo.ac.id",
  "univpancasila.ac.id",
  "unipasby.ac.id",
  "unja.ac.id",
  "unj.ac.id",
  "unjani.ac.id",
  "unkhair.ac.id",
  "unkris.ac.id",
  "unlam.ac.id",
  "unm.ac.id",
  "unmas.org",
  "unmer.ac.id",
  "unmer-madiun.ac.id",
  "unmuhjember.ac.id",
  "unmuhmataram.com",
  "unmul.ac.id",
  "wicida.ac.id",
  "unnes.ac.id",
  "unp.ac.id",
  "unpad.ac.id",
  "unpak.ac.id",
  "unpar.ac.id",
  "unpas.ac.id",
  "unpatti.ac.id",
  "unr.ac.id",
  "unram.ac.id",
  "unri.ac.id",
  "uns.ac.id",
  "unsada.ac.id",
  "unsil.ac.id",
  "unsoed.ac.id",
  "unsrat.ac.id",
  "unsri.ac.id",
  "unswagati-crb.ac.id",
  "unsyiah.ac.id",
  "untad.ac.id",
  "untag.ac.id",
  "untag-banyuwangi.ac.id",
  "untagcirebon.info",
  "untag-jkt.org",
  "untag-smd.ac.id",
  "untagsmg.ac.id",
  "untan.ac.id",
  "untar.ac.id",
  "untirta.ac.id",
  "unud.ac.id",
  "unwiku.ac.id",
  "uny.ac.id",
  "up45.ac.id",
  "uph.edu",
  "upi.edu",
  "upnvj.ac.id",
  "upnjatim.ac.id",
  "upnyk.ac.id",
  "upr.ac.id",
  "upstegal.ac.id",
  "usd.ac.id",
  "usi.ac.id",
  "usm.ac.id",
  "usu.ac.id",
  "ut.ac.id",
  "untidar.ac.id",
  "utp.ac.id",
  "widyagama.ac.id",
  "widyakarya.ac.id",
  "widyatama.ac.id",
  "wijayakusumasby.ac.id",
  "wima.ac.id",
  "wisnuwardhana.ac.id",
  "yai.ac.id",
  "yarsi.ac.id",
  "acmt.ac.ir",
  "adiban.ac.ir",
  "ajums.ac.ir",
  "aliabadiau.ac.ir",
  "miu.ac.ir",
  "arakmu.ac.ir",
  "araku.ac.ir",
  "arakut.ac.ir",
  "kut.ac.ir",
  "art.ac.ir",
  "arums.ac.ir",
  "atu.ac.ir",
  "aums.ac.ir",
  "aut.ac.ir",
  "azad.ac.ir",
  "alzahra.ac.ir",
  "basu.ac.ir",
  "bihe.org",
  "birjand.ac.ir",
  "bmsu.ac.ir",
  "bojnourdiau.ac.ir",
  "bpums.ac.ir",
  "bums.ac.ir",
  "scu.ac.ir",
  "dehaghan.ac.ir",
  "delijanpnu.ac.ir",
  "deylaman.ac.ir",
  "du.ac.ir",
  "falavarjan.ac.ir",
  "fsriau.ac.ir",
  "fums.ac.ir",
  "gau.ac.ir",
  "gmu.ac.ir",
  "gorganiau.ir",
  "goums.ac.ir",
  "guilan.ac.ir",
  "gums.ac.ir",
  "hormozgan.ac.ir",
  "hums.ac.ir",
  "hyrcania.ac.ir",
  "iasbs.ac.ir",
  "iau-ahar.ac.ir",
  "iau-arak.ac.ir",
  "iauardabil.ac.ir",
  "iauba.ac.ir",
  "iaubos.ac.ir",
  "iau-boukan.ac.ir",
  "iauctb.ac.ir",
  "iau-garmsar.ac.ir",
  "iauh.ac.ir",
  "iauk.ac.ir",
  "iaukb.ir",
  "iau-lahijan.ac.ir",
  "iaumajlesi.ac.ir",
  "iaumehriz.com",
  "iaun.ac.ir",
  "iauq.ac.ir",
  "iauro.ac.ir",
  "iau-saveh.ac.ir",
  "iausdj.ac.ir",
  "iaushab.ac.ir",
  "iau-shahrood.ac.ir",
  "iaushiraz.ac.ir",
  "iau-shirvan.ac.ir",
  "iausr.ac.ir",
  "iaut.ac.ir",
  "iautb.ac.ir",
  "iautmu.ac.ir",
  "iau-tnb.ac.ir",
  "iauvaramin.ac.ir",
  "iauyazd.ac.ir",
  "ikiu.ac.ir",
  "ilam.ac.ir",
  "imamreza.ac.ir",
  "isu.ac.ir",
  "iums.ac.ir",
  "iust.ac.ir",
  "iut.ac.ir",
  "jami.ac.ir",
  "janahiau.ac.ir",
  "jums.ac.ir",
  "kamalolmolk.ac.ir",
  "karuniversity.com",
  "kashanu.ac.ir",
  "kau.ac.ir",
  "kaums.ac.ir",
  "kgut.ac.ir",
  "khuisf.ac.ir",
  "kish.ac.ir",
  "km.isfpnu.ac.ir",
  "kmu.ac.ir",
  "kntu.ac.ir",
  "kums.ac.ir",
  "teh-lavasan.pnu.ac.ir",
  "lu.ac.ir",
  "lums.ac.ir",
  "mahshahriau.ac.ir",
  "malayeru.ac.ir",
  "malekanpnu.ac.ir",
  "mamasaniiau.ac.ir",
  "mau.ac.ir",
  "maybod.ac.ir",
  "mazums.ac.ir",
  "medilam.hbi.ir",
  "medsab.ac.ir",
  "modares.ac.ir",
  "mofidu.ac.ir",
  "mshdiau.ac.ir",
  "mubabol.ac.ir",
  "mui.ac.ir",
  "muk.ac.ir",
  "mums.ac.ir",
  "muq.ac.ir",
  "najafabad-uast.ac.ir",
  "nit.ac.ir",
  "pgu.ac.ir",
  "piau.ac.ir",
  "pnu.ac.ir",
  "pnum.ac.ir",
  "pnut.ac.ir",
  "put.ac.ir",
  "pwit.ac.ir",
  "qiau.ac.ir",
  "qom.ac.ir",
  "qom-iau.ac.ir",
  "qums.ac.ir",
  "qut.ac.ir",
  "raghebisf.ac.ir",
  "razi.ac.ir",
  "riau.ac.ir",
  "rums.ac.ir",
  "sadjad.ac.ir",
  "sadra.ac.ir",
  "sbmu.ac.ir",
  "sbu.ac.ir",
  "sdi.ac.ir",
  "semnan.ac.ir",
  "semnaniau.ac.ir",
  "sem-ums.ac.ir",
  "ses.ac.ir",
  "shahed.ac.ir",
  "shahroodut.ac.ir",
  "shahryariau.ac.ir",
  "sharif.ac.ir",
  "shbu.ac.ir",
  "shirazu.ac.ir",
  "shmu.ac.ir",
  "shomal.ac.ir",
  "sku.ac.ir",
  "skums.ac.ir",
  "sowmesaraiau.ac.ir",
  "spnu.ac.ir",
  "srbiau.ac.ir",
  "srttu.edu",
  "ssu.ac.ir",
  "sttu.ac.ir",
  "sums.ac.ir",
  "sut.ac.ir",
  "sutech.ac.ir",
  "tabriziau.ac.ir",
  "tabrizu.ac.ir",
  "tbzmed.ac.ir",
  "tct.ac.ir",
  "thums.ac.ir",
  "tiau.ac.ir",
  "tmu.ac.ir",
  "tums.ac.ir",
  "uast.ac.ir",
  "ucna.info",
  "ui.ac.ir",
  "uk.ac.ir",
  "uma.ac.ir",
  "um.ac.ir",
  "umsha.ac.ir",
  "umsu.ac.ir",
  "umz.ac.ir",
  "uok.ac.ir",
  "uoz.ac.ir",
  "urmia.ac.ir",
  "usb.ac.ir",
  "usc.ac.ir",
  "ustmb.ac.ir",
  "uswr.ac.ir",
  "ut.ac.ir",
  "uut.ac.ir",
  "vru.ac.ir",
  "yazduni.ac.ir",
  "yu.ac.ir",
  "yums.ac.ir",
  "zdmu.ac.ir",
  "znu.ac.ir",
  "zums.ac.ir",
  "ahlulbaitonline.com",
  "almamonuc.org",
  "alrasheed-uc.com",
  "alsadrain.org",
  "auc-edu.org",
  "auis.edu.iq",
  "baghdadcollege.edu.iq",
  "bpc.edu.iq",
  "broyalu.net",
  "cihanuniversity.edu.iq",
  "coalrafidain.edu.iq",
  "duc.edu.iq",
  "hawlermu.org",
  "iraquniversity.net",
  "iu.edu.iq",
  "komar.edu.iq",
  "koyauniversity.org",
  "madenatalelem.com",
  "muc.edu.iq",
  "nahrainuniv.edu.iq",
  "nawrozuniversity.com",
  "qadissuni.edu.iq",
  "scbaghdad.edu.iq",
  "soran.edu.iq",
  "suh-edu.com",
  "thiqaruni.org",
  "tu.edu.iq",
  "turathun.com",
  "uhd.edu.iq",
  "ukh.ac",
  "univsul.org",
  "uoalmuthana.edu.iq",
  "uoanbar.edu.iq",
  "uobabylon.edu.iq",
  "uobaghdad.edu.iq",
  "uobasrah.edu.iq",
  "uod.ac",
  "uodiyala.edu.iq",
  "uokerbala.edu.iq",
  "uokirkuk.edu.iq",
  "uokufa.edu.iq",
  "uomisan.edu.iq",
  "uomosul.edu.iq",
  "uomustansiriyah.edu.iq",
  "uotechnology.edu.iq",
  "uowasit.edu.iq",
  "ait.ie",
  "cit.ie",
  "dcu.ie",
  "dias.ie",
  "dit.ie",
  "gcd.ie",
  "gmit.ie",
  "iiuedu.ie",
  "itcarlow.ie",
  "ittralee.ie",
  "lsb.ie",
  "may.ie",
  "ncirl.ie",
  "nui.ie",
  "rcpi.ie",
  "rcsi.ie",
  "shannoncollege.com",
  "tcd.ie",
  "ucc.ie",
  "ucd.ie",
  "ucdconnect.ie",
  "nuigalway.ie",
  "ucg.ie",
  "ul.ie",
  "wit.ie",
  "afeka.ac.il",
  "jct.ac.il",
  "ariel.ac.il",
  "ash-college.ac.il",
  "bezalel.ac.il",
  "bgu.ac.il",
  "biu.ac.il",
  "clb.ac.il",
  "colman.ac.il",
  "galilcol.ac.il",
  "haifa.ac.il",
  "huji.ac.il",
  "idc.ac.il",
  "juc.edu",
  "openu.ac.il",
  "rbni.technion.ac.il",
  "sce.ac.il",
  "shenkar.ac.il",
  "tau.ac.il",
  "technion.ac.il",
  "weizmann.ac.il",
  "wgalil.ac.il",
  "yvc.ac.il",
  "angelicum.org",
  "antonianum.ofm.org",
  "casa.unimo.it",
  "ictp.trieste.it",
  "imtlucca.it",
  "iuav.unive.it",
  "iue.it",
  "iulm.it",
  "iuoart.org",
  "iuo.it",
  "iusspavia.it",
  "jhubc.it",
  "johncabot.edu",
  "liuc.it",
  "lumsa.it",
  "naba.it",
  "nyuniversity.net",
  "pio.urbe.it",
  "poliba.it",
  "polimi.it",
  "mail.polimi.it",
  "polito.it",
  "pul.it",
  "pusc.it",
  "sacrocuore.org",
  "sissa.it",
  "sns.it",
  "sssup.it",
  "unian.it",
  "uniba.it",
  "unibas.it",
  "unibg.it",
  "unibocconi.it",
  "unibo.it",
  "unibs.it",
  "unibz.it",
  "unica.it",
  "unical.it",
  "unicam.it",
  "unicampus.it",
  "unicas.it",
  "unicatt.it",
  "unich.it",
  "unict.it",
  "uniecampus.it",
  "uniese.it",
  "unife.it",
  "unifg.it",
  "unifi.it",
  "unige.it",
  "unigre.it",
  "unile.it",
  "unilink.it",
  "unimc.it",
  "unime.it",
  "unimib.it",
  "unimi.it",
  "unimol.it",
  "unina2.it",
  "unina.it",
  "uninav.it",
  "uninsubria.it",
  "unipa.it",
  "unipd.it",
  "unipg.it",
  "unipi.it",
  "unipr.it",
  "unipv.it",
  "unirc.it",
  "uniroma1.it",
  "uniroma2.eu",
  "uniroma3.it",
  "unisa.it",
  "unisal.it",
  "unisannio.it",
  "unisg.it",
  "unisi.it",
  "unisob.na.it",
  "uniss.it",
  "unistrapg.it",
  "unistrasi.it",
  "unite.it",
  "unitn.it",
  "unito.it",
  "unitus.it",
  "uniud.it",
  "uniurb.it",
  "univaq.it",
  "unive.it",
  "univr.it",
  "units.it",
  "aur.edu",
  "ihteamlingue.it",
  "ncu.edu.jm",
  "utech.edu.jm",
  "uwimona.edu.jm",
  "aasa.ac.jp",
  "abu.ac.jp",
  "aichi-edu.ac.jp",
  "aichi-fam-u.ac.jp",
  "aichi-gakuin.ac.jp",
  "aichi-med-u.ac.jp",
  "aichi-pu.ac.jp",
  "aichi-u.ac.jp",
  "aikoku-u.ac.jp",
  "aitech.ac.jp",
  "akeihou-u.ac.jp",
  "akita-u.ac.jp",
  "andrew.ac.jp",
  "aomoricgu.ac.jp",
  "aomori-u.ac.jp",
  "aoyama.ac.jp",
  "apu.ac.jp",
  "asahikawa-med.ac.jp",
  "asahikawa-u.ac.jp",
  "asahi-u.ac.jp",
  "ashitech.ac.jp",
  "ashiya-u.ac.jp",
  "asia-u.ac.jp",
  "asu.ac.jp",
  "atomi.ac.jp",
  "aue-okinawa.ac.jp",
  "auhw.ac.jp",
  "azabu-u.ac.jp",
  "baika.ac.jp",
  "baiko.ac.jp",
  "beppu-u.ac.jp",
  "budo-u.ac.jp",
  "bukkyo-u.ac.jp",
  "bunka-wu.ac.jp",
  "bunkyo.ac.jp",
  "bunri-u.ac.jp",
  "cgu.ac.jp",
  "chiba-u.ac.jp",
  "chikushi.ac.jp",
  "chubu.ac.jp",
  "chubu-gu.ac.jp",
  "chujo-u.ac.jp",
  "chukyogakuin-u.ac.jp",
  "chukyo-u.ac.jp",
  "chuo-u.ac.jp",
  "cku.ac.jp",
  "cuc.ac.jp",
  "daido-it.ac.jp",
  "daiichi-cps.ac.jp",
  "daiichi-koudai.ac.jp",
  "daiichi-ue.ac.jp",
  "daion.ac.jp",
  "daishodai.ac.jp",
  "daito.ac.jp",
  "dendai.ac.jp",
  "doho.ac.jp",
  "dohto.ac.jp",
  "do-johodai.ac.jp",
  "dokkyo.ac.jp",
  "dokkyomed.ac.jp",
  "doshisha.ac.jp",
  "dwc.doshisha.ac.jp",
  "edogawa-u.ac.jp",
  "ehime-u.ac.jp",
  "eichi-u.ac.jp",
  "eiyo.ac.jp",
  "eum.ac.jp",
  "fdcnet.ac.jp",
  "ferris.ac.jp",
  "fish-u.ac.jp",
  "fit.ac.jp",
  "fmu.ac.jp",
  "fpu.ac.jp",
  "fujijoshi.ac.jp",
  "fujita-hu.ac.jp",
  "fukui-med.ac.jp",
  "fukui-u.ac.jp",
  "fukui-ut.ac.jp",
  "fukuoka-edu.ac.jp",
  "fukuoka-int-u.ac.jp",
  "fukuoka-pu.ac.jp",
  "fukuoka-u.ac.jp",
  "fukushima-u.ac.jp",
  "fukuyama-u.ac.jp",
  "fwu.ac.jp",
  "gakusen.ac.jp",
  "gakushuin.ac.jp",
  "geidai.ac.jp",
  "gifu-keizai.ac.jp",
  "gifu-kyoiku.ac.jp",
  "gifu-pu.ac.jp",
  "gifu-u.ac.jp",
  "gijodai.ac.jp",
  "gpwu.ac.jp",
  "gunma-u.ac.jp",
  "hachinohe-u.ac.jp",
  "hakodate-u.ac.jp",
  "hakuoh.ac.jp",
  "hamamatsu-u.ac.jp",
  "hama-med.ac.jp",
  "hanazono.ac.jp",
  "hannan-u.ac.jp",
  "h-bunkyo.ac.jp",
  "heisei-u.ac.jp",
  "hijiyama-u.ac.jp",
  "himeji-du.ac.jp",
  "himeji-tech.ac.jp",
  "hirogaku-u.ac.jp",
  "hirojo-u.ac.jp",
  "hirokoku-u.ac.jp",
  "hirosaki-u.ac.jp",
  "hiroshima-cu.ac.jp",
  "hiroshima-pu.ac.jp",
  "hiroshima-u.ac.jp",
  "hit.ac.jp",
  "hi-tech.ac.jp",
  "hit-u.ac.jp",
  "hiu.ac.jp",
  "hju.ac.jp",
  "hkg.ac.jp",
  "hokkai-k-u.ac.jp",
  "hokkai-s-u.ac.jp",
  "hokkyodai.ac.jp",
  "hokudai.ac.jp",
  "hoku-iryo-u.ac.jp",
  "hokuriku-u.ac.jp",
  "hokusei.ac.jp",
  "hokuyakudai.ac.jp",
  "hosei.ac.jp",
  "hoshi.ac.jp",
  "hue.ac.jp",
  "hyogo-dai.ac.jp",
  "hyogo-u.ac.jp",
  "hyo-med.ac.jp",
  "ibaraki.ac.jp",
  "icc.ac.jp",
  "icu.ac.jp",
  "ip.kyusan-u.ac.jp",
  "ipu.ac.jp",
  "isenshu-u.ac.jp",
  "it-chiba.ac.jp",
  "it-hiroshima.ac.jp",
  "iuhw.ac.jp",
  "iuj.ac.jp",
  "iuk.ac.jp",
  "iwakimu.ac.jp",
  "iwate-med.ac.jp",
  "iwate-pu.ac.jp",
  "iwate-u.ac.jp",
  "jaist.ac.jp",
  "jichi.ac.jp",
  "jikei.ac.jp",
  "jissen.ac.jp",
  "jiu.ac.jp",
  "jobu.ac.jp",
  "josai.ac.jp",
  "joshibi.ac.jp",
  "juen.ac.jp",
  "juntan.k-junshin.ac.jp",
  "juntendo.ac.jp",
  "jwu.ac.jp",
  "kagawa-u.ac.jp",
  "kagoshima-u.ac.jp",
  "kamakura-u.ac.jp",
  "kanagawa-it.ac.jp",
  "kanagawa-u.ac.jp",
  "kanazawa-bidai.ac.jp",
  "kanazawa-eco.ac.jp",
  "kanazawa-gu.ac.jp",
  "kanazawa-it.ac.jp",
  "kanazawa-med.ac.jp",
  "kanazawa-u.ac.jp",
  "kankyo-u.ac.jp",
  "kansai-gaidai-u.ac.jp",
  "kansai-u.ac.jp",
  "kanto-gakuen.ac.jp",
  "kanto-gakuin.ac.jp",
  "kawasaki-m.ac.jp",
  "kbu.ac.jp",
  "kcua.ac.jp",
  "kdcnet.ac.jp",
  "keiho-u.ac.jp",
  "keio.ac.jp",
  "keisen.ac.jp",
  "keiwa-c.ac.jp",
  "kgwu.ac.jp",
  "kindai.ac.jp",
  "kinjo-u.ac.jp",
  "kit.ac.jp",
  "kitakyu-u.ac.jp",
  "kitami-it.ac.jp",
  "kitasato-u.ac.jp",
  "kiu.ac.jp",
  "kiui.ac.jp",
  "kms.ac.jp",
  "kmu.ac.jp",
  "knct.ac.jp",
  "kobe-c.ac.jp",
  "kobe-cufs.ac.jp",
  "kobe-du.ac.jp",
  "kobegakuin.ac.jp",
  "kobe-kiu.ac.jp",
  "kobepharma-u.ac.jp",
  "kobe-shinwa.ac.jp",
  "kobe-u.ac.jp",
  "kochi-ms.ac.jp",
  "kochi-tech.ac.jp",
  "kochi-u.ac.jp",
  "kochi-wu.ac.jp",
  "kogakkan-u.ac.jp",
  "kogakuin.ac.jp",
  "koka.ac.jp",
  "kokugakuin.ac.jp",
  "kokushikan.ac.jp",
  "komazawa-u.ac.jp",
  "konan-u.ac.jp",
  "konan-wu.ac.jp",
  "korea-u.ac.jp",
  "koriyama-kgc.ac.jp",
  "koshien.ac.jp",
  "koyasan-u.ac.jp",
  "kpu.ac.jp",
  "kpu-m.ac.jp",
  "kshosen.ac.jp",
  "ksu.ac.jp",
  "ktokai-u.ac.jp",
  "kufs.ac.jp",
  "kuins.ac.jp",
  "kuis.ac.jp",
  "kumagaku.ac.jp",
  "kumamoto-u.ac.jp",
  "kunitachi.ac.jp",
  "kure-u.ac.jp",
  "kurume-it.ac.jp",
  "kurume-u.ac.jp",
  "kusa.ac.jp",
  "kushiro-pu.ac.jp",
  "kusw.ac.jp",
  "kwansei.ac.jp",
  "kwassui.ac.jp",
  "kwc-u.ac.jp",
  "kwuc.ac.jp",
  "kyokyo-u.ac.jp",
  "kyorin-u.ac.jp",
  "kyoritsu-ph.ac.jp",
  "kyoritsu-wu.ac.jp",
  "kyoto-art.ac.jp",
  "kyotogakuen-u.ac.jp",
  "kyoto-phu.ac.jp",
  "kyoto-seika.ac.jp",
  "kyoto-su.ac.jp",
  "kyoto-u.ac.jp",
  "kyoto-wu.ac.jp",
  "kyu-dent.ac.jp",
  "kyukyo-u.ac.jp",
  "kyushu-id.ac.jp",
  "kyushu-ns.ac.jp",
  "kyushu-u.ac.jp",
  "kyutech.ac.jp",
  "marianna-u.ac.jp",
  "matsuyama-u.ac.jp",
  "mdu.ac.jp",
  "meiji.ac.jp",
  "meijigakuin.ac.jp",
  "meiji-u.ac.jp",
  "meijo-u.ac.jp",
  "meikai.ac.jp",
  "meio-u.ac.jp",
  "meisei-u.ac.jp",
  "mejiro.ac.jp",
  "metro-hs.ac.jp",
  "metro-u.ac.jp",
  "mgu.ac.jp",
  "mie-chukyo-u.ac.jp",
  "mie-u.ac.jp",
  "mimasaka.ac.jp",
  "miyakyo-u.ac.jp",
  "miyasankei-u.ac.jp",
  "miyazaki-med.ac.jp",
  "miyazaki-mu.ac.jp",
  "miyazaki-u.ac.jp",
  "morioka-u.ac.jp",
  "mpu.ac.jp",
  "mukogawa-u.ac.jp",
  "muroran-it.ac.jp",
  "musabi.ac.jp",
  "musashi.ac.jp",
  "musashino-music.ac.jp",
  "musashino-wu.ac.jp",
  "musashi-tech.ac.jp",
  "my-pharm.ac.jp",
  "myu.ac.jp",
  "nagano.ac.jp",
  "nagaokaut.ac.jp",
  "nagasakipu.ac.jp",
  "nagasaki-u.ac.jp",
  "nagoya-cu.ac.jp",
  "nagoya-ku.ac.jp",
  "nagoya-u.ac.jp",
  "nagoya-wu.ac.jp",
  "nakamura-u.ac.jp",
  "nankyudai.ac.jp",
  "nanzan-u.ac.jp",
  "nara-edu.ac.jp",
  "naramed-u.ac.jp",
  "nara-su.ac.jp",
  "nara-u.ac.jp",
  "narauc.ac.jp",
  "nara-wu.ac.jp",
  "naruto-u.ac.jp",
  "nbu.ac.jp",
  "ndmc.ac.jp",
  "ndsu.ac.jp",
  "ndu.ac.jp",
  "nebuta.ac.jp",
  "n-fukushi.ac.jp",
  "ngu.ac.jp",
  "nias.ac.jp",
  "nifs-k.ac.jp",
  "nihon-u.ac.jp",
  "niigata-pharm.ac.jp",
  "niigata-u.ac.jp",
  "niigataum.ac.jp",
  "nishitech.ac.jp",
  "nishogakusha-u.ac.jp",
  "nisikyu-u.ac.jp",
  "nit.ac.jp",
  "nitech.ac.jp",
  "nittai.ac.jp",
  "nms.ac.jp",
  "nodai.ac.jp",
  "notredame.ac.jp",
  "nsu.ac.jp",
  "ntu.ac.jp",
  "nua.ac.jp",
  "nucba.ac.jp",
  "nufs.nakanishi.ac.jp",
  "nuis.ac.jp",
  "nvau.ac.jp",
  "obihiro.ac.jp",
  "obirin.ac.jp",
  "ocha.ac.jp",
  "ohkagakuen-u.ac.jp",
  "ohtani-w.ac.jp",
  "ohu-u.ac.jp",
  "oit.ac.jp",
  "oita-med.ac.jp",
  "oita-nhs.ac.jp",
  "oita-u.ac.jp",
  "oiu.oiu.ac.jp",
  "oiuw.oiu.ac.jp",
  "oka-pu.ac.jp",
  "okayama-u.ac.jp",
  "okigei.ac.jp",
  "okinawa-u.ac.jp",
  "okiu.ac.jp",
  "osakac.ac.jp",
  "oecu.jp",
  "osaka-cu.ac.jp",
  "osaka-dent.ac.jp",
  "osakafu-u.ac.jp",
  "osaka-gaidai.ac.jp",
  "osaka-geidai.ac.jp",
  "osaka-gu.ac.jp",
  "osaka-kyoiku.ac.jp",
  "osaka-med.ac.jp",
  "osaka-sandai.ac.jp",
  "osaka-shoin.ac.jp",
  "osaka-u.ac.jp",
  "osaka-ue.ac.jp",
  "osaka-wu.ac.jp",
  "osu.ac.jp",
  "otani.ac.jp",
  "otaru-uc.ac.jp",
  "otemae.ac.jp",
  "otemon.ac.jp",
  "otsuma.ac.jp",
  "ouhs.ac.jp",
  "oups.ac.jp",
  "ous.ac.jp",
  "poole.ac.jp",
  "pu-kumamoto.ac.jp",
  "pu-toyama.ac.jp",
  "rakuno.ac.jp",
  "redcross.ac.jp",
  "reitaku-u.ac.jp",
  "rikkyo.ac.jp",
  "ris.ac.jp",
  "ritsumei.ac.jp",
  "rku.ac.jp",
  "ryukoku.ac.jp",
  "saga-med.ac.jp",
  "sagami-wu.ac.jp",
  "saga-u.ac.jp",
  "saitama-med.ac.jp",
  "saitama-u.ac.jp",
  "sakushin-u.ac.jp",
  "sanno.ac.jp",
  "sapmed.ac.jp",
  "sapporo-u.ac.jp",
  "seian.ac.jp",
  "seigakuin-univ.ac.jp",
  "seijo.ac.jp",
  "seikei.ac.jp",
  "seinan-gu.ac.jp",
  "seisen-u.ac.jp",
  "seiwa-u.ac.jp",
  "sendaidaigaku.jp",
  "senshu-u.ac.jp",
  "senzoku.ac.jp",
  "setsunan.ac.jp",
  "sgu.ac.jp",
  "sg-u.ac.jp",
  "sguc.ac.jp",
  "shakaijigyo.ac.jp",
  "shibaura-it.ac.jp",
  "shiga-med.ac.jp",
  "shiga-u.ac.jp",
  "shikoku-u.ac.jp",
  "shimane-med.ac.jp",
  "shimane-u.ac.jp",
  "shimonoseki-cu.ac.jp",
  "shinshu-u.ac.jp",
  "shirayuri.ac.jp",
  "shitennoji.ac.jp",
  "shizuoka.ac.jp",
  "shodai.ac.jp",
  "shoin.ac.jp",
  "shokei-gakuen.ac.jp",
  "shonan-it.ac.jp",
  "shotoku.ac.jp",
  "showa-u.ac.jp",
  "shoyaku.ac.jp",
  "shuchiin.ac.jp",
  "shudo-u.ac.jp",
  "shujitsu.ac.jp",
  "shukutoku.ac.jp",
  "shumei-u.ac.jp",
  "sit.ac.jp",
  "siu.ac.jp",
  "slcn.ac.jp",
  "soai.ac.jp",
  "soka.ac.jp",
  "soken.ac.jp",
  "sonoda-u.ac.jp",
  "sophia.ac.jp",
  "spu.ac.jp",
  "ssu.ac.jp",
  "sugino.ac.jp",
  "sugiyama-u.ac.jp",
  "suma.kobe-wu.ac.jp",
  "sun.ac.jp",
  "surugadai.ac.jp",
  "sut.ac.jp",
  "suzuka-iu.ac.jp",
  "suzuka-u.ac.jp",
  "swu.ac.jp",
  "tachibana-u.ac.jp",
  "tais.ac.jp",
  "takachiho.ac.jp",
  "takamatsu-u.ac.jp",
  "takara-univ.ac.jp",
  "takushoku-u.ac.jp",
  "tamabi.ac.jp",
  "tamagawa.ac.jp",
  "tbgu.ac.jp",
  "tcue.ac.jp",
  "tdc.ac.jp",
  "teikyo-u.ac.jp",
  "tenri-u.ac.jp",
  "termnet.co.jp",
  "teu.ac.jp",
  "tezuka-gu.ac.jp",
  "tezukayama-u.ac.jp",
  "tfu.ac.jp",
  "timis.ac.jp",
  "titech.ac.jp",
  "tiu.ac.jp",
  "t-komazawa.ac.jp",
  "t-kougei.ac.jp",
  "tku.ac.jp",
  "tmd.ac.jp",
  "tmit.ac.jp",
  "tohoku.ac.jp",
  "tohoku-gakuin.ac.jp",
  "tohoku-pharm.ac.jp",
  "toho-music.ac.jp",
  "tohomusic.ac.jp",
  "toho-u.ac.jp",
  "tohtech.ac.jp",
  "tohwa-u.ac.jp",
  "toin.ac.jp",
  "tojo.ac.jp",
  "tokai.ac.jp",
  "tokaigakuen-u.ac.jp",
  "tokaijoshi-u.ac.jp",
  "tokiwa.ac.jp",
  "tokoha-u.ac.jp",
  "tokushima-u.ac.jp",
  "tokuyama-u.ac.jp",
  "tokyo-kasei.ac.jp",
  "tokyo-med.ac.jp",
  "tokyo-ondai.ac.jp",
  "tokyo-u-fish.ac.jp",
  "tonichi-kokusai-u.ac.jp",
  "tosho-u.ac.jp",
  "tottori-u.ac.jp",
  "toua-u.ac.jp",
  "toyaku.ac.jp",
  "toyama-mpu.ac.jp",
  "toyama-u.ac.jp",
  "toyo.ac.jp",
  "toyoeiwa.ac.jp",
  "toyogakuen-u.ac.jp",
  "toyota-ti.ac.jp",
  "tsuda.ac.jp",
  "tsukuba.ac.jp",
  "tsuru.ac.jp",
  "tsurumi-u.ac.jp",
  "tuad.ac.jp",
  "tuat.ac.jp",
  "tufs.ac.jp",
  "tuins.ac.jp",
  "tuis.ac.jp",
  "tuj.ac.jp",
  "tut.ac.jp",
  "twcpe.ac.jp",
  "twcu.ac.jp",
  "twmu.ac.jp",
  "u-air.ac.jp",
  "u-aizu.ac.jp",
  "u-bunkyo.ac.jp",
  "uec.ac.jp",
  "uenogakuen.ac.jp",
  "u-gakugei.ac.jp",
  "uitec.ac.jp",
  "u-keiai.ac.jp",
  "umds.ac.jp",
  "unu.edu",
  "uoeh-u.ac.jp",
  "u-ryukyu.ac.jp",
  "u-sacred-heart.ac.jp",
  "u-shizuoka-ken.ac.jp",
  "usp.ac.jp",
  "pref.shiga.lg.jp",
  "u-tokai.ac.jp",
  "u-tokyo.ac.jp",
  "utsunomiya-u.ac.jp",
  "wakayama-med.ac.jp",
  "wakayama-u.ac.jp",
  "wakhok.ac.jp",
  "wako.ac.jp",
  "waseda.ac.jp",
  "wayo.ac.jp",
  "yamagata-u.ac.jp",
  "yamaguchi-pu.ac.jp",
  "yamaguchi-u.ac.jp",
  "yamanashi.ac.jp",
  "yamanashi-med.ac.jp",
  "yama.sut.ac.jp",
  "yasuda-u.ac.jp",
  "ygu.ac.jp",
  "ynu.ac.jp",
  "yokkaichi-u.ac.jp",
  "yokohama-cu.ac.jp",
  "zokei.ac.jp",
  "aambfs.edu.eg",
  "aabu.edu.jo",
  "aau.edu.jo",
  "ahu.edu.jo",
  "alzaytoonah.edu.jo",
  "amman.edu",
  "ammanu.edu.jo",
  "aou.edu.jo",
  "asu.edu.jo",
  "bau.edu.jo",
  "gju.edu.jo",
  "hu.edu.jo",
  "inu.edu.jo",
  "isra.edu.jo",
  "jadara.edu.jo",
  "jam.edu.jo",
  "jerashun.edu.jo",
  "ju.edu.jo",
  "just.edu.jo",
  "meu.edu.jo",
  "mutah.edu.jo",
  "nyit.edu.jo",
  "philadelphia.edu.jo",
  "psut.edu.jo",
  "ttu.edu.jo",
  "uop.edu.jo",
  "wise.edu.jo",
  "yu.edu.jo",
  "zpu.edu.jo",
  "ablaikhan.kz",
  "agriun.almaty.kz",
  "aktsu.kz",
  "atgu.kz",
  "ektu.kz",
  "enu.kz",
  "eurazir.kz",
  "kau.kz",
  "kazeu.kz",
  "kaznpu.kz",
  "kazntu.kz",
  "kaznu.kz",
  "kbtu.kz",
  "kimep.kz",
  "kstu.kz",
  "ksu.kst.kz",
  "ksu.kz",
  "nkzu.kz",
  "psu.kz",
  "sdu.edu.kz",
  "semgu.kz",
  "sgma.kz",
  "turkistan.kz",
  "uib.kz",
  "vkgu.kz",
  "wkau.kz",
  "zhezu.kz",
  "zhgu.kz",
  "aiu.ac.ke",
  "anu.ac.ke",
  "aua.ac.ke",
  "chuka.ac.ke",
  "cuea.edu",
  "daystar.ac.ke",
  "dkut.ac.ke",
  "egerton.ac.ke",
  "gluk.ac.ke",
  "gretsauniversity.ac.ke",
  "iu.ac.ke",
  "jkuat.ac.ke",
  "jooust.ac.ke",
  "kabarak.ac.ke",
  "kabianga.ac.ke",
  "karatinauniversity.ac.ke",
  "kca.ac.ke",
  "kemu.ac.ke",
  "kisiiuniversity.ac.ke",
  "kmtc.ac.ke",
  "ku.ac.ke",
  "kwust.ac.ke",
  "laikipia.ac.ke",
  "maseno.ac.ke",
  "mku.ac.ke",
  "mmarau.ac.ke",
  "mmu.ac.ke",
  "mmust.ac.ke",
  "mu.ac.ke",
  "must.ac.ke",
  "nibs.ac.ke",
  "ntti.ac.ke",
  "pacuniversity.ac.ke",
  "piu.ac.ke",
  "pu.ac.ke",
  "puea.ac.ke",
  "riarauniversity.ac.ke",
  "scott.ac.ke",
  "seku.ac.ke",
  "spu.ac.ke",
  "strathmore.edu",
  "tukenya.ac.ke",
  "tum.ac.ke",
  "ueab.ac.ke",
  "uoeld.ac.ke",
  "uonbi.ac.ke",
  "usiu.ac.ke",
  "uzimauniversity.ac.ke",
  "zetechcollege.com",
  "pust.net",
  "ajou.ac.kr",
  "andong.ac.kr",
  "acts.ac.kr",
  "bu.ac.kr",
  "bnue.ac.kr",
  "bpu.ac.kr",
  "bufs.ac.kr",
  "cataegu.ac.kr",
  "cau.ac.kr",
  "changwon.ac.kr",
  "charusat.ac.in",
  "cheju.ac.kr",
  "cheju-e.ac.kr",
  "chinju.ac.kr",
  "chinju-e.ac.kr",
  "cue.ac.kr",
  "chodang.ac.kr",
  "cdu.ac.kr",
  "chonbuk.ac.kr",
  "chongju.ac.kr",
  "chongju-e.ac.kr",
  "chonju-e.ac.kr",
  "chonnam.ac.kr",
  "jnu.ac.kr",
  "chosun.ac.kr",
  "chungbuk.ac.kr",
  "cbnu.ac.kr",
  "chungju.ac.kr",
  "chungnam.ac.kr",
  "cnu.ac.kr",
  "cnue-e.ac.kr",
  "cuk.ac.kr",
  "cup.ac.kr",
  "cha.ac.kr",
  "cju.ac.kr",
  "cku.ac.kr",
  "cs.ac.kr",
  "cje.ac.kr",
  "chongshin.ac.kr",
  "chugye.ac.kr",
  "cnue.ac.kr",
  "chungwoon.ac.kr",
  "daebul.ac.kr",
  "dankook.ac.kr",
  "donga.ac.kr",
  "dongduk.ac.kr",
  "dongeui.ac.kr",
  "dongguk.ac.kr",
  "dongguk.edu",
  "dongseo.ac.kr",
  "dongshinu.ac.kr",
  "dsu.ac.kr",
  "duksung.ac.kr",
  "dytc.ac.kr",
  "tau.ac.kr",
  "dgist.ac.kr",
  "dhu.ac.kr",
  "dnue.ac.kr",
  "daegu.ac.kr",
  "dufs.ac.kr",
  "dcatholic.ac.kr",
  "daejeon.ac.kr",
  "dju.ac.kr",
  "daejin.ac.kr",
  "daeshin.ac.kr",
  "deu.ac.kr",
  "dyu.ac.kr",
  "ewha.ac.kr",
  "eulji.ac.kr",
  "kdu.ac.kr",
  "gachon.ac.kr",
  "gwnu.ac.kr",
  "ggu.ac.kr",
  "gimcheon.ac.kr",
  "gjue.ac.kr",
  "gjcatholic.ac.kr",
  "gist.ac.kr",
  "gnue.ac.kr",
  "ginue.ac.kr",
  "gntech.ac.kr",
  "gsnu.ac.kr",
  "gnu.ac.kr",
  "hallym.ac.kr",
  "hangkong.ac.kr",
  "hannam.ac.kr",
  "hansei.ac.kr",
  "hanseo.ac.kr",
  "hanshin.ac.kr",
  "hs.ac.kr",
  "hansung.ac.kr",
  "hanyang.ac.kr",
  "hknu.ac.kr",
  "honam.ac.kr",
  "hongik.ac.kr",
  "hoseo.ac.kr",
  "howon.ac.kr",
  "hufs.ac.kr",
  "hyupsung.ac.kr",
  "uhs.ac.kr",
  "halla.ac.kr",
  "hanbat.ac.kr",
  "handong.edu",
  "hanil.ac.kr",
  "hanlyo.ac.kr",
  "hanzhong.ac.kr",
  "htus.ac.kr",
  "iccu.ac.kr",
  "iuk.ac.kr",
  "icu.ac.kr",
  "inchon.ac.kr",
  "inchon-e.ac.kr",
  "inha.ac.kr",
  "inje.ac.kr",
  "jeonju.ac.kr",
  "jj.ac.kr",
  "jeju.ac.kr",
  "jejunu.ac.kr",
  "jnue.ac.kr",
  "jesus.ac.kr",
  "sangha.ac.kr",
  "joongbu.ac.kr",
  "jwu.ac.kr",
  "kaist.ac.kr",
  "kaist.edu",
  "kangnam.ac.kr",
  "kangnung.ac.kr",
  "kangwon.ac.kr",
  "kaya.ac.kr",
  "kdischool.ac.kr",
  "keimyung.ac.kr",
  "kmu.ac.kr",
  "kmaritime.ac.kr",
  "knou.ac.kr",
  "knua.ac.kr",
  "karts.ac.kr",
  "knue.ac.kr",
  "knupe.ac.kr",
  "kongju-e.ac.kr",
  "konkuk.ac.kr",
  "konyang.ac.kr",
  "kookmin.ac.kr",
  "korea.ac.kr",
  "kosin.ac.kr",
  "kumoh.ac.kr",
  "kunsan.ac.kr",
  "kwandong.ac.kr",
  "kwangju.ac.kr",
  "gwangju.ac.kr",
  "kwangju-e.ac.kr",
  "kwangwoon.ac.kr",
  "kw.ac.kr",
  "kyonggi.ac.kr",
  "kyongju.ac.kr",
  "kongju.ac.kr",
  "kyunghee.ac.kr",
  "kyungil.ac.kr",
  "kiu.ac.kr",
  "kyungnam.ac.kr",
  "kyungpook.ac.kr",
  "kyungsung.ac.kr",
  "ks.ac.kr",
  "kyungwon.ac.kr",
  "kcu.ac.kr",
  "kkot.ac.kr",
  "kau.ac.kr",
  "kafna.ac.kr",
  "kbtus.ac.kr",
  "kmou.ac.kr",
  "kma.ac.kr",
  "knsu.ac.kr",
  "nuch.ac.kr",
  "kornu.ac.kr",
  "kpu.ac.kr",
  "koreatech.ac.kr",
  "bible.ac.kr",
  "police.ac.kr",
  "kwu.ac.kr",
  "kwangshin.ac.kr",
  "khu.ac.kr",
  "kduniv.ac.kr",
  "ikw.ac.kr",
  "miryang.ac.kr",
  "mmu.ac.kr",
  "mokpo.ac.kr",
  "mokwon.ac.kr",
  "myongji.ac.kr",
  "mju.ac.kr",
  "mtu.ac.kr",
  "mcu.ac.kr",
  "nambu.ac.kr",
  "nsu.ac.kr",
  "pcu.ac.kr",
  "puts.ac.kr",
  "ptu.ac.kr",
  "paichai.ac.kr",
  "pknu.ac.kr",
  "postech.ac.kr",
  "postech.edu",
  "ptuniv.ac.kr",
  "pufs.ac.kr",
  "pusan.ac.kr",
  "pusan-e.ac.kr",
  "pwc.ac.kr",
  "afa.ac.kr",
  "navy.ac.kr",
  "samchok.ac.kr",
  "sangji.ac.kr",
  "sangmyung.ac.kr",
  "sch.ac.kr",
  "sejong.ac.kr",
  "semyung.ac.kr",
  "seonam.ac.kr",
  "seoul-e.ac.kr",
  "snue.ac.kr",
  "seowon.ac.kr",
  "silla.ac.kr",
  "skku.ac.kr",
  "skuniv.ac.kr",
  "seokyeong.ac.kr",
  "snu.ac.kr",
  "snut.ac.kr",
  "sogang.ac.kr",
  "sookmyung.ac.kr",
  "soongsil.ac.kr",
  "ssu.ac.kr",
  "stu.ac.kr",
  "sunchon.ac.kr",
  "scnu.ac.kr",
  "sungkyul.ac.kr",
  "sungshin.ac.kr",
  "sunmoon.ac.kr",
  "suwon.ac.kr",
  "suwoncatholic.ac.kr",
  "swu.ac.kr",
  "syu.ac.kr",
  "smu.ac.kr",
  "sehan.ac.kr",
  "scu.ac.kr",
  "hytu.ac.kr",
  "sjs.ac.kr",
  "seoultech.ac.kr",
  "sgu.ac.kr",
  "shinhan.ac.kr",
  "songwon.ac.kr",
  "skhu.ac.kr",
  "skku.edu",
  "taegu.ac.kr",
  "taegu-e.ac.kr",
  "taejon.ac.kr",
  "taeshin.ac.kr",
  "tit.ac.kr",
  "tnut.ac.kr",
  "ulsan.ac.kr",
  "uos.ac.kr",
  "ust.ac.kr",
  "u1.ac.kr",
  "uu.ac.kr",
  "unist.ac.kr",
  "wsu.ac.kr",
  "wonkwang.ac.kr",
  "wku.ac.kr",
  "woosuk.ac.kr",
  "yeungnam.ac.kr",
  "yonsei.ac.kr",
  "yosu.ac.kr",
  "www.yewon.ac.kr",
  "www.yongin.ac.kr",
  "www.ysu.ac.kr",
  "www.youngsan.ac.kr",
  "aukonline.org",
  "uiliria.org",
  "uni-pr.edu",
  "uni-prizren.com",
  "universum-ks.org",
  "ackonline.com",
  "aou.edu.kw",
  "auk.edu.kw",
  "aum.edu.kw",
  "gust.edu.kw",
  "kilaw.edu.kw",
  "kmbs.edu.kw",
  "kuniv.edu.kw",
  "asmi.edu.kg",
  "auca.kg",
  "bhu.kg",
  "iaau.edu.kg",
  "iuk.kg",
  "jasu.edu.kg",
  "knau.kg",
  "krsu.edu.kg",
  "kstu.kg",
  "ksucta.kg",
  "manas.kg",
  "oshmed.com",
  "university.kg",
  "nuol.edu.la",
  "simt.edu.la",
  "aml.lv",
  "ba.lv",
  "bsa.edu.lv",
  "cs.lpu.lv",
  "dau.lv",
  "lama.lv",
  "lka.edu.lv",
  "llu.lv",
  "lbtu.lv",
  "lma.lv",
  "lmuza.lv",
  "lspa.lanet.lv",
  "lu.lv",
  "mil.lv",
  "polak.edu.lv",
  "psy.lv",
  "rau.lv",
  "rpiva.lv",
  "rtu.lv",
  "ru.lv",
  "sseriga.edu.lv",
  "tsi.lv",
  "va.lv",
  "arabou-lb.edu.lb",
  "aub.edu.lb",
  "aul.edu.lb",
  "aust.edu.lb",
  "aut.edu",
  "balamand.edu.lb",
  "bau.edu.lb",
  "cut.edu.lb",
  "gu.edu.lb",
  "haigazian.edu.lb",
  "hariricanadian.edu.lb",
  "hu.edu.lb",
  "islahonline.org",
  "jinan.edu.lb",
  "lau.edu.lb",
  "liu.edu.lb",
  "matnu.edu.lb",
  "mut.edu.lb",
  "ndu.edu.lb",
  "theonest.edu.lb",
  "ul.edu.lb",
  "usek.edu.lb",
  "usj.edu.lb",
  "wakf.org",
  "nul.ls",
  "universityliberia.org",
  "asmarya.edu.ly",
  "elmergib.edu.ly",
  "jgu.edu.ly",
  "libopenuniv-edu.org",
  "limu.edu.ly",
  "omu.edu.ly",
  "sebhau.edu.ly",
  "su.edu.ly",
  "uob.edu.ly",
  "uot.edu.ly",
  "zu.edu.ly",
  "iap.li",
  "uni.li",
  "info.kma.lt",
  "ism.lt",
  "ktu.lt",
  "ku.lt",
  "lcc.lt",
  "lka.lt",
  "lkka.lt",
  "lmta.lt",
  "lva.lt",
  "lzua.lt",
  "mch.mii.lt",
  "mruni.eu",
  "su.lt",
  "vdu.lt",
  "vgtu.lt",
  "vpu.lt",
  "vu.lt",
  "iuil.lu",
  "uni.lu",
  "ipm.edu.mo",
  "um.edu.mo",
  "usj.edu.mo",
  "fon.edu.mk",
  "seeu.edu.mk",
  "manu.edu.mk",
  "ugd.edu.mk",
  "uist.edu.mk",
  "ukim.edu.mk",
  "ukim.mk",
  "finki.ukim.mk",
  "students.finki.ukim.mk",
  "uklo.edu.mk",
  "unite.edu.mk",
  "univ-antananarivo.mg",
  "univ-antsiranana.mg",
  "univ-fianar.mg",
  "univ-mahajanga.mg",
  "univ-toamasina.mg",
  "univ-toliara.mg",
  "cunima.net",
  "mzuni.ac.mw",
  "ulivingstonia.com",
  "unima.ac.mw",
  "mubas.ac.mw",
  "must.ac.mw",
  "luanar.ac.mw",
  "unima.mw",
  "acms.edu.my",
  "aeu.edu.my",
  "aimst.edu.my",
  "aiu.edu.my",
  "apiit.edu.my",
  "atc2u.com",
  "bac.edu.my",
  "binary.edu.my",
  "bmi.edu.my",
  "city.edu.my",
  "curtin.edu.my",
  "cybermed.edu.my",
  "ftmsglobal.com",
  "help.edu.my",
  "hikmah.edu.my",
  "i4p.edu.my",
  "iact.edu.my",
  "iic.edu.my",
  "iiu.edu.my",
  "imu.edu.my",
  "instedt.edu.my",
  "ipb.edu.my",
  "ipbl.edu.my",
  "ipbmm.edu.my",
  "ipda.edu.my",
  "ipgkkb.edu.my",
  "ipgkrajang.edu.my",
  "ipgkti.edu.my",
  "ipgmkdri.edu.my",
  "ipgmksm.edu.my",
  "ipgmtawau.edu.my",
  "ipgperlis.edu.my",
  "ipik.edu.my",
  "ipip.edu.my",
  "ipislam.edu.my",
  "ipkent.edu.my",
  "ipks.edu.my",
  "ippm.edu.my",
  "iprm.edu.my",
  "ipsah.edu.my",
  "ipsmiri.edu.my",
  "iptaa.edu.my",
  "iptb.edu.my",
  "ipteknik.edu.my",
  "iptho.edu.my",
  "iptips.edu.my",
  "iucn.edu.my",
  "iuctt.edu.my",
  "kbu.edu.my",
  "kdu.edu.my",
  "kias.edu.my",
  "kidu-darululum.blogspot.com",
  "kiperak.edu.my",
  "kipsas.edu.my",
  "kist.edu.my",
  "kitab.edu.my",
  "kliuc.edu.my",
  "klmu.edu.my",
  "kolejshahputra.edu.my",
  "kolejypc.edu.my",
  "kptm.edu.my",
  "ktd.edu.my",
  "kudqi.net.my",
  "kuim.edu.my",
  "kuin.edu.my",
  "kuis.edu.my",
  "limkokwing.edu.my",
  "linton.edu.my",
  "mahsa.edu.my",
  "mediu.edu.my",
  "miit.unikl.edu.my",
  "mmu.edu.my",
  "monash.edu.my",
  "msu.edu.my",
  "murni.edu.my",
  "must.edu.my",
  "nilai.edu.my",
  "nottingham.edu.my",
  "numed.ncl.ac.uk",
  "olympia.edu.my",
  "oum.edu.my",
  "perdanauniversity.edu.my",
  "pidc.edu.my",
  "pine.edu.my",
  "piup.edu.my",
  "pkb.edu.my",
  "pkksabah.edu.my",
  "pkkt.edu.my",
  "pmm.edu.my",
  "polijb.edu.my",
  "poliku.edu.my",
  "polimas.edu.my",
  "polimelaka.edu.my",
  "polimuadzam.edu.my",
  "polipd.edu.my",
  "polisas.edu.my",
  "psa.edu.my",
  "psas.edu.my",
  "psis.edu.my",
  "psmza.edu.my",
  "psp.edu.my",
  "ptpl.edu.my",
  "ptsb.edu.my",
  "ptss.edu.my",
  "puo.edu.my",
  "raffles-university.edu.my",
  "saito.edu.my",
  "segi.edu.my",
  "sunway.edu.my",
  "swinburne.edu.my",
  "taj.edu.my",
  "tarc.edu.my",
  "taylors.edu.my",
  "tpmcollege.edu.my",
  "ucsi.edu.my",
  "ucti.edu.my",
  "udm.edu.my",
  "uitm.edu.my",
  "ukm.my",
  "um.edu.my",
  "umk.edu.my",
  "ump.edu.my",
  "ums.edu.my",
  "umtech.edu.my",
  "umt.edu.my",
  "unikl.edu.my",
  "unimap.edu.my",
  "unimas.my",
  "unirazak.edu.my",
  "unisel.edu.my",
  "unisza.edu.my",
  "unitar.edu.my",
  "uniten.edu.my",
  "upm.edu.my",
  "upsi.edu.my",
  "usm.my",
  "utar.edu.my",
  "utem.edu.my",
  "uthm.edu.my",
  "utm.my",
  "utp.edu.my",
  "uum.edu.my",
  "westminster.edu.my",
  "wou.edu.my",
  "cyryxcollege.edu.mv",
  "mandhu.com",
  "mnu.edu.mv",
  "ml.refer.org",
  "eieonline.com",
  "itismalta.com",
  "um.edu.mt",
  "univ-nkc.mr",
  "uom.ac.mu",
  "utm.ac.mu",
  "anahuac.mx",
  "bajio.delasalle.edu.mx",
  "buap.mx",
  "campusleon.ugto.mx",
  "ceti.mx",
  "cetys.mx",
  "ceu.edu.mx",
  "ceunix.com.mx",
  "ceux.mx",
  "champagnat.edu.mx",
  "chapingo.mx",
  "colmex.mx",
  "cuauhtemoc.edu.mx",
  "cueyatl.uam.mx",
  "dgit.gob.mx",
  "ebc.mx",
  "enes.unam.mx",
  "frayluca.edu.mx",
  "hispano.edu.mx",
  "i.edu.mx",
  "ipn.edu.mx",
  "itam.mx",
  "ita.mx",
  "itc.mx",
  "itdurango.edu.mx",
  "itesm.mx",
  "iteso.mx",
  "itleon.edu.mx",
  "itmina.edu.mx",
  "itmorelia.edu.mx",
  "itq.edu.mx",
  "itslp.edu.mx",
  "itson.mx",
  "iztapalapa.uam.mx",
  "lef.upn.mx",
  "leon.uia.mx",
  "members.rotfl.com",
  "motolinia.com.mx",
  "olmeca.edu.mx",
  "poligto.edu.mx",
  "reduaeh.mx",
  "reduaz.mx",
  "secupv.org",
  "tecbc.mx",
  "tepeyac.edu.mx",
  "ties.com.mx",
  "uaaan.mx",
  "uaa.mx",
  "uabc.mx",
  "uabcs.mx",
  "uabjo.mx",
  "uacam.mx",
  "uach.mx",
  "itsncg.edu.mx",
  "uacj.mx",
  "uacm.edu.mx",
  "uadec.mx",
  "uad.edu.mx",
  "uady.mx",
  "uaed.edu.mx",
  "uaemex.mx",
  "uaem.mx",
  "uaf.mx",
  "uag.mx",
  "uagro.mx",
  "ual.mx",
  "uam.mx",
  "uane.edu.mx",
  "uanl.mx",
  "uan.mx",
  "uaq.mx",
  "uaslp.mx",
  "uas.mx",
  "uasnet.mx",
  "uat.mx",
  "uatx.mx",
  "ubac.edu.mx",
  "ucem.edu.mx",
  "uclah.edu.mx",
  "ucol.mx",
  "udec.edu.mx",
  "udeci.edu.mx",
  "udeh.edu.mx",
  "udem.edu.mx",
  "udesanmiguel.edu.mx",
  "udg.mx",
  "udla.mx",
  "udlap.mx",
  "udo.mx",
  "udv.edu.mx",
  "ufm.edu.mx",
  "ufram.edu.mx",
  "ugto.mx",
  "uia.mx",
  "uic.edu.mx",
  "uileon.edu.mx",
  "ujat.mx",
  "ujed.mx",
  "ujsierra.mx",
  "ula.edu.mx",
  "ulsab.edu.mx",
  "ulsac.edu.mx",
  "ulsa.edu.mx",
  "umad.edu.mx",
  "uman.edu.mx",
  "umar.mx",
  "umich.mx",
  "umi.edu.mx",
  "umla.edu.mx",
  "umm.edu.mx",
  "umne.edu.mx",
  "umontemorelos.edu.mx",
  "umsa.edu.mx",
  "unacar.mx",
  "unach.mx",
  "unam.mx",
  "un.edu.mx",
  "une.edu.mx",
  "unes.edu.mx",
  "unicach.edu.mx",
  "unica.edu.mx",
  "unikino.mx",
  "unimayab.edu.mx",
  "unimex.edu.mx",
  "unitec.mx",
  "univa.mx",
  "univas.edu.mx",
  "univchapultepec.edu.mx",
  "universidaddeleon.edu.mx",
  "universidadlaconcordia.edu.mx",
  "universidadmotolinia.edu.mx",
  "universidadsalesiana.edu.mx",
  "univillarica.mx",
  "unla.edu.mx",
  "un.mx",
  "uno.mx",
  "unum.edu.mx",
  "uom.edu.mx",
  "upaep.mx",
  "upiig.ipn.mx",
  "up.mx",
  "upn.mx",
  "uqroo.mx",
  "urmh.edu.mx",
  "ur.mx",
  "urse.edu.mx",
  "usac.edu.mx",
  "usb.edu.mx",
  "usf.com.mx",
  "uson.mx",
  "utm.mx",
  "utneza.edu.mx",
  "uvb.edu.mx",
  "uvg1.net",
  "uvmnet.edu",
  "uv.mx",
  "uvt.edu.mx",
  "uxac.edu.mx",
  "ver.ucc.mx",
  "westhill.edu.mx",
  "azc.uam.mx",
  "itsoeh.edu.mx",
  "jefferson.edu.mx",
  "ase.md",
  "kdu.md",
  "uasm.md",
  "uccm.md",
  "ulim.md",
  "upm.moldnet.md",
  "usam.md",
  "usb.md",
  "usmf.md",
  "usm.md",
  "ust.md",
  "utm.md",
  "riviera.fr",
  "hsum-ac.mn",
  "humanities.mn",
  "icb.edu.mn",
  "msuac.edu.mn",
  "msua.edu.mn",
  "msue.edu.mn",
  "must.edu.mn",
  "num.edu.mn",
  "orkhon.edu.mn",
  "ulaanbaatar.edu.mn",
  "ucg.cg.ac.yu",
  "usat.ms",
  "archi.ac.ma",
  "aui.ma",
  "edhec.ac.ma",
  "emi.ac.ma",
  "enameknes.ac.ma",
  "esca.ac.ma",
  "esta.ac.ma",
  "groupeiscae.ma",
  "hec.ac.ma",
  "hem.ac.ma",
  "hightech.edu",
  "iihem.ac.ma",
  "imbt.ma",
  "inpt.ac.ma",
  "isfort.ac.ma",
  "matci.ac.ma",
  "rectorat-uh2c.ac.ma",
  "rumi.ac.ma",
  "suptelecom.net.ma",
  "uae.ac.ma",
  "ucam.ac.ma",
  "ucd.ac.ma",
  "uh1.ac.ma",
  "uh2m.ac.ma",
  "um5a.ac.ma",
  "um5s.ac.ma",
  "univ-ibntofail.ac.ma",
  "univ-oujda.ac.ma",
  "usmba.ac.ma",
  "ump.ac.ma",
  "isctem.ac.mz",
  "ispu.ac.mz",
  "isri.ac.mz",
  "ucm.ac.mz",
  "uem.mz",
  "up.ac.mz",
  "unizambeze.ac.mz",
  "unilurio.ac.mz",
  "iit.com.na",
  "ilsacollege.edu.na",
  "ium.edu.na",
  "polytechnic.edu.na",
  "unam.na",
  "afu.edu.np",
  "bpkihs.edu",
  "ku.edu.np",
  "lbu.edu.np",
  "nsu.edu.np",
  "pu.edu.np",
  "purbuniv.edu.np",
  "tribhuvan-university.edu.np",
  "eustatiusmed.edu",
  "internationaluniversity-schoolofmedicine.org",
  "sjsm.org",
  "una.an",
  "ddu.nl",
  "dehaagsehogeschool.nl",
  "eur.nl",
  "rsm.nl",
  "europeanopenuniversity.com",
  "fontys.edu",
  "foundationuniversity.com",
  "hanze.nl",
  "hku.nl",
  "hotelschool.nl",
  "hro.nl",
  "hr.nl",
  "hsleiden.nl",
  "hu.nl",
  "hva.nl",
  "inholland.nl",
  "islamicuniversity.nl",
  "iss.nl",
  "itc.nl",
  "universiteitleiden.nl",
  "nhtv.nl",
  "nyenrode.nl",
  "ou.nl",
  "roac.nl",
  "rug.nl",
  "ru.nl",
  "saxion.edu",
  "stenden.com",
  "tilburguniversity.nl",
  "tilburguniversity.edu",
  "tudelft.nl",
  "student.tudelft.nl",
  "tue.nl",
  "unesco-ihe.org",
  "unimaas.nl",
  "student.maastrichtuniversity.nl",
  "utwente.nl",
  "uu.nl",
  "uva.nl",
  "uvh.nl",
  "vu.nl",
  "wageningenuniversity.nl",
  "windesheim.nl",
  "wittenborg.eu",
  "zuyd.nl",
  "ufp.nc",
  "auckland.ac.nz",
  "aucklanduni.ac.nz",
  "aut.ac.nz",
  "canterbury.ac.nz",
  "uclive.ac.nz",
  "cpit.ac.nz",
  "lincoln.ac.nz",
  "massey.ac.nz",
  "openpolytechnic.ac.nz",
  "otago.ac.nz",
  "vuw.ac.nz",
  "waikato.ac.nz",
  "nmit.ac.nz",
  "uam.edu.ni",
  "uca.edu.ni",
  "ucc.edu.ni",
  "ucem.edu.ni",
  "ucyt.edu.ni",
  "udem.edu.ni",
  "uhispam.edu.ni",
  "ulam.edu.ni",
  "una.edu.ni",
  "unan.edu.ni",
  "unica.edu.ni",
  "unicit.edu.ni",
  "uni.edu.ni",
  "unival.edu.ni",
  "upoli.edu.ni",
  "uponic.edu.ni",
  "uraccan.edu.ni",
  "uam.refer.ne",
  "aaua.edu.ng",
  "aauekpoma.edu.ng",
  "abiapoly.edu.ng",
  "absu.edu.ng",
  "abuad.edu.ng",
  "abu.edu.ng",
  "aceondo-ng.com",
  "achievers.edu.ng",
  "acu.edu.ng",
  "adelekeuniversity.edu.ng",
  "adsu.edu.ng",
  "akutech.edu.ng",
  "alhikmah.edu.ng",
  "ansu.edu.ng",
  "atbu.edu.ng",
  "auchipoly.edu.ng",
  "aun.edu.ng",
  "aust-abuja.org",
  "babcockuni.edu.ng",
  "basug.edu.ng",
  "bazeuniversity.edu.ng",
  "bellsuniversity.org",
  "binghamuni.edu.ng",
  "biu.edu.ng",
  "bowenuniversity-edu.org",
  "bsum.edu.ng",
  "buk.edu.ng",
  "calebuniversity.edu.ng",
  "caritasuni.edu.ng",
  "coeikere.edu.ng",
  "coeoju.com",
  "covenantuniversity.edu.ng",
  "crawforduniversity.edu.ng",
  "crescent-university.edu.ng",
  "crutech.edu.ng",
  "ebsu-edu.net",
  "eksu.edu.ng",
  "esut.edu.ng",
  "fcetakoka-edu.net",
  "fedpoffa.edu.ng",
  "fountainuniversity.edu.ng",
  "fupre.edu.ng",
  "futa.edu.ng",
  "futminna.edu.ng",
  "futo.edu.ng",
  "futy.edu.ng",
  "gouni.edu.ng",
  "gsu.edu.ng",
  "ibbu.edu.ng",
  "imsu.edu.ng",
  "iuokada.edu.ng",
  "jabu.edu.ng",
  "kasuportal.net",
  "ksuportal.edu.ng",
  "ksusta.edu.ng",
  "kuk.edu.ng",
  "kustwudil.edu.ng",
  "kwarapoly.edu.ng",
  "kwararafauniversity.edu.ng",
  "kwasu.edu.ng",
  "laspotech.net",
  "lasunigeria.org",
  "lautech.edu.ng",
  "lcu.edu.ng",
  "lmu.edu.ng",
  "madonnauniversity.edu.ng",
  "mouau.edu.ng",
  "mylcp.net",
  "nda.edu.ng",
  "ndu.edu.ng",
  "nou.edu.ng",
  "novenauniversity.edu.ng",
  "nsuk.edu.ng",
  "ntnu.edu.ng",
  "oauife.edu.ng",
  "obonguniversity.net",
  "oduduwauniversity.edu.ng",
  "oouagoiwoye.edu.ng",
  "osustech.edu.ng",
  "pau.edu.ng",
  "pauluniversity.edu.ng",
  "polyibadan.edu.ng",
  "polyunwana.net",
  "rnu.edu.ng",
  "run.edu.ng",
  "salemuniversity.edu.ng",
  "ssu.edu.ng",
  "tansianuniversity.edu.ng",
  "tasued.edu.ng",
  "tsuniversity.edu.ng",
  "uam.edu.ng",
  "udusok.edu.ng",
  "ui.edu.ng",
  "umyu.edu.ng",
  "unaab.edu.ng",
  "uniben.edu",
  "unibuja.edu.ng",
  "unical.edu.ng",
  "unijos.edu.ng",
  "unilag.edu.ng",
  "unilorin.edu.ng",
  "unimaid.edu.ng",
  "unimkar.edu.ng",
  "uniosun.edu.ng",
  "uniport.edu.ng",
  "uniuyo.edu.ng",
  "unizik.edu.ng",
  "unn.edu.ng",
  "ust.edu.ng",
  "veritas.edu.ng",
  "wdu.edu.ng",
  "wellspringuniversity.edu.ng",
  "wustoportal.edu.ng",
  "yabatech.edu.ng",
  "stclements.edu.nu",
  "aho.no",
  "bi.no",
  "hia.no",
  "hib.no",
  "hibo.no",
  "hifm.no",
  "hil.no",
  "himolde.no",
  "khio.no",
  "nhh.no",
  "nih.no",
  "nith.no",
  "nla.no",
  "nlh.no",
  "nmh.no",
  "ntnu.no",
  "ska.khio.no",
  "uib.no",
  "uio.no",
  "uis.no",
  "uit.no",
  "umb.no",
  "veths.no",
  "aou.edu.om",
  "buc.edu.om",
  "du.edu.om",
  "ict.edu.om",
  "majancollege.edu.om",
  "muscatcollege.edu.om",
  "omancollege.edu.om",
  "soharuni.edu.om",
  "squ.edu.om",
  "suc.edu.om",
  "unizwa.edu.om",
  "abasyn.edu.pk",
  "aiou.edu.pk",
  "ajku.edu.pk",
  "aku.edu",
  "aldirasat.edu.pk",
  "alkhair.edu.pk",
  "ami.edu.pk",
  "au.edu.pk",
  "aup.edu.pk",
  "bahria.edu.pk",
  "biit.edu.pk",
  "bims.edu.pk",
  "baqai.edu.pk",
  "buetk.edu.pk",
  "buitms.edu.pk",
  "bzu.edu.pk",
  "cust.edu.pk",
  "cbm.iobm.edu.pk",
  "cecos.edu.pk",
  "ciit-atd.edu.pk",
  "ciit-attock.edu.pk",
  "ciit.edu.pk",
  "ciitlahore.edu.pk",
  "ciit-wah.edu.pk",
  "cosmiq.edu.pk",
  "dadabhoy.edu.pk",
  "dcet.edu.pk",
  "duhs.edu.pk",
  "fitfd.edu.pk",
  "fuuast.edu.pk",
  "fwu.edu.pk",
  "gandhara.edu.pk",
  "gcu.edu.pk",
  "gcuf.edu.pk",
  "uob.edu.pk",
  "zu.edu.pk",
  "gift.edu.pk",
  "giki.edu.pk",
  "gist.edu.pk",
  "global.edu.pk",
  "gu.edu.pk",
  "guic.edu.pk",
  "hajvery.edu.pk",
  "hamdard.edu.pk",
  "hamdard.edu",
  "hitecuni.edu.pk",
  "hu.edu.pk",
  "iba.edu.pk",
  "iba-suk.edu.pk",
  "iiee.edu.pk",
  "iiu.edu.pk",
  "imsciences.edu.pk",
  "iqra.edu.pk",
  "isra.edu.pk",
  "ist.edu.pk",
  "iub.edu.pk",
  "jinnah.edu",
  "jinnah.edu.pk",
  "juw.edu.pk",
  "kasbit.edu.pk",
  "kiit.edu.pk",
  "kiu.edu.pk",
  "kmdc.edu.pk",
  "ksa.edu.pk",
  "kust.edu.pk",
  "lcwu.edu.pk",
  "lumhs.edu.pk",
  "lums.edu.pk",
  "miu.edu.pk",
  "moorelanduniversity.com",
  "muet.edu.pk",
  "mul.edu.pk",
  "nca.edu.pk",
  "neduet.edu.pk",
  "dsu.edu.pk",
  "smiu.edu.pk",
  "indus.edu.pk",
  "newports.edu.pk",
  "niab.org.pk",
  "nu.edu.pk",
  "numl.edu.pk",
  "nust.edu.pk",
  "nwfpuet.edu.pk",
  "pafkiet.edu.pk",
  "pakaims.edu",
  "pakaims.edu.pk",
  "pakistanhomoeopathy.com",
  "quest.edu.pk",
  "pedu.edu.pk",
  "pics.edu.pk",
  "pieas.edu.pk",
  "pimsat-khi.edu.pk",
  "planwel.edu",
  "prestonpak.edu.pk",
  "pucit.edu.pk",
  "pu.edu.pk",
  "pugc.edu.pk",
  "qau.edu.pk",
  "qurtuba.edu.pk",
  "riphah.edu.pk",
  "salu.edu.pk",
  "sau.edu.pk",
  "ssms.edu.pk",
  "ssuet.edu.pk",
  "suit.edu.pk",
  "superior.edu.pk",
  "szabist.edu.pk",
  "tip.edu.pk",
  "tuf.edu.pk",
  "uaar.edu.pk",
  "uaf.edu.pk",
  "ucp.edu.pk",
  "ue.edu.pk",
  "uet.edu",
  "uettaxila.edu.pk",
  "umi.edu.pk",
  "umt.edu.pk",
  "unw.edu.pk",
  "uoe.edu.pk",
  "uog.edu.pk",
  "uok.edu.pk",
  "uol.edu.pk",
  "uom.edu.pk",
  "uos.edu.pk",
  "upesh.edu",
  "usa.edu.pk",
  "usindh.edu.pk",
  "ustb.edu.pk",
  "uvas.edu.pk",
  "uw.edu.pk",
  "vu.edu.pk",
  "wahmedicalcollege.edu.pk",
  "zawiya.edu.pk",
  "aauj.edu",
  "alaqsa.edu.ps",
  "alazhar.edu.ps",
  "alquds.edu",
  "bethlehem.edu",
  "birzeit.edu",
  "hebron.edu",
  "iugaza.edu.ps",
  "najah.edu",
  "ppu.edu",
  "ptcdb.edu.ps",
  "ptuk.edu.ps",
  "qou.edu",
  "ucas.edu.ps",
  "uou.edu.ps",
  "up.edu.ps",
  "columbus.edu",
  "floret.edu.pa",
  "institutoteima.ac.pa",
  "oteima.ac.pa",
  "ucapanama.org",
  "udelas.ac.pa",
  "udi.edu",
  "ulacit.ac.pa",
  "ulat.ac.pa",
  "unachi.ac.pa",
  "unadp.ac.pa",
  "uniedpa.com",
  "up.ac.pa",
  "usma.ac.pa",
  "utp.ac.pa",
  "viu.edu.pa",
  "westcoastuniversity-edu.com",
  "dwu.ac.pg",
  "pau.ac.pg",
  "unitech.ac.pg",
  "uog.ac.pg",
  "upng.ac.pg",
  "columbia.edu.py",
  "uaa.edu.py",
  "uamericana.edu.py",
  "uap.edu.py",
  "uc.edu.py",
  "ucom.edu.py",
  "ucsa.edu.py",
  "uma.edu.py",
  "una.py",
  "une.edu.py",
  "unida.edu.py",
  "uninorte.edu.py",
  "upe.edu.py",
  "champagnat.edu.pe",
  "lamolina.edu.pe",
  "pucp.edu.pe",
  "sil.edu.pe",
  "uancv.edu.pe",
  "uandina.edu.pe",
  "uap.edu.pe",
  "uch.edu.pe",
  "ucsm.edu.pe",
  "ucsur.edu.pe",
  "ucv.edu.pe",
  "udch.edu.pe",
  "udep.edu.pe",
  "udh.edu.pe",
  "udl.edu.pe",
  "uigv.edu.pe",
  "uladech.edu.pe",
  "ulima.edu.pe",
  "umb.edu.pe",
  "unac.edu.pe",
  "unap.edu.pe",
  "unapiquitos.edu.pe",
  "unasam.edu.pe",
  "unas.edu.pe",
  "unc.edu.pe",
  "uncp.edu.pe",
  "undac.edu.pe",
  "une.edu.pe",
  "unfv.edu.pe",
  "unheval.edu.pe",
  "unica.edu.pe",
  "uni.edu.pe",
  "unife.edu.pe",
  "unitru.edu.pe",
  "unjbg.edu.pe",
  "unjfsc.edu.pe",
  "unmsm.edu.pe",
  "unp.edu.pe",
  "unprg.edu.pe",
  "unsaac.edu.pe",
  "unsa.edu.pe",
  "unsch.edu.pe",
  "uns.edu.pe",
  "unsm.edu.pe",
  "untumbes.edu.pe",
  "unu.edu.pe",
  "upao.edu.pe",
  "upc.edu.pe",
  "upch.edu.pe",
  "upci.edu.pe",
  "up.edu.pe",
  "upeu.edu.pe",
  "upla.edu.pe",
  "upnorte.edu.pe",
  "upsb.edu.pe",
  "upsjb.edu.pe",
  "upt.edu.pe",
  "urp.edu.pe",
  "usanpedro.edu.pe",
  "usat.edu.pe",
  "usmp.edu.pe",
  "usp.edu.pe",
  "uss.edu.pe",
  "utea.edu.pe",
  "utp.edu.pe",
  "uwiener.edu.pe",
  "adamson.edu.ph",
  "addu.edu.ph",
  "admu.edu.ph",
  "adnu.edu.ph",
  "adzu.edu.ph",
  "aquinas-university.edu",
  "araneta.dlsu.edu.ph",
  "arellano.edu.ph",
  "auf.edu.ph",
  "aup.edu.ph",
  "aupen.edu.ph",
  "bicol-u.edu.ph",
  "bsu.edu.ph",
  "bulsu.edu.ph",
  "ceu.edu.ph",
  "cmu.edu.ph",
  "cpuic.edu",
  "csu.edu.ph",
  "cu-cdo.edu.ph",
  "davaodoctors.edu.ph",
  "dlsu.edu.ph",
  "dmmmsu.edu.ph",
  "dwc-legazpi.edu",
  "fatima.edu.ph",
  "featiu.edu.ph",
  "feu.edu.ph",
  "foundationu.com",
  "hau.edu.ph",
  "holyspirit.edu.ph",
  "iacademy.ph",
  "isu.edu.ph",
  "iubs.co.uk",
  "ldcu.edu.ph",
  "letran.edu",
  "lnu.evis.net.ph",
  "lpu.edu.ph",
  "lsu-visca.edu.ph",
  "mcu.edu.ph",
  "mlqu.edu.ph",
  "mmsu.edu.ph",
  "mseuf.edu.ph",
  "msuiit.edu.ph",
  "msumain.edu.ph",
  "mu.fapenet.org",
  "mvc.edu.ph",
  "ndmu.edu.ph",
  "ndu.fapenet.org",
  "neu.edu.ph",
  "nu.edu.ph",
  "nulaoag.com",
  "pcu.edu.ph",
  "perpetualdalta.edu.ph",
  "plm.edu.ph",
  "pma.ph",
  "pnumanila.com.ph",
  "psu.edu.ph",
  "psu.palawan.edu.ph",
  "psu-online.edu.ph",
  "pup.edu.ph",
  "pwu.edu",
  "rtu.edu.ph",
  "sanbeda.edu.ph",
  "slc-sflu.edu.ph",
  "slu.edu.ph",
  "smu.edu.ph",
  "spu.edu.ph",
  "ssu.edu.ph",
  "su.edu.ph",
  "swu.edu.ph",
  "tup.edu.ph",
  "ua.edu.ph",
  "uap.edu.ph",
  "ubaguio.edu",
  "ub.edu.ph",
  "uc-bcf.edu.ph",
  "ucv.edu.ph",
  "ue.edu.ph",
  "uep.educ.ph",
  "uerm.edu.ph",
  "uic.globe.com.ph",
  "ui.phinma.edu.ph",
  "umindanao.edu.ph",
  "unc.edu.ph",
  "unep.edu.ph",
  "universityofbohol.com",
  "universityofcebu.edu.ph",
  "univman.edu.ph",
  "uno-r.edu.ph",
  "unp.edu.ph",
  "upang.edu.ph",
  "upb.edu.ph",
  "upd.edu.ph",
  "uphighschoolcebu.edu.ph",
  "uphr.edu.ph",
  "uplb.edu.ph",
  "upm.edu.ph",
  "upmin.edu.ph",
  "upou.org",
  "upv.edu.ph",
  "urc.edu.ph",
  "usa.edu.ph",
  "usc.edu.ph",
  "usep.edu.ph",
  "usjr.edu.ph",
  "usls.edu",
  "usm.edu.ph",
  "usp.ph",
  "ust.edu.ph",
  "uv.edu.ph",
  "vmuf.edu.ph",
  "wesleyan.edu.ph",
  "wmsu.edu.ph",
  "wvsu.edu.ph",
  "www2.mozcom.com",
  "xu.edu.ph",
  "asp.krakow.pl",
  "ae.katowice.pl",
  "ae.krakow.pl",
  "ae.poznan.pl",
  "ae.wroc.pl",
  "agh.edu.pl",
  "ahe.edu.pl",
  "amb.bydgoszcz.pl",
  "amb.edu.pl",
  "amg.gda.pl",
  "am.katowice.pl",
  "am.lodz.pl",
  "am.lublin.pl",
  "amu.edu.pl",
  "amuz.bydgoszcz.pl",
  "amuz.gda.pl",
  "amuz.krakow.pl",
  "amuz.lodz.pl",
  "amuz.poznan.pl",
  "amuz.wroc.pl",
  "amwaw.edu.pl",
  "am.wroc.pl",
  "ap.edu.pl",
  "ap.siedlce.pl",
  "ar.krakow.pl",
  "ar.lublin.pl",
  "ar.szczecin.pl",
  "art.olsztyn.pl",
  "ar.wroc.pl",
  "asp.gda.pl",
  "aspkat.edu.pl",
  "asp.lodz.pl",
  "asp.poznan.pl",
  "asp.waw.pl",
  "asp.wroc.pl",
  "at.edu.pl",
  "au.poznan.pl",
  "awf.edu.pl",
  "awf.gda.pl",
  "awf.katowice.pl",
  "awf.krakow.pl",
  "awf.poznan.pl",
  "awf.wroc.pl",
  "chopin.edu.pl",
  "collegium.edu.pl",
  "dswe.wroc.pl",
  "eas.edu.pl",
  "filmowka.lodz.pl",
  "gallus.pl",
  "gwsh.pl",
  "ifg.com.pl",
  "ksw.edu.pl",
  "kul.lublin.pl",
  "lazarski.pl",
  "man.radom.pl",
  "nkrriwf.pl",
  "pam.szczecin.pl",
  "pb.bialystok.pl",
  "pcz.czest.pl",
  "pg.gda.pl",
  "pjwstk.edu.pl",
  "pk.edu.pl",
  "p.lodz.pl",
  "pol.lublin.pl",
  "polsl.gliwice.pl",
  "polsl.pl",
  "po.opole.pl",
  "prz.rzeszow.pl",
  "pu.kielce.pl",
  "puls.edu.pl",
  "put.poznan.pl",
  "pw.edu.pl",
  "pwr.wroc.pl",
  "pwr.edu.pl",
  "pwst.krakow.pl",
  "pwsz.eu",
  "pwsz.pila.pl",
  "pz.zgora.pl",
  "qdnet.pl",
  "sggw.waw.pl",
  "sgh.waw.pl",
  "slam.katowice.pl",
  "swps.edu.pl",
  "tu.kielce.pl",
  "tu.koszalin.pl",
  "tuniv.szczecin.pl",
  "tyszkiewicz.edu.pl",
  "ub.edu.pl",
  "uj.edu.pl",
  "uksw.edu.pl",
  "umcs.lublin.pl",
  "wsei.lublin.pl",
  "uni.lodz.pl",
  "uni.opole.pl",
  "uni.torun.pl",
  "univ.gda.pl",
  "univ.rzeszow.pl",
  "univ.szczecin.pl",
  "uni.wroc.pl",
  "us.edu.pl",
  "usoms.poznan.pl",
  "utp.edu.pl",
  "uw.edu.pl",
  "uwm.edu.pl",
  "wlodkowic.pl",
  "wsb-nlu.edu.pl",
  "wsb.poznan.pl",
  "wsb.toi.tarnow.pl",
  "wsfiz.edu.pl",
  "wsh.edu.pl",
  "wship.edu.pl",
  "wsinf.edu.pl",
  "wsisiz.edu.pl",
  "wsiz.rzeszow.pl",
  "wsm.gdynia.pl",
  "wsms.edu.pl",
  "wsm.szczecin.pl",
  "wsp.bydgoszcz.pl",
  "wsp.czest.pl",
  "wspiz.edu.pl",
  "wsp.krakow.pl",
  "wsp.slupsk.pl",
  "wsps.waw.pl",
  "wsptwpwaw.edu.pl",
  "wsp.zgora.pl",
  "wsub.waw.pl",
  "wszib.krakow.pl",
  "wszim.edu.pl",
  "wsz.pl",
  "wsz-pou.edu.pl",
  "zpsb.szczecin.pl",
  "cocite.pt",
  "enautica.pt",
  "esad.pt",
  "eshte.pt",
  "europeanuniversity.pt",
  "iade.pt",
  "inp.pt",
  "inuaf-studia.pt",
  "ipbeja.pt",
  "ipb.pt",
  "ipca.pt",
  "ipcb.pt",
  "ipc.pt",
  "ipg.pt",
  "ipiaget.org",
  "ipleiria.pt",
  "my.ipleiria.pt",
  "ipl.pt",
  "ipportalegre.pt",
  "ipp.pt",
  "ipsantarem.pt",
  "ips.pt",
  "ipt.pt",
  "ipvc.pt",
  "isai.pt",
  "isave.edu.pt",
  "isbb.pt",
  "iscet.pt",
  "iscte.pt",
  "iscte-iul.pt",
  "isg.pt",
  "isla.pt",
  "ispab.pt",
  "ispa.pt",
  "ispgaya.pt",
  "istec.pt",
  "istp.pt",
  "isvouga.com",
  "oml.pt",
  "pedago.pt",
  "uac.pt",
  "ualg.pt",
  "ua.pt",
  "uatla.pt",
  "ubi.pt",
  "ucp.pt",
  "uc.pt",
  "uevora.pt",
  "ufp.pt",
  "uinternacional.pt",
  "ul.pt",
  "campus.ul.pt",
  "ulusiada.pt",
  "ulusofona.pt",
  "uma.pt",
  "uminho.pt",
  "umoderna.pt",
  "uni.pt",
  "univ-ab.pt",
  "universidade-autonoma.pt",
  "unl.pt",
  "uportu.pt",
  "up.pt",
  "utad.pt",
  "utl.pt",
  "novasbe.unl.pt",
  "bayamon.inter.edu",
  "caribbean.edu",
  "cayey.upr.edu",
  "ceaprc.org",
  "cuhupr.clu.edu",
  "inter.edu",
  "pucpr.edu",
  "rcm.upr.edu",
  "sagrado.edu",
  "sanjuanbautista.edu",
  "sg.inter.edu",
  "suagm.edu",
  "ucb.edu.pr",
  "uccaribe.edu",
  "upra.edu",
  "uprag.edu",
  "uprb.edu",
  "uprc.edu",
  "upr.clu.edu",
  "uprm.edu",
  "upr-ponce.upr.edu",
  "uprrp.edu",
  "uprutuado.edu",
  "ut.pr",
  "qatar.northwestern.edu",
  "qu.edu.qa",
  "oryxschool.qa",
  "univ-reunion.fr",
  "aistedaab.ro",
  "amgd.eu",
  "anefs-edu.ro",
  "arteiasi.ro",
  "ase.ro",
  "central.ucv.ro",
  "emanuel.ro",
  "iaim.ro",
  "mta.ro",
  "oradeauniversity.com",
  "oradeauniversity.co",
  "ovidunivconstanta-edu.org",
  "pub.ro",
  "rau.ro",
  "sibiu.ro",
  "snspa.ro",
  "spiruharet.ro",
  "tuiasi.ro",
  "uab.ro",
  "uaic.ro",
  "uartdcluj.ro",
  "uat.ro",
  "uav.ro",
  "ubbcluj.ro",
  "ubm.ro",
  "ub.ro",
  "uem.ro",
  "ugal.ro",
  "ulbsibiu.ro",
  "umfcluj.ro",
  "umfiasi.ro",
  "umftgm.ro",
  "umft.ro",
  "unarte.ro",
  "unatc.ro",
  "unibuc.ro",
  "unitbv.ro",
  "univagro-iasi.ro",
  "univcb.ro",
  "univermed-cdgm.ro",
  "universitateamaritima.ro",
  "univ-ovidius.ro",
  "unmb.ro",
  "uoradea.ro",
  "upa.ro",
  "upet.ro",
  "upg-ploiesti.ro",
  "upit.ro",
  "upm.ro",
  "usab.ro",
  "usab-tm.ro",
  "usamvcluj.ro",
  "usv.ro",
  "utcb.ro",
  "utcluj.ro",
  "utgjiu.ro",
  "utm.ro",
  "utt.ro",
  "uvt.ro",
  "uvvg.ro",
  "valahia.ro",
  "academiaga.ru",
  "adygnet.ru",
  "ael.ru",
  "agma.astranet.ru",
  "agtu.ru",
  "ame.ru",
  "amursu.ru",
  "aqua.sci-nnov.ru",
  "artacademy.spb.ru",
  "asmu.ru",
  "aspu.ru",
  "astu.org",
  "astu.secna.ru",
  "asu.ru",
  "avtlg.ru",
  "bashedu.ru",
  "bigpi.biysk.ru",
  "bmstu.ru",
  "brstu.ru",
  "bsaa.ru",
  "bspu.ru",
  "bstu.ru",
  "bsu.burnet.ru",
  "bsu.edu.ru",
  "mosgu.ru",
  "chuvsu.ru",
  "cit.drbit.com.ru",
  "conservatoire.ru",
  "conservatory.ru",
  "cspi.urc.ac.ru",
  "csu.ac.ru",
  "dgu.ru",
  "dongau.ru",
  "dstu.edu.ru",
  "dvgups.ru",
  "dvgu.ru",
  "education.ivanovo.ru",
  "elsu.ru",
  "eltech.ru",
  "engec.ru",
  "esstu.ru",
  "eu.spb.ru",
  "fa.ru",
  "festu.ru",
  "filine.centro.ru",
  "ftacademy.ru",
  "gasu.ru",
  "gitis.net",
  "gma.ru",
  "gnesin-academy.ru",
  "gorny-ins.ru",
  "gpma.ru",
  "gti.spb.ru",
  "gturp.spb.ru",
  "gubkin.ru",
  "gukit.ru",
  "guu.ru",
  "herzen.edu.ru",
  "herzen.spb.ru",
  "hse.ru",
  "iate.obninsk.ru",
  "ibch.ru",
  "ibi.spb.ru",
  "ibp-moscow.ru",
  "ieeu.udm.ru",
  "iet.ru",
  "ifmo.ru",
  "itmo.ru",
  "niuitmo.ru",
  "ime.ru",
  "intstudy.mai.ru",
  "isea.irk.ru",
  "isea.ru",
  "isma.indi.ru",
  "ispu.ru",
  "istu.irk.ru",
  "istu.ru",
  "isuct.ru",
  "isu.ru",
  "iubnt.yar.ru",
  "iufs.edu",
  "ivanovo.ac.ru",
  "jum.ru",
  "mpgu.su",
  "kai.ru",
  "kalmsu.ru",
  "kamgu.ru",
  "kantiana.ru",
  "karelia.ru",
  "kazanconservatory.ru",
  "kbsu.ru",
  "kemsu.ru",
  "kgmu.kcn.ru",
  "kgpu.ru",
  "kgtu.runnet.ru",
  "khspu.ru",
  "khstu.ru",
  "kirovballet.com",
  "kiu.ru",
  "klgtu.ru",
  "knastu.ru",
  "ksaba.ru",
  "ksfei.ru",
  "ksmu.kursknet.ru",
  "kspu.kaluga.ru",
  "kspu.ptz.ru",
  "kstu.edu.ru",
  "kstu.kursk.ru",
  "ksu.ru",
  "kubagro.ru",
  "kubstu.ru",
  "kubsu.ru",
  "kursk-uni.ru",
  "kuzstu.ac.ru",
  "lunn.sci-nnov.ru",
  "madi.ru",
  "margu.mari.ru",
  "marstu.mari.ru",
  "masu.ru",
  "mechnik.spb.ru",
  "medin.nsc.ru",
  "med.sgu.ru",
  "mesi.ru",
  "mgavm.ru",
  "mgimo.ru",
  "mgri-rggru.ru",
  "mgsu.norna.ru",
  "mgul.ac.ru",
  "miet.ru",
  "miigaik.ru",
  "miit.ru",
  "miom.org",
  "mipt.ru",
  "phystech.edu",
  "mirbis.ru",
  "mirea.ru",
  "misis.ru",
  "miu.ru",
  "mma.ru",
  "mosconsv.ru",
  "mpei.ac.ru",
  "mrsu.ru",
  "msaab.ru",
  "msiu.ru",
  "msta.ac.ru",
  "mstuca.ru",
  "mstu.edu.ru",
  "msuc.edu.ru",
  "msuee.ru",
  "msuie.ru",
  "msu.ru",
  "mtuci.ru",
  "muctr.edu.ru",
  "muh.ru",
  "nayanova.edu",
  "ncstu.ru",
  "neic.nsk.su",
  "nes.ru",
  "ngasu.sci-nnov.ru",
  "nilc.spb.ru",
  "n-nov.mednet.com",
  "nntu.sci-nnov.ru",
  "nosu.ru",
  "novsu.ac.ru",
  "nsau.edu.ru",
  "nsmu.ru",
  "nstu.ru",
  "nsu.ru",
  "g.nsu.ru",
  "nwpi.ru",
  "omgau.ru",
  "omgtu.ru",
  "omgups.ru",
  "omsk.edu",
  "omsk-osma.ru",
  "orun.ru",
  "osu.ru",
  "pfu.edu.ru",
  "pglu.ru",
  "pnzgu.ru",
  "pomorsu.ru",
  "psfa.ru",
  "psma.ru",
  "pspu.ac.ru",
  "pstu.ac.ru",
  "psu.ru",
  "rah.ru",
  "rea.ru",
  "rgata.yaroslavl.ru",
  "rshu.ru",
  "rsmu.da.ru",
  "rsmu.ru",
  "rsuh.ru",
  "rsu.ru",
  "rsute.ru",
  "rusoil.net",
  "sakhgu.sakhalin.ru",
  "samiit.ru",
  "samsmu.ru",
  "sapanet.ru",
  "sgap.ru",
  "sgau.ru",
  "sgma.info",
  "sgu.ru",
  "shu.smolensk.su",
  "sibsiu.kemerovo.su",
  "smolny-un.spb.ru",
  "smtu.ru",
  "spbau.ru",
  "spbgasu.ru",
  "spbgau.spb.ru",
  "spbguki.ru",
  "spbiir.ru",
  "spbstu.ru",
  "spbtei.ru",
  "spbu.ru",
  "spbuwc.ru",
  "spcpa.ru",
  "spmi.edu.ru",
  "spsmma.com",
  "ssaa.ru",
  "ssaba.smr.ru",
  "ssau.ru",
  "ssea.runnet.ru",
  "sseu.ru",
  "ssmu.ru",
  "ssttu.samara.ru",
  "sstu-edu.ru",
  "sstu.samara.ru",
  "ssu.komi.com",
  "ssu.samara.ru",
  "stankin.ru",
  "stavsu.ru",
  "stu.lipetsk.ru",
  "stu.ru",
  "suai.ru",
  "surgu.wsnet.ru",
  "sutd.ru",
  "sut.ru",
  "tart.spb.ru",
  "tgpi.ttn.ru",
  "tgsha.ru",
  "theatrins-yar.ru",
  "timacad.ru",
  "tltsu.ru",
  "touro.ru",
  "tpu.ru",
  "tsma.ru",
  "tsogu.ru",
  "tspu.edu.ru",
  "tstu.ru",
  "tstu.tver.ru",
  "tsuab.ru",
  "tsure.ru",
  "tsu.ru",
  "utmn.ru",
  "tsu.tula.ru",
  "ttc.ryazan.ru",
  "tu-bryansk.ru",
  "tu-chel.ac.ru",
  "tusur.ru",
  "tuvsu.ru",
  "uapa.ru",
  "uef.ru",
  "ugatu.ac.ru",
  "ugrasu.ru",
  "ugsha.ru",
  "uii.sever.ru",
  "ulspu.ru",
  "ulstu.ru",
  "ulsu.ru",
  "uni-dubna.ru",
  "unilib.neva.ru",
  "uni.udm.ru",
  "univer.omsk.su",
  "university.tversu.ru",
  "uni-vologda.ac.ru",
  "uniyar.ac.ru",
  "unn.ac.ru",
  "urc.ac.ru",
  "usaaa.ru",
  "usart.ru",
  "uscon.ru",
  "usfea.ru",
  "usla.ru",
  "usmga.ru",
  "urfu.ru",
  "usue.ru",
  "usu.ru",
  "uvauga.ru",
  "vfrta.ru",
  "vgafk.ru",
  "vgasa.ru",
  "vgta.vrn.ru",
  "vieup.ru",
  "vlsu.ru",
  "voenmeh.ru",
  "volsu.ru",
  "vorstu.ac.ru",
  "vsau.ru",
  "vsgaki.burnet.ru",
  "vsgtu.eastsib.ru",
  "vsma.info",
  "vspu.ac.ru",
  "vspu.kirov.ru",
  "vspu.ru",
  "vstu.ru",
  "vsu.ru",
  "vvsu.ru",
  "yma.ac.ru",
  "yspu.yar.ru",
  "ystu.yar.ru",
  "ysu.ru",
  "synergy.ru",
  "synergy.university",
  "auca.ac.rw",
  "inatek.ac.rw",
  "isae.ac.rw",
  "khi.ac.rw",
  "kie.ac.rw",
  "kist.ac.rw",
  "nur.ac.rw",
  "sfb.ac.rw",
  "uck.ac.rw",
  "ulk.ac.rw",
  "umutarapolytech.ac.rw",
  "iugrad.edu.kn",
  "stmu.org",
  "unisilvaner.info",
  "windsor.edu",
  "spartanmed.org",
  "tusom.org",
  "nus.edu.ws",
  "unirsm.sm",
  "iau.edu.sa",
  "act.gotevot.edu.sa",
  "alfaisal.edu",
  "almaarifah.com",
  "alyamamah.edu.sa",
  "arabou.edu.sa",
  "bmc.edu.sa",
  "bpc.edu.sa",
  "bu.edu.sa",
  "cba.edu.sa",
  "cti.edu.sa",
  "dah.edu.sa",
  "dau.edu.sa",
  "dcc.edu.sa",
  "dct.gotevot.edu.sa",
  "effatcollege.edu.sa",
  "fbsc.edu.sa",
  "ibnsina.edu.sa",
  "imamu.edu.sa",
  "ipa.edu.sa",
  "iu.edu.sa",
  "jazanu.edu.sa",
  "jct.edu.sa",
  "jic.edu.sa",
  "ju.edu.sa",
  "kau.edu.sa",
  "kaust.edu.sa",
  "kct.gotevot.edu.sa",
  "kfsc.edu.sa",
  "kfu.edu.sa",
  "kfupm.edu.sa",
  "kku.edu.sa",
  "ksau-hs.edu.sa",
  "ksu.edu.sa",
  "ku.edu.sa",
  "mcst.edu.sa",
  "mu.edu.sa",
  "nauss.edu.sa",
  "nbu.edu.sa",
  "ngha.med.sa",
  "nu.edu.sa",
  "pcdp.edu.sa",
  "pmu.edu.sa",
  "pscabha.edu.sa",
  "psu.edu.sa",
  "qc.edu.sa",
  "qu.edu.sa",
  "rct.edu.sa",
  "riyadh.edu.sa",
  "rug.edu.sa",
  "saadcollege.com",
  "su.edu.sa",
  "taibahu.edu.sa",
  "tnmeyah.net",
  "tu.edu.sa",
  "ucj.edu.sa",
  "ud.edu.sa",
  "uoh.edu.sa",
  "uqu.edu.sa",
  "ut.edu.sa",
  "yic.edu.sa",
  "yti.edu.sa",
  "yuc.edu.sa",
  "afi-ue.sn",
  "bambey.univ.sn",
  "uahb.sn",
  "ucad.sn",
  "udb.sn",
  "uea.edu.sn",
  "ugb.sn",
  "unis.sn",
  "univ-thies.sn",
  "univ-zig.sn",
  "arts.bg.ac.rs",
  "bg.ac.rs",
  "eu.ac.rs",
  "kg.ac.rs",
  "megatrend-edu.net",
  "megatrend.edu.rs",
  "xz.edu.rs",
  "metropolitan.edu.rs",
  "ni.ac.rs",
  "np.ac.rs",
  "pr.ac.rs",
  "privrednaakademija.edu.rs",
  "singidunum.edu.rs",
  "uninp.edu.rs",
  "uns.ac.rs",
  "unisey.ac.sc",
  "usaim.edu",
  "fbcusl.8k.com",
  "liccsalsl.org",
  "nu-online.com",
  "tusol.org",
  "aventis.edu.sg",
  "ntu.edu.sg",
  "nus.edu",
  "nus.edu.sg",
  "yale-nus.edu.sg",
  "sim.edu.sg",
  "smu.edu.sg",
  "singaporetech.edu.sg",
  "sutd.edu.sg",
  "suss.edu.sg",
  "uas.edu.sg",
  "taoistcollege.org.sg",
  "ite.edu.sg",
  "tp.edu.sg",
  "sp.edu.sg",
  "np.edu.sg",
  "nyp.edu.sg",
  "rp.edu.sg",
  "akademiapz.sk",
  "aku.sk",
  "aos.sk",
  "bisla.sk",
  "dti.sk",
  "euba.sk",
  "ismpo.sk",
  "ku.sk",
  "szu.sk",
  "selyeuni.sk",
  "sevs.sk",
  "stuba.sk",
  "tnuni.sk",
  "truni.sk",
  "tuke.sk",
  "tuzvo.sk",
  "ucm.sk",
  "ukf.sk",
  "umb.sk",
  "uniag.sk",
  "uniba.sk",
  "uninova.sk",
  "unipo.sk",
  "uniza.sk",
  "upjs.sk",
  "uvm.sk",
  "vsm.sk",
  "vsmu.sk",
  "vssladkovicovo.sk",
  "vssvalzbety.sk",
  "vsvu.sk",
  "ung.si",
  "uni-lj.si",
  "um.si",
  "upr.si",
  "amouduniversity.org",
  "benadiruniversity.net",
  "bosasocollege.com",
  "buraouniversity.com",
  "eastafricauniversity.net",
  "eastsomaliauniversity.com",
  "eelouniversity.org",
  "gollisuniversity.com",
  "hargeisauniversity.net",
  "hiiraanuniversity.info",
  "maakhiruniversity.net",
  "mogadishuuniversity.com",
  "nugaaluniversity.com",
  "puntlandstateuniversity.com",
  "sanaaguniversity.com",
  "somtech.org",
  "universityofsomalia.net",
  "cput.ac.za",
  "cut.ac.za",
  "dut.ac.za",
  "mandela.ac.za",
  "nmmu.ac.za",
  "ru.ac.za",
  "sun.ac.za",
  "tut.ac.za",
  "uct.ac.za",
  "myuct.ac.za",
  "ufh.ac.za",
  "uj.ac.za",
  "ukzn.ac.za",
  "ul.ac.za",
  "unisa.ac.za",
  "univen.ac.za",
  "nwu.ac.za",
  "uniwest.ac.za",
  "uovs.ac.za",
  "ufs.ac.za",
  "up.ac.za",
  "uwc.ac.za",
  "unizulu.ac.za",
  "vut.ac.za",
  "wits.ac.za",
  "wsu.ac.za",
  "msa.ac.za",
  "ump.ac.za",
  "mut.ac.za",
  "smu.ac.za",
  "spu.ac.za",
  "aisct.org",
  "juba.edu.sd",
  "unbeg.edu.sd",
  "barcelonagse.eu",
  "bircham.edu",
  "ceu.es",
  "deusto.es",
  "ehu.es",
  "ehu.eus",
  "esic.es",
  "global-business-school.org",
  "ie.edu",
  "iuse.edu.es",
  "lasalleuniversities.net",
  "muni.es",
  "mundragon.edu",
  "salleurl.edu",
  "schillermadrid.edu",
  "uab.es",
  "ua.es",
  "uah.es",
  "ualm.es",
  "uam.es",
  "uax.es",
  "ub.es",
  "ubu.es",
  "uc3m.es",
  "uca.es",
  "ucam.edu",
  "ucavila.es",
  "uchceu.es",
  "uclm.es",
  "ucm.es",
  "uco.es",
  "ucv.es",
  "udc.es",
  "udg.es",
  "udl.es",
  "uem.es",
  "ufv.es",
  "ugr.es",
  "uhu.es",
  "uia.es",
  "uib.es",
  "uic.es",
  "uimp.es",
  "ujaen.es",
  "uji.es",
  "ull.es",
  "ulpgc.es",
  "uma.es",
  "um.es",
  "umh.es",
  "unavarra.es",
  "unav.es",
  "uned.es",
  "unex.es",
  "uniaam.uia.es",
  "uloyola.es",
  "uniactiva.com",
  "uniara.uia.es",
  "unican.es",
  "unileon.es",
  "uniovi.es",
  "unirioja.es",
  "universidadatlantico.org",
  "unizar.es",
  "unnet.es",
  "uoc.es",
  "uoc.edu",
  "upc.edu",
  "upco.es",
  "upct.es",
  "upf.es",
  "upf.edu",
  "upm.es",
  "upo.es",
  "upsa.es",
  "upv.es",
  "urjc.es",
  "gsyc.es",
  "url.es",
  "urv.es",
  "usal.es",
  "usc.es",
  "us.es",
  "uva.es",
  "uv.es",
  "uvic.es",
  "uvigo.es",
  "villanueva.edu",
  "accimt.ac.lk",
  "bbu.ac.lk",
  "bpu.ac.lk",
  "cmb.ac.lk",
  "esn.ac.lk",
  "jfn.ac.lk",
  "kdu.ac.lk",
  "kln.ac.lk",
  "mrt.ac.lk",
  "ou.ac.lk",
  "pdn.ac.lk",
  "pgia.ac.lk",
  "rjt.ac.lk",
  "ruh.ac.lk",
  "sab.ac.lk",
  "saitm.edu.lk",
  "seu.ac.lk",
  "sjp.ac.lk",
  "sliate.net",
  "sliit.lk",
  "uwu.ac.lk",
  "vpa.ac.lk",
  "wyb.ac.lk",
  "nsbm.lk",
  "nibm.lk",
  "iit.ac.lk",
  "aau.edu.sd",
  "ahfad.edu.sd",
  "bakhtalruda.edu.sd",
  "bayantech.edu",
  "bnu.edu.sd",
  "ccs.edu.sd",
  "dalanjuniversity.edu.sd",
  "elmahdi.edu.sd",
  "elrazicollege.net",
  "fashir.edu.sd",
  "gaduniv.edu.sd",
  "geziracollege.edu.sd",
  "hibfs.edu.sd",
  "iua.edu.sd",
  "kassalauni.edu.sd",
  "kordofan.edu.sd",
  "nc.edu.sd",
  "neelain.edu.sd",
  "nilevalley.edu.sd",
  "oau.edu.sd",
  "oiu.edu.sd",
  "ous.edu.sd",
  "quran-unv.edu.sd",
  "ribat.edu.sd",
  "rsu.edu.sd",
  "sas-sd.net",
  "sinnaruniv.edu.sd",
  "sustech.edu",
  "umst-edu.com",
  "unu.edu.sd",
  "uofd.edu.sd",
  "uofg.edu.sd",
  "uofk.edu",
  "ush.sd",
  "ust.edu.sd",
  "uvs.edu",
  "uniswa.sz",
  "bth.se",
  "chalmers.se",
  "du.se",
  "gu.se",
  "hb.se",
  "hdk.gu.se",
  "hgo.se",
  "hh.se",
  "hhs.se",
  "hig.se",
  "hik.se",
  "his.se",
  "hj.se",
  "hkr.se",
  "htu.se",
  "kau.se",
  "ki.se",
  "konstfack.se",
  "kth.se",
  "lhs.se",
  "liu.se",
  "lu.se",
  "luth.se",
  "mah.se",
  "mdh.se",
  "miun.se",
  "nhv.se",
  "oru.se",
  "sh.se",
  "slu.se",
  "stockholm-fu.com",
  "su.se",
  "umu.se",
  "uu.se",
  "vxu.se",
  "wmu.se",
  "american-college.com",
  "bhms.ch",
  "bsl-lausanne.ch",
  "cubidor.com",
  "dct.ch",
  "egs.edu",
  "ehl.ch",
  "euruni.edu",
  "fc.edu",
  "fh-aargau.ch",
  "fhbb.ch",
  "fh-htachur.ch",
  "fhso.ch",
  "gbs-ge.ch",
  "glion.edu",
  "gsba.ch",
  "hgkz.ch",
  "ici-luzern.com",
  "imi-luzern.com",
  "isbm-school.com",
  "iun.ch",
  "lesroches.edu",
  "lrguas.ch",
  "phw.info",
  "sbs.edu",
  "swissmc.ch",
  "swissu.org",
  "uccr.edu",
  "uniludes.ch",
  "victoria-uni.ch",
  "webster.ch",
  "zhwin.ch",
  "epfl.ch",
  "epfl.edu",
  "ethz.ch",
  "eth.edu",
  "usi.ch",
  "unibas.ch",
  "unifr.ch",
  "unige.ch",
  "uzh.ch",
  "unizh.ch",
  "unibe.ch",
  "unilu.ch",
  "unisg.ch",
  "student.unisg.ch",
  "unil.ch",
  "unine.ch",
  "bfh.ch",
  "ffhs.ch",
  "fhnw.ch",
  "fhsg.ch",
  "hepl.ch",
  "hep-bejune.ch",
  "edufr.ch",
  "hepvs.ch",
  "hes-so.ch",
  "hfh.ch",
  "hslu.ch",
  "hsr.ch",
  "fh-htwchur.ch",
  "fh-hwz.ch",
  "ntb.ch",
  "phzg.ch",
  "phzh.ch",
  "phbern.ch",
  "ph-gr.ch",
  "phlu.ch",
  "phsg.ch",
  "phsz.ch",
  "phtg.ch",
  "supsi.ch",
  "zhaw.ch",
  "zhdk.ch",
  "chuv.ch",
  "hcuge.ch",
  "insel.ch",
  "unispital.ch",
  "rosey.ch",
  "aiu.edu.sy",
  "albaath-univ.edu.sy",
  "alepuniv.edu.sy",
  "damascusuniversity.edu.sy",
  "hiba.edu.sy",
  "ipu.edu.sy",
  "iust.edu.sy",
  "must.edu.sy",
  "siust.edu.sy",
  "svuonline.org",
  "tishreen.edu.sy",
  "uok.edu.sy",
  "wiu.edu.sy",
  "wpu.edu.sy",
  "ypu.edu.sy",
  "au.edu.tw",
  "ccu.edu.tw",
  "cgu.edu.tw",
  "chna.edu.tw",
  "chu.edu.tw",
  "cju.edu.tw",
  "cku.edu.tw",
  "cmc.edu.tw",
  "cpu.edu.tw",
  "csmc.edu.tw",
  "cycu.edu.tw",
  "cyut.edu.tw",
  "dyu.edu.tw",
  "english.web.ncku.edu.tw",
  "fcu.edu.tw",
  "fju.edu.tw",
  "hcu.edu.tw",
  "hfu.edu.tw",
  "isu.edu.tw",
  "kmc.edu.tw",
  "ksit.edu.tw",
  "ksu.edu.tw",
  "lhu.edu.tw",
  "ltc.edu.tw",
  "mcu.edu.tw",
  "nccu.edu.tw",
  "nchu.edu.tw",
  "nchulc.edu.tw",
  "ncnu.edu.tw",
  "ncpes.edu.tw",
  "ncu.edu.tw",
  "ncue.edu.tw",
  "ncyu.edu.tw",
  "ndhu.edu.tw",
  "nfu.edu.tw",
  "nhctc.edu.tw",
  "nhltc.edu.tw",
  "nhu.edu.tw",
  "nia.edu.tw",
  "niu.edu.tw",
  "nkust.edu.tw",
  "nknu.edu.tw",
  "nou.edu.tw",
  "npttc.edu.tw",
  "npust.edu.tw",
  "nsysu.edu.tw",
  "ntca.edu.tw",
  "ntcn.edu.tw",
  "ntcpe.edu.tw",
  "ntctc.edu.tw",
  "nthu.edu.tw",
  "ntntc.edu.tw",
  "ntnu.edu.tw",
  "ntou.edu.tw",
  "ntptc.edu.tw",
  "ntpu.edu.tw",
  "ntttc.edu.tw",
  "ntu.edu.tw",
  "ntust.edu.tw",
  "gapps.ntust.edu.tw",
  "ntut.edu.tw",
  "nuk.edu.tw",
  "nuu.edu.tw",
  "ouk.edu.tw",
  "pccu.edu.tw",
  "pu.edu.tw",
  "scc.edu.tw",
  "scu.edu.tw",
  "shu.edu.tw",
  "sjsmit.edu.tw",
  "stut.edu.tw",
  "tcu.edu.tw",
  "thmu.edu.tw",
  "thu.edu.tw",
  "tku.edu.tw",
  "tmc.edu.tw",
  "tmtc.edu.tw",
  "tnca.edu.tw",
  "tpec.edu.tw",
  "ttit.edu.tw",
  "ttu.edu.tw",
  "nycu.edu.tw",
  "yuntech.edu.tw",
  "yzu.edu.tw",
  "tajagroun.tj",
  "tgnu.tarena.tj",
  "tiu.tj",
  "avu.org",
  "dit.ac.tz",
  "hkmu.ac.tz",
  "iaa.ac.tz",
  "ifm.ac.tz",
  "imtu.edu",
  "kcmc.ac.tz",
  "mmu.ac.tz",
  "mnma.ac.tz",
  "muccobs.ac.tz",
  "muchs.ac.tz",
  "mzumbe.ac.tz",
  "out.ac.tz",
  "saut.ac.tz",
  "sekuco.org",
  "stjosephtanzania.com",
  "suanet.ac.tz",
  "tumaini.ac.tz",
  "udom.ac.tz",
  "udsm.ac.tz",
  "ait.ac.th",
  "apiu.edu",
  "aru.ac.th",
  "asianust.ac.th",
  "au.edu",
  "bu.ac.th",
  "buu.ac.th",
  "chiangmai.ac.th",
  "chiangraicampus.rmutl.ac.th",
  "chula.ac.th",
  "cmru.ac.th",
  "cmvc.ac.th",
  "cpu.ac.th",
  "dpu.ac.th",
  "dusit.ac.th",
  "eau.ac.th",
  "efau.org",
  "hcu.ac.th",
  "hu.ac.th",
  "ibc.ac.th",
  "kbu.ac.th",
  "kku.ac.th",
  "kmitl.ac.th",
  "kmutnb.ac.th",
  "kmutt.ac.th",
  "krirk.ac.th",
  "ku.ac.th",
  "lampangvc.ac.th",
  "lcat.ac.th",
  "lcct.ac.th",
  "lpru.ac.th",
  "mahidol.ac.th",
  "mbu.ac.th",
  "mcu.ac.th",
  "mfu.ac.th",
  "mju.ac.th",
  "msu.ac.th",
  "muic.mahidol.ac.th",
  "mut.ac.th",
  "nan.rmutl.ac.th",
  "nida.ac.th",
  "nuv.ac.in",
  "nivadhana.ac.th",
  "nu.ac.th",
  "payap.ac.th",
  "psu.ac.th",
  "ptu.ac.th",
  "rmutp.ac.th",
  "rsu.ac.th",
  "ru.ac.th",
  "sau.ac.th",
  "shinawatra.ac.th",
  "siam.edu",
  "spu.ac.th",
  "stamford.edu",
  "stjohn.ac.th",
  "stou.ac.th",
  "su.ac.th",
  "sut.ac.th",
  "swu.ac.th",
  "tsu.ac.th",
  "tu.ac.th",
  "ubu.ac.th",
  "utcc.ac.th",
  "vu.ac.th",
  "webster.ac.th",
  "wu.ac.th",
  "nation.ac.th",
  "univ-lome.tg",
  "usc.edu.tt",
  "utt.edu.tt",
  "uwi.tt",
  "disfm.rnu.tn",
  "isetr.rnu.tn",
  "isg.rnu.tn",
  "u7nc.uvt.rnu.tn",
  "ucaat.com",
  "uc.rnu.tn",
  "ugaf.rnu.tn",
  "uj.rnu.tn",
  "ult.ens.tn",
  "uma.rnu.tn",
  "um.rnu.tn",
  "unat.ens.tn",
  "univgb.rnu.tn",
  "univ-k.rnu.tn",
  "uss.rnu.tn",
  "utm.rnu.tn",
  "utunis.rnu.tn",
  "uvt.rnu.tn",
  "uz.rnu.tn",
  "adu.edu.tr",
  "ahievran.edu.tr",
  "akdeniz.edu.tr",
  "anadolu.edu.tr",
  "ankara.edu.tr",
  "arel.edu.tr",
  "atauni.edu.tr",
  "atilim.edu.tr",
  "bahcesehir.edu.tr",
  "balikesir.edu.tr",
  "baskent.edu.tr",
  "mcbu.edu.tr",
  "cbu.edu.tr",
  "beykent.edu.tr",
  "bilecik.edu.tr",
  "bilgi.edu.tr",
  "bilkent.edu.tr",
  "boun.edu.tr",
  "cag.edu.tr",
  "cankaya.edu.tr",
  "comu.edu.tr",
  "cu.edu.tr",
  "cumhuriyet.edu.tr",
  "deu.edu.tr",
  "dho.edu.tr",
  "dicle.edu.tr",
  "dogus.edu.tr",
  "dumlupinar.edu.tr",
  "dpu.edu.tr",
  "ege.edu.tr",
  "erciyes.edu.tr",
  "etu.edu.tr",
  "firat.edu.tr",
  "fbu.edu.tr",
  "gantep.edu.tr",
  "gazi.edu.tr",
  "gop.edu.tr",
  "gsu.edu.tr",
  "gyte.edu.tr",
  "halic.edu.tr",
  "harran.edu.tr",
  "hun.edu.tr",
  "hacettepe.edu.tr",
  "ibu.edu.tr",
  "iku.edu.tr",
  "inonu.edu.tr",
  "isikun.edu.tr",
  "ticaret.edu.tr",
  "istanbulticaret.edu.tr",
  "itu.edu.tr",
  "iyte.edu.tr",
  "kafkas.edu.tr",
  "karaelmas.edu.tr",
  "khas.edu.tr",
  "kilis.edu.tr",
  "kku.edu.tr",
  "konya.edu.tr",
  "kou.edu.tr",
  "ksu.edu.tr",
  "ktu.edu.tr",
  "ku.edu.tr",
  "maltepe.edu.tr",
  "marmara.edu.tr",
  "mersin.edu.tr",
  "mku.edu.tr",
  "msu.edu.tr",
  "mu.edu.tr",
  "marun.edu.tr",
  "ohu.edu.tr",
  "nku.edu.tr",
  "metu.edu.tr",
  "ogu.edu.tr",
  "omu.edu.tr",
  "odu.edu.tr",
  "ozyegin.edu.tr",
  "ozu.edu.tr",
  "pamukkale.edu.tr",
  "sabanciuniv.edu.tr",
  "sabanciuniv.edu",
  "sau.edu.tr",
  "sakarya.edu.tr",
  "sdu.edu.tr",
  "sehir.edu.tr",
  "selcuk.edu.tr",
  "tarsus.edu.tr",
  "tau.edu.tr",
  "trakya.edu.tr",
  "ufuk.edu.tr",
  "uludag.edu.tr",
  "yalova.edu.tr",
  "yasar.edu.tr",
  "ybu.edu.tr",
  "yeditepe.edu.tr",
  "yildiz.edu.tr",
  "yyu.edu.tr",
  "ittu.edu.tm",
  "charismauniversity.org",
  "bugemauniv.ac.ug",
  "busitema.ac.ug",
  "busogauniversity.ac.ug",
  "cbu2000.com",
  "gu.ac.ug",
  "iuiu.ac.ug",
  "kiu.ac.ug",
  "ku.ac.ug",
  "kyu.ac.ug",
  "mak.ac.ug",
  "mmu.ac.ug",
  "mru.ac.ug",
  "must.ac.ug",
  "ndejjeuniversity.ac.ug",
  "nkumbauniversity.ac.ug",
  "saiu.ac.ug",
  "ucu.ac.ug",
  "umu.ac.ug",
  "academy.sumy.ua",
  "arosmu.org",
  "btsau.kiev.ua",
  "chnu.cv.ua",
  "crsmu.com",
  "diit.edu.ua",
  "dnu.dp.ua",
  "donmeduni.com",
  "donntu.edu.ua",
  "donnu.edu.ua",
  "dstu.dp.ua",
  "duep.edu",
  "forest.lviv.ua",
  "franko.lviv.ua",
  "hneu.edu.ua",
  "ifdtung.if.ua",
  "kdpu.edu.ua",
  "khadi.kharkov.ua",
  "khai.edu",
  "kma.mk.ua",
  "kneu.kiev.ua",
  "knuba.edu.ua",
  "kpi.kharkov.ua",
  "kpi.ua",
  "krok.edu.ua",
  "kse.org.ua",
  "ksmu.kharkov.ua",
  "kstuca.kharkov.ua",
  "ksu.edu.ua",
  "nure.ua",
  "lgpu.lg.ua",
  "lp.edu.ua",
  "lsmu.edu.ua",
  "ma.odessa.ua",
  "meduniv.lviv.ua",
  "nau.edu.ua",
  "nauu.kiev.ua",
  "nmetau.edu.ua",
  "nmu.edu.ua",
  "nmu.org.ua",
  "npu.edu.ua",
  "ntu.kar.net",
  "ogasa.odessa.ua",
  "onu.edu.ua",
  "opu.odessa.ua",
  "osaft.odessa.ua",
  "osmu.odessa.ua",
  "pntu.edu.ua",
  "pu.if.ua",
  "pusku.edu.ua",
  "sau.sumy.ua",
  "sevntu.com.ua",
  "snu.edu.ua",
  "sumdu.edu.ua",
  "tane.edu.ua",
  "tnu.crimea.ua",
  "tu.edu.te.ua",
  "tup.km.ua",
  "uamsa.net",
  "ukma.kiev.ua",
  "ukrfa.kharkov.ua",
  "univd.edu.ua",
  "univer.kharkov.ua",
  "university.kherson.ua",
  "univ.kiev.ua",
  "knu.ua",
  "univ.uzhgorod.ua",
  "uosa.uar.net",
  "usmtu.edu.ua",
  "usuft.kiev.ua",
  "vdu.edu.ua",
  "vnmu.vn.ua",
  "vstu.vinnica.ua",
  "zntu.edu.ua",
  "znu.edu.ua",
  "lnu.edu.ua",
  "acd.ac.ae",
  "adu.ac.ae",
  "agu.ae",
  "ajman.ac.ae",
  "alainuniversity.ac.ae",
  "alhosnu.ae",
  "aud.edu",
  "aue.ae",
  "aus.edu",
  "buid.ac.ae",
  "dmcg.edu",
  "dpc.edu",
  "dubai.rit.edu",
  "ece.ac.ae",
  "emiratesacademy.edu",
  "gmu.ac.ae",
  "hbmeu.ac.ae",
  "hct.ac.ae",
  "ittihad.ac.ae",
  "ju.ac.ae",
  "khawarizmi.com",
  "ku.ac.ae",
  "kustar.ac.ae",
  "masdar.ac.ae",
  "nyuad.nyu.edu",
  "pi.ac.ae",
  "rakmhsu.com",
  "sharjah.ac.ae",
  "skylineuniversity.com",
  "sorbonne.ae",
  "uaeu.ac.ae",
  "ud.ac.ae",
  "uojazeera.com",
  "uowdubai.ac.ae",
  "zu.ac.ae",
  "sia.ae",
  "abdn.ac.uk",
  "aberdeen.ac.uk",
  "aber.ac.uk",
  "abertay.ac.uk",
  "aiuniv.edu",
  "anglia.ac.uk",
  "aston.ac.uk",
  "aul.edu",
  "bangor.ac.uk",
  "bath.ac.uk",
  "bathspa.ac.uk",
  "bbk.ac.uk",
  "bcom.ac.uk",
  "bcu.ac.uk",
  "beds.ac.uk",
  "bham.ac.uk",
  "bolton.ac.uk",
  "bournemouth.ac.uk",
  "brad.ac.uk",
  "brijnet.org",
  "bristol.ac.uk",
  "bris.ac.uk",
  "brookes.ac.uk",
  "brunel.ac.uk",
  "bton.ac.uk",
  "buck.ac.uk",
  "bucks.ac.uk",
  "cam.ac.uk",
  "camb.linst.ac.uk",
  "canterbury.ac.uk",
  "cardiff.ac.uk",
  "cf.ac.uk",
  "chelsea.linst.ac.uk",
  "chester.ac.uk",
  "city.ac.uk",
  "courtauld.ac.uk",
  "coventry.ac.uk",
  "cranfield.ac.uk",
  "csm.linst.ac.uk",
  "derby.ac.uk",
  "dmu.ac.uk",
  "dundee.ac.uk",
  "dur.ac.uk",
  "edgehill.ac.uk",
  "ed.ac.uk",
  "eselondon.ac.uk",
  "essex.ac.uk",
  "sx.ac.uk",
  "ex.ac.uk",
  "falmouth.ac.uk",
  "gcal.ac.uk",
  "gcu.ac.uk",
  "gla.ac.uk",
  "glam.ac.uk",
  "glos.ac.uk",
  "gold.ac.uk",
  "gre.ac.uk",
  "gsa.ac.uk",
  "student.gsa.ac.uk",
  "halifaxuni.ac",
  "herts.ac.uk",
  "heythrop.ac.uk",
  "hope.ac.uk",
  "hud.ac.uk",
  "hull.ac.uk",
  "huron.ac.uk",
  "hw.ac.uk",
  "imperial.ac.uk",
  "ic.ac.uk",
  "ifslearning.ac.uk",
  "ihr.sas.ac.uk",
  "ioe.ac.uk",
  "islamiccolleges.com",
  "kcl.ac.uk",
  "keele.ac.uk",
  "kingston.ac.uk",
  "kolieh.com",
  "lamp.ac.uk",
  "lancs.ac.uk",
  "lboro.ac.uk",
  "student.lboro.ac.uk",
  "lbs.lon.ac.uk",
  "lcst.ac",
  "le.ac.uk",
  "leeds.ac.uk",
  "lgu.ac.uk",
  "limt.co.uk",
  "lincoln.ac.uk",
  "liv.ac.uk",
  "ljmu.ac.uk",
  "leedsbeckett.ac.uk",
  "lon.ac.uk",
  "londonmet.ac.uk",
  "lsbf.org.uk",
  "lse.ac.uk",
  "lshtm.ac.uk",
  "manchester.ac.uk",
  "mbs.ac.uk",
  "man.ac.uk",
  "mdx.ac.uk",
  "med.ic.ac.uk",
  "mmu.ac.uk",
  "napier.ac.uk",
  "ncl.ac.uk",
  "newport.ac.uk",
  "niu.org.uk",
  "northampton.ac.uk",
  "nottingham.ac.uk",
  "ntu.ac.uk",
  "open.ac.uk",
  "ou.ac.uk",
  "oxford.ac.uk",
  "ox.ac.uk",
  "paisley.ac.uk",
  "plymouth.ac.uk",
  "port.ac.uk",
  "qmw.ac.uk",
  "qmul.ac.uk",
  "qub.ac.uk",
  "ram.ac.uk",
  "rca.ac.uk",
  "network.rca.ac.uk",
  "rcm.ac.uk",
  "rdg.ac.uk",
  "rfhsm.ac.uk",
  "rgu.ac.uk",
  "royalholloway.ac.uk",
  "rhbnc.ac.uk",
  "rh.ac.uk",
  "richmond.ac.uk",
  "roehampton.ac.uk",
  "salford.ac.uk",
  "sas.ac.uk",
  "sbu.ac.uk",
  "schillerlondon.ac.uk",
  "sclondon.ac",
  "sghms.ac.uk",
  "shef.ac.uk",
  "sheffield.ac.uk",
  "shu.ac.uk",
  "smu.ac.uk",
  "soas.ac.uk",
  "solent.ac.uk",
  "sothebysinstitutelondon.com",
  "soton.ac.uk",
  "ssees.ac.uk",
  "staffs.ac.uk",
  "st-andrews.ac.uk",
  "st-and.ac.uk",
  "stir.ac.uk",
  "st-patricks.ac.uk",
  "strath.ac.uk",
  "sunderland.ac.uk",
  "surrey.ac.uk",
  "sussex.ac.uk",
  "swan.ac.uk",
  "tcm.ac.uk",
  "tees.ac.uk",
  "trinity-cm.ac.uk",
  "tvu.ac.uk",
  "ucl.ac.uk",
  "uclan.ac.uk",
  "ucs.ac.uk",
  "uea.ac.uk",
  "uel.ac.uk",
  "ukc.ac.uk",
  "ulsop.ac.uk",
  "umds.ac.uk",
  "unl.ac.uk",
  "northumbria.ac.uk",
  "uwcm.ac.uk",
  "uwe.ac.uk",
  "uwic.ac.uk",
  "wales.ac.uk",
  "warnborough.edu",
  "warwick.ac.uk",
  "westminster.ac.uk",
  "williamgilbert.co.uk",
  "wimbledon.ac.uk",
  "wlv.ac.uk",
  "worc.ac.uk",
  "york.ac.uk",
  "middlesbro.ac.uk",
  "mbro.ac.uk",
  "stmarys.ac.uk",
  "leedstrinity.ac.uk",
  "eastcoast.ac.uk",
  "swansea.ac.uk",
  "yorksj.ac.uk",
  "cavc.ac.uk",
  "claeh.edu.uy",
  "ort.edu.uy",
  "ucu.edu.uy",
  "ude.edu.uy",
  "um.edu.uy",
  "universidad.edu.uy",
  "fing.edu.uy",
  "agrar.uz",
  "ferpi.dem.ru",
  "ibs.uz",
  "mdis.uz",
  "ndpi.uz",
  "nuu.uz",
  "pharmi.uz",
  "polito.uz",
  "qdpi.uz",
  "samdu.uz",
  "tashgiv.uz",
  "tayi.uz",
  "tdiu.uz",
  "tdtu.uz",
  "tfi.uz",
  "tiim.uz",
  "tiu.uz",
  "tma.uz",
  "tuit.uz",
  "uwed.uz",
  "uzswlu.uz",
  "wiut.uz",
  "luz.ve",
  "uba.edu.ve",
  "ubv.edu.ve",
  "ucab.edu.ve",
  "ucat.edu.ve",
  "uc.edu.ve",
  "ucla.edu.ve",
  "ucv.ve",
  "udo.edu.ve",
  "uft.edu.ve",
  "ugma.edu.ve",
  "ujap.edu.ve",
  "ujmv.edu",
  "ula.ve",
  "una.edu.ve",
  "une.edu.ve",
  "unefa.edu.ve",
  "unefm.edu.ve",
  "uneg.edu.ve",
  "unellez.edu.ve",
  "unerg.edu.ve",
  "unermb.edu.ve",
  "unesr.edu.ve",
  "unesur.edu.ve",
  "unet.edu.ve",
  "unexpo.edu.ve",
  "unica.edu.ve",
  "unimet.edu.ve",
  "unitec.edu.ve",
  "universidad-monteavila.edu.ve",
  "upel.edu.ve",
  "urbe.edu",
  "uru.edu",
  "usb.ve",
  "usm.trompo.com",
  "uvm.edu.ve",
  "yacambu.edu.ve",
  "bdu.edu.vn",
  "buh.edu.vn",
  "ctu.edu.vn",
  "dct.udn.vn",
  "dhxd.edu.vn",
  "dlu.edu.vn",
  "fpt.edu.vn",
  "ftu.edu.vn",
  "hau.edu.vn",
  "hcmuaf.edu.vn",
  "hcmuarc.edu.vn",
  "hcmulaw.edu.vn",
  "hcmuns.edu.vn",
  "hcmupeda.edu.vn",
  "hcmussh.edu.vn",
  "hcmut.edu.vn",
  "hcmute.edu.vn",
  "hcmutrans.edu.vn",
  "hmu.edu.vn",
  "hou.edu.vn",
  "hua.edu.vn",
  "huaf.edu.vn",
  "hueuni.edu.vn",
  "huflit.vnn.vn",
  "humg.edu.vn",
  "hus.edu.vn",
  "hut.edu.vn",
  "hvtc.edu.vn",
  "hwru.edu.vn",
  "ktkt-haiduong.edu.vn",
  "neu.edu.vn",
  "ou.edu.vn",
  "ptit.edu.vn",
  "rmit.edu.vn",
  "sgu.edu.vn",
  "taynguyenuni.edu.vn",
  "tuaf.edu.vn",
  "uct.edu.vn",
  "ud.edu.vn",
  "ueh.edu.vn",
  "vimaru.edu.vn",
  "vnu.edu.vn",
  "vnuhcm.edu.vn",
  "yds.edu.vn",
  "pnt.edu.vn",
  "vgu.edu.vn",
  "uvi.edu",
  "adenuniversity.edu.ye",
  "ahgaff.edu",
  "al-edu.com",
  "arwauniversity.org",
  "eman-univ.edu.ye",
  "hoduniv.net.ye",
  "hust.edu.ye",
  "sabauni.net",
  "su.edu.ye",
  "taizun.net",
  "thuniv.net",
  "ust.edu",
  "yuniv.net",
  "cavendishza.org",
  "cbu.edu.zm",
  "mu.ac.zm",
  "rusangu-university.edu.zm",
  "unilus.ac.zm",
  "unza.zm",
  "zaou.ac.zm",
  "africau.edu",
  "buse.ac.zw",
  "cut.ac.zw",
  "cuz.ac.zw",
  "gzu.ac.zw",
  "hit.ac.zw",
  "lsu.ac.zw",
  "msu.ac.zw",
  "nust.ac.zw",
  "rcu.ac.zw",
  "solusi.ac.zw",
  "uz.ac.zw",
  "wua.ac.zw",
  "zegu.ac.zw",
  "zou.ac.zw",
  "agu.edu.tr",
  "adanabtu.edu.tr",
  "adiyaman.edu.tr",
  "agri.edu.tr",
  "aksaray.edu.tr",
  "alanyaaku.edu.tr",
  "amasya.edu.tr",
  "asbu.edu.tr",
  "ardahan.edu.tr",
  "artvin.edu.tr",
  "bandirma.edu.tr",
  "bartin.edu.tr",
  "batman.edu.tr",
  "bayburt.edu.tr",
  "bingol.edu.tr",
  "beu.edu.tr",
  "bozok.edu.tr",
  "btu.edu.tr",
  "karatekin.edu.tr",
  "duzce.edu.tr",
  "ebyu.edu.tr",
  "erzurum.edu.tr",
  "gtu.edu.tr",
  "gata.edu.tr",
  "gumushane.edu.tr",
  "hakkari.edu.tr",
  "hho.edu.tr",
  "hitit.edu.tr",
  "igdir.edu.tr",
  "iste.edu.tr",
  "medeniyet.edu.tr",
  "istanbul.edu.tr",
  "ogr.iu.edu.tr",
  "ikc.edu.tr",
  "ikcu.edu.tr",
  "karabuk.edu.tr",
  "kmu.edu.tr",
  "kastamonu.edu.tr",
  "kirklareli.edu.tr",
  "artuklu.edu.tr",
  "mehmetakif.edu.tr",
  "msgsu.edu.tr",
  "alparslan.edu.tr",
  "nevsehir.edu.tr",
  "osmaniye.edu.tr",
  "erdogan.edu.tr",
  "siirt.edu.tr",
  "sinop.edu.tr",
  "sirnak.edu.tr",
  "tunceli.edu.tr",
  "sbu.edu.tr",
  "usak.edu.tr",
  "beun.edu.tr",
  "acibadem.edu.tr",
  "ahap.edu.tr",
  "avrasya.edu.tr",
  "bezmialem.edu.tr",
  "biruni.edu.tr",
  "fatihsultan.edu.tr",
  "gedik.edu.tr",
  "hku.edu.tr",
  "29mayis.edu.tr",
  "aydin.edu.tr",
  "esenyurt.edu.tr",
  "gelisim.edu.tr",
  "kemerburgaz.edu.tr",
  "medipol.edu.tr",
  "rumeli.edu.tr",
  "iszu.edu.tr",
  "ieu.edu.tr",
  "izmirekonomi.edu.tr",
  "karatay.edu.tr",
  "gidatarim.edu.tr",
  "mef.edu.tr",
  "nisantasi.edu.tr",
  "nny.edu.tr",
  "okan.edu.tr",
  "pirireis.edu.tr",
  "sanko.edu.tr",
  "tedu.edu.tr",
  "toros.edu.tr",
  "thk.edu.tr",
  "antalya.edu.tr",
  "uskudar.edu.tr",
  "yeniyuzyil.edu.tr",
  "yuksekihtisasuniversitesi.edu.tr",
  "ascc.edu",
  "bscc.edu",
  "bishop.edu",
  "cacc.edu",
  "cv.edu",
  "duke.edu",
  "escc.edu",
  "gadsdenstate.edu",
  "wallace.edu",
  "wallacestate.edu",
  "wccs.edu",
  "trenholmstate.edu",
  "faulknerstate.edu",
  "jdcc.edu",
  "jeffstateonline.com",
  "drakestate.edu",
  "calhoun.edu",
  "lbwcc.edu",
  "marionmilitary.edu",
  "nacc.edu",
  "nwscc.edu",
  "sheltonstate.edu",
  "snead.edu",
  "suscc.edu",
  "ilisagvik.edu",
  "pwsc.alaska.edu",
  "centralaz.edu",
  "cgc.maricopa.edu",
  "cochise.edu",
  "coconino.edu",
  "eac.edu",
  "gatewaycc.edu",
  "gccaz.edu",
  "mesacc.edu",
  "maricopa.edu",
  "mohave.edu",
  "npc.edu",
  "pvc.maricopa.edu",
  "phoenixcollege.edu",
  "pima.edu",
  "rio.maricopa.edu",
  "scottsdalecc.edu",
  "southmountaincc.edu",
  "tocc.edu",
  "yc.edu",
  "anc.edu",
  "asub.edu",
  "asumh.edu",
  "asun.edu",
  "blackrivertech.edu",
  "coto.edu",
  "cccua.edu",
  "www.eacc.edu",
  "midsouthcc.edu",
  "npcc.edu",
  "northark.edu",
  "nwacc.edu",
  "ozarka.edu",
  "pccua.edu",
  "pulaskitech.edu",
  "rmcc.edu",
  "southark.edu",
  "seark.edu",
  "sautech.edu",
  "uaccb.edu",
  "uacch.edu",
  "uaccm.edu",
  "hancockcollege.edu",
  "arc.losrios.edu",
  "avc.edu",
  "bakersfieldcollege.edu",
  "barstow.edu",
  "berkeleycitycollege.edu",
  "butte.edu",
  "cabrillo.edu",
  "canadacollege.edu",
  "carrington.edu",
  "cerritos.edu",
  "cerrocoso.edu",
  "chabotcollege.edu",
  "chaffey.edu",
  "citruscollege.edu",
  "ccsf.edu",
  "coastline.edu",
  "peralta.edu",
  "marin.edu",
  "collegeofsanmateo.edu",
  "canyons.edu",
  "collegeofthedesert.edu",
  "redwoods.edu",
  "cos.edu",
  "siskiyous.edu",
  "ccis.edu",
  "gocolumbia.edu",
  "contracosta.edu",
  "cmccd.edu",
  "crc.losrios.edu",
  "craftonhills.edu",
  "www.cuesta.edu",
  "cuyamaca.edu",
  "cypresscollege.edu",
  "deanza.edu",
  "dvc.edu",
  "elac.edu",
  "compton.edu",
  "elcamino.edu",
  "evc.edu",
  "frc.edu",
  "flc.losrios.edu",
  "foothill.edu",
  "fresnocitycollege.edu",
  "fullcoll.edu",
  "gavilan.edu",
  "glendale.edu",
  "goldenwestcollege.edu",
  "grossmont.edu",
  "hartnell.edu",
  "imperial.edu",
  "ltcc.edu",
  "laney.edu",
  "laspositascollege.edu",
  "lassencollege.edu",
  "lbcc.edu",
  "lacitycollege.edu",
  "ladhs.org",
  "lahc.edu",
  "lamission.edu",
  "piercecollege.edu",
  "lasc.edu",
  "lattc.edu",
  "lavc.edu",
  "losmedanos.edu",
  "mendocino.edu",
  "mccd.edu",
  "merritt.edu",
  "miracosta.edu",
  "missioncollege.edu",
  "mjc.edu",
  "mpc.edu",
  "moorparkcollege.edu",
  "mvc.edu",
  "sacramento.mticollege.edu",
  "mtsac.edu",
  "msjc.edu",
  "napavalley.edu",
  "ohlone.edu",
  "orangecoastcollege.edu",
  "oxnardcollege.edu",
  "palomar.edu",
  "paloverde.edu",
  "pasadena.edu",
  "portervillecollege.edu",
  "reedleycollege.edu",
  "riohondo.edu",
  "rcc.edu",
  "scc.losrios.edu",
  "saddleback.edu",
  "valleycollege.edu",
  "sdcity.edu",
  "student.sdccd.edu",
  "sdmesa.edu",
  "student.sdccd.edu",
  "sdmiramar.edu",
  "student.sdccd.edu",
  "deltacollege.edu",
  "sjvc.edu",
  "sjcc.edu",
  "sac.edu",
  "sbcc.edu",
  "smc.edu",
  "santarosa.edu",
  "sccollege.edu",
  "shastacollege.edu",
  "sierracollege.edu",
  "skylinecollege.edu",
  "solano.edu",
  "swccd.edu",
  "taftcollege.edu",
  "venturacollege.edu",
  "vvc.edu",
  "westhillscollege.com",
  "wlac.edu",
  "westvalley.edu",
  "wcc.yccd.edu",
  "yc.yccd.edu",
  "aims.edu",
  "arapahoe.edu",
  "cncc.edu",
  "ccaurora.edu",
  "ccd.edu",
  "frontrange.edu",
  "lamarcc.edu",
  "morgancc.edu",
  "njc.edu",
  "www.ojc.edu",
  "ppcc.edu",
  "pueblocc.edu",
  "rrcc.edu",
  "trinidadstate.edu",
  "asnuntuck.edu",
  "ccc.commnet.edu",
  "gwcc.commnet.edu",
  "hcc.commnet.edu",
  "mcc.commnet.edu",
  "mxcc.commnet.edu",
  "nv.edu",
  "nwcc.commnet.edu",
  "ncc.commnet.edu",
  "qvcc.edu",
  "trcc.commnet.edu",
  "tunxis.edu",
  "dcad.edu",
  "dtcc.edu",
  "fkcc.edu",
  "hccfl.edu",
  "nfcc.edu",
  "phcc.edu",
  "tcc.fl.edu",
  "albanytech.edu",
  "andrewcollege.edu",
  "athenstech.edu",
  "atlantatech.edu",
  "augustatech.edu",
  "bainbridge.edu",
  "centralgatech.edu",
  "chattahoocheetech.edu",
  "columbustech.edu",
  "darton.edu",
  "highlands.edu",
  "gmc.cc.ga.us",
  "gntc.edu",
  "gpc.edu",
  "gptc.edu",
  "gwinnetttech.edu",
  "laniertech.edu",
  "moultrietech.edu",
  "northgatech.edu",
  "ogeecheetech.edu",
  "coastalpines.edu",
  "savannahtech.edu",
  "southeasterntech.edu",
  "sctech.edu",
  "southgatech.edu",
  "southwestgatech.edu",
  "westgatech.edu",
  "wiregrass.edu",
  "hawaii.hawaii.edu",
  "honolulu.hawaii.edu",
  "kapiolani.hawaii.edu",
  "kauai.hawaii.edu",
  "leeward.hawaii.edu",
  "windward.hawaii.edu",
  "csi.edu",
  "cwidaho.cc",
  "eitc.edu",
  "nic.edu",
  "bhc.edu",
  "sandburg.edu",
  "ccc.edu",
  "dupage.edu",
  "cod.edu",
  "www.clcillinois.edu",
  "dacc.edu",
  "elgin.edu",
  "foxcollege.edu",
  "harpercollege.edu",
  "heartland.edu",
  "highland.edu",
  "icc.edu",
  "ivcc.edu",
  "jalc.edu",
  "jwcc.edu",
  "jjc.edu",
  "kcc.edu",
  "kaskaskia.edu",
  "kishwaukeecollege.edu",
  "lakeland.cc.il.us",
  "chefs.edu",
  "lc.edu",
  "llcc.edu",
  "maccormac.edu",
  "mchenry.edu",
  "morainevalley.edu",
  "morton.edu",
  "northwesterncollege.edu",
  "oakton.edu",
  "parkland.edu",
  "prairiestate.edu",
  "rlc.edu",
  "richland.edu",
  "rockvalleycollege.edu",
  "svcc.edu",
  "shawneecc.edu",
  "sic.edu",
  "ssc.edu",
  "swic.edu",
  "src.edu",
  "triton.edu",
  "ancilla.edu",
  "ivytech.edu",
  "dmacc.edu",
  "eicc.edu",
  "hawkeyecollege.edu",
  "indianhills.edu",
  "iowacentral.edu",
  "iowalakes.edu",
  "iwcc.edu",
  "kirkwood.edu",
  "iavalley.edu",
  "nicc.edu",
  "niacc.edu",
  "nwicc.edu",
  "scciowa.edu",
  "swcciowa.edu",
  "witcc.edu",
  "allencc.edu",
  "bartonccc.edu",
  "butlercc.edu",
  "cloud.edu",
  "coffeyville.edu",
  "colbycc.edu",
  "cowley.edu",
  "dc3.edu",
  "fhtc.edu",
  "fortscott.edu",
  "gcccks.edu",
  "highlandcc.edu",
  "hutchcc.edu",
  "indycc.edu",
  "jccc.edu",
  "kckcc.edu",
  "labette.edu",
  "manhattantech.edu",
  "neosho.edu",
  "ncktc.edu",
  "nwktc.edu",
  "prattcc.edu",
  "sccc.edu",
  "watc.edu",
  "ashland.kctcs.edu",
  "bigsandy.kctcs.edu",
  "bluegrass.kctcs.edu",
  "elizabethtown.kctcs.edu",
  "galencollege.edu",
  "gateway.kctcs.edu",
  "hazard.kctcs.edu",
  "hencc.kctcs.edu",
  "hopkinsville.kctcs.edu",
  "jefferson.kctcs.edu",
  "madisonville.kctcs.edu",
  "maysville.kctcs.edu",
  "owensboro.kctcs.edu",
  "somerset.kctcs.edu",
  "bowlinggreen.kctcs.edu",
  "southeast.kctcs.edu",
  "westkentucky.kctcs.edu",
  "mybrcc.edu",
  "bpcc.edu",
  "dcc.edu",
  "fletcher.edu",
  "ladelta.edu",
  "lsue.edu",
  "nunez.edu",
  "rpcc.edu",
  "susla.edu",
  "solacc.edu",
  "sowela.edu",
  "cmcc.edu",
  "cmmccollege.edu",
  "emcc.edu",
  "kvcc.me.edu",
  "nmcc.edu",
  "smccme.edu",
  "wccc.me.edu",
  "yccc.edu",
  "allegany.edu",
  "bccc.edu",
  "carrollcc.edu",
  "cecil.edu",
  "csmd.edu",
  "garrettcollege.edu",
  "pgcc.edu",
  "worwic.edu",
  "berkshirecc.edu",
  "bristolcc.edu",
  "bhcc.mass.edu",
  "capecod.edu",
  "gcc.mass.edu",
  "hcc.edu",
  "massbay.edu",
  "massasoit.mass.edu",
  "massasoit.edu",
  "middlesex.mass.edu",
  "mwcc.edu",
  "necc.edu",
  "necc.mass.edu",
  "northshore.edu",
  "quincycollege.edu",
  "qcc.edu",
  "rcc.mass.edu",
  "stcc.edu",
  "urbancollege.edu",
  "alpenacc.edu",
  "baycollege.edu",
  "bmcc.edu",
  "delta.edu",
  "glenoaks.cc.mi.us",
  "gogebic.edu",
  "grcc.edu",
  "hfcc.edu",
  "jccmi.edu",
  "kvcc.edu",
  "kellogg.edu",
  "kbocc.org",
  "kirtland.edu",
  "lakemichigancollege.edu",
  "lcc.edu",
  "macomb.edu",
  "midmich.edu",
  "monroeccc.edu",
  "montcalm.edu",
  "muskegoncc.edu",
  "ncmich.edu",
  "oaklandcc.edu",
  "sagchip.edu",
  "schoolcraft.edu",
  "swmich.edu",
  "sc4.edu",
  "wccnet.edu",
  "wcccd.edu",
  "westshore.edu",
  "alextech.edu",
  "anokaramsey.edu",
  "anokatech.edu",
  "www.clcmn.edu",
  "century.edu",
  "dctc.edu",
  "fdltcc.edu",
  "hennepintech.edu",
  "hibbing.edu",
  "inverhills.edu",
  "itascacc.edu",
  "lltc.edu",
  "mesabirange.edu",
  "minneapolis.edu",
  "southeastmn.edu",
  "minnesota.edu",
  "mnwest.edu",
  "normandale.edu",
  "nhcc.edu",
  "northlandcollege.edu",
  "ntcmn.edu",
  "pinetech.edu",
  "rrcc.mnscu.edu",
  "ridgewater.edu",
  "riverland.edu",
  "rctc.edu",
  "saintpaul.edu",
  "southcentral.edu",
  "sctcc.edu",
  "vcc.edu",
  "wetcc.edu",
  "coahomacc.edu",
  "colin.edu",
  "students.olin.edu",
  "eccc.edu",
  "eastms.edu",
  "hindscc.edu",
  "holmescc.edu",
  "iccms.edu",
  "jcjc.edu",
  "meridiancc.edu",
  "msdelta.edu",
  "mgccc.edu",
  "nemcc.edu",
  "northwestms.edu",
  "prcc.edu",
  "smcc.edu",
  "crowder.edu",
  "eastcentral.edu",
  "jeffco.edu",
  "linnstate.edu",
  "mineralarea.edu",
  "missouristate.edu",
  "wp.missouristate.edu",
  "macc.edu",
  "ncmissouri.edu",
  "otc.edu",
  "southeastmissourihospitalcollege.edu",
  "sfccmo.edu",
  "stchas.edu",
  "trcc.edu",
  "wma.edu",
  "ancollege.edu",
  "bfcc.edu",
  "cdkc.edu",
  "dawson.edu",
  "fvcc.edu",
  "fpcc.edu",
  "gfcmsu.edu",
  "umhelena.edu",
  "lbhc.edu",
  "milescc.edu",
  "stonechild.edu",
  "cccneb.edu",
  "littlepriest.edu",
  "mpcc.edu",
  "ncta.unl.edu",
  "thenicc.edu",
  "northeast.edu",
  "southeast.edu",
  "wncc.edu",
  "tmcc.edu",
  "greatbay.edu",
  "lrcc.edu",
  "mccnh.edu",
  "nashuacc.edu",
  "nhti.edu",
  "rivervalley.edu",
  "wmcc.edu",
  "acs350.org",
  "atlantic.edu",
  "bergen.edu",
  "brookdalecc.edu",
  "camdencc.edu",
  "ccm.edu",
  "student.ccm.edu",
  "cccnj.edu",
  "essex.edu",
  "gccnj.edu",
  "hccc.edu",
  "middlesexcc.edu",
  "ocean.edu",
  "pccc.edu",
  "raritanval.edu",
  "salemcc.edu",
  "sussex.edu",
  "ucc.edu",
  "warren.edu",
  "cnm.edu",
  "clovis.edu",
  "roswell.enmu.edu",
  "luna.edu",
  "mesalands.edu",
  "nmjc.edu",
  "nmmi.edu",
  "nmsua.edu",
  "cavern.nmsu.edu",
  "dabcc.nmsu.edu",
  "sanjuancollege.edu",
  "sfcc.edu",
  "sipi.edu",
  "www.sunyacc.edu",
  "aada.edu",
  "asa.edu",
  "cayuga-cc.edu",
  "clinton.edu",
  "sunycgcc.edu",
  "bmcc.cuny.edu",
  "bcc.cuny.edu",
  "hostos.cuny.edu",
  "kbcc.cuny.edu",
  "lagcc.cuny.edu",
  "qcc.cuny.edu",
  "baruch.cuny.edu",
  "qc.cuny.edu",
  "citytech.cuny.edu",
  "ccny.cuny.edu",
  "brooklyn.cuny.edu",
  "mec.cuny.edu",
  "csi.cuny.edu",
  "macauly.cuny.edu",
  "sunydutchess.edu",
  "flcc.edu",
  "fmcc.edu",
  "genesee.edu",
  "herkimer.edu",
  "hvcc.edu",
  "sunyjcc.edu",
  "sunyjefferson.edu",
  "mvcc.edu",
  "monroecc.edu",
  "ncc.edu",
  "niagaracc.suny.edu",
  "nccc.edu",
  "sunyocc.edu",
  "sunyorange.edu",
  "sunyrockland.edu",
  "secon.edu",
  "sunysccc.edu",
  "www3.sunysuffolk.edu",
  "sunysullivan.edu",
  "www.sunybroome.edu",
  "sunywcc.edu",
  "tcicollege.edu",
  "tc3.edu",
  "www.sunyulster.edu",
  "alamancecc.edu",
  "abtech.edu",
  "beaufortccc.edu",
  "bladencc.edu",
  "blueridge.edu",
  "brunswickcc.edu",
  "cccti.edu",
  "cfcc.edu",
  "carolinascollege.edu",
  "carteret.edu",
  "cvcc.edu",
  "cccc.edu",
  "cpcc.edu",
  "clevelandcc.edu",
  "coastalcarolina.edu",
  "albemarle.edu",
  "cravencc.edu",
  "davidsonccc.edu",
  "durhamtech.edu",
  "edgecombe.edu",
  "faytechcc.edu",
  "forsythtech.edu",
  "gaston.edu",
  "gtcc.edu",
  "halifaxcc.edu",
  "haywood.edu",
  "isothermal.edu",
  "jamessprunt.edu",
  "johnstoncc.edu",
  "louisburg.edu",
  "martincc.edu",
  "mayland.edu",
  "mcdowelltech.edu",
  "mitchellcc.edu",
  "montgomery.edu",
  "nashcc.edu",
  "pamlicocc.edu",
  "piedmontcc.edu",
  "pittcc.edu",
  "randolph.edu",
  "richmondcc.edu",
  "roanokechowan.edu",
  "robeson.edu",
  "rockinghamcc.edu",
  "rccc.edu",
  "sampsoncc.edu",
  "sandhills.edu",
  "sccnc.edu",
  "spcc.edu",
  "southwesterncc.edu",
  "stanly.edu",
  "surry.edu",
  "tricountycc.edu",
  "waketech.edu",
  "waynecc.edu",
  "wpcc.edu",
  "wilkescc.edu",
  "wilsoncc.edu",
  "littlehoop.edu",
  "dakotacollege.edu",
  "lrsc.edu",
  "ndscs.edu",
  "willistonstate.edu",
  "belmontcollege.edu",
  "cotc.edu",
  "chatfield.edu",
  "cincinnatistate.edu",
  "clarkstate.edu",
  "cscc.edu",
  "tri-c.edu",
  "daviscollege.edu",
  "egcc.edu",
  "edisonohio.edu",
  "hocking.edu",
  "rhodesstate.edu",
  "lakelandcc.edu",
  "lorainccc.edu",
  "mtc.edu",
  "ncstatecollege.edu",
  "northweststate.edu",
  "ati.osu.edu",
  "owens.edu",
  "sscc.edu",
  "starkstate.edu",
  "terra.edu",
  "wayne.uakron.edu",
  "wscc.edu",
  "zanestate.edu",
  "carlalbert.edu",
  "connorsstate.edu",
  "eosc.edu",
  "mscok.edu",
  "neo.edu",
  "noc.edu",
  "occc.edu",
  "redlandscc.edu",
  "rose.edu",
  "sscok.edu",
  "tulsacc.edu",
  "wosc.edu",
  "bluecc.edu",
  "cocc.edu",
  "chemeketa.edu",
  "clackamas.edu",
  "clatsopcc.edu",
  "cgcc.cc.or.us",
  "klamathcc.edu",
  "lanecc.edu",
  "linnbenton.edu",
  "mhcc.edu",
  "roguecc.edu",
  "socc.edu",
  "tbcc.cc.or.us",
  "tvcc.cc",
  "umpqua.edu",
  "bucks.edu",
  "bc3.edu",
  "ccac.edu",
  "ccbc.edu",
  "ccp.edu",
  "dccc.edu",
  "harcum.edu",
  "hacc.edu",
  "lackawanna.edu",
  "lccc.edu",
  "luzerne.edu",
  "manor.edu",
  "mc3.edu",
  "pennhighlands.edu",
  "pit.edu",
  "pti.edu",
  "racc.edu",
  "stevenscollege.edu",
  "upt.pitt.edu",
  "vfmac.edu",
  "wccc.edu",
  "ccri.edu",
  "cctech.edu",
  "denmarktech.edu",
  "www.fdtc.edu",
  "gvltec.edu",
  "hgtc.edu",
  "midlandstech.edu",
  "netc.edu",
  "octech.edu",
  "ptc.edu",
  "sccsc.edu",
  "smcsc.edu",
  "tcl.edu",
  "tctc.edu",
  "tridenttech.edu",
  "wiltech.edu",
  "yorktech.edu",
  "kilian.edu",
  "lakeareatech.edu",
  "mitchelltech.edu",
  "swc.tc",
  "southeasttech.edu",
  "wdt.edu",
  "chattanoogastate.edu",
  "clevelandstatecc.edu",
  "columbiastate.edu",
  "dscc.edu",
  "jscc.edu",
  "guptoncollege.edu",
  "mscc.edu",
  "northeaststate.edu",
  "pstcc.edu",
  "roanestate.edu",
  "southwest.tn.edu",
  "volstate.edu",
  "ws.edu",
  "alvincollege.edu",
  "actx.edu",
  "angelina.edu",
  "blinn.edu",
  "brookhavencollege.edu",
  "cedarvalleycollege.edu",
  "ctcd.edu",
  "schreiner.edu",
  "cisco.edu",
  "clarendoncollege.edu",
  "coastalbend.edu",
  "com.edu",
  "collin.edu",
  "delmar.edu",
  "eastfieldcollege.edu",
  "elcentrocollege.edu",
  "epcc.edu",
  "fpctx.edu",
  "gc.edu",
  "grayson.edu",
  "hillcollege.edu",
  "hccs.edu",
  "howardcollege.edu",
  "jacksonville-college.edu",
  "kilgore.edu",
  "lit.edu",
  "lsco.edu",
  "lamarpa.edu",
  "laredo.edu",
  "lee.edu",
  "lonestar.edu",
  "mclennan.edu",
  "navarrocollege.edu",
  "nctc.edu",
  "ntcc.edu",
  "northlakecollege.edu",
  "odessa.edu",
  "alamo.edu",
  "panola.edu",
  "parisjc.edu",
  "rangercollege.edu",
  "richlandcollege.edu",
  "southplainscollege.edu",
  "swtjc.net",
  "templejc.edu",
  "texarkanacollege.edu",
  "harlingen.tstc.edu",
  "marshall.tstc.edu",
  "waco.tstc.edu",
  "westtexas.tstc.edu",
  "tvcc.edu",
  "www.tjc.edu",
  "vernoncollege.edu",
  "victoriacollege.edu",
  "wc.edu",
  "wtc.edu",
  "wcjc.edu",
  "ldsbc.edu",
  "slcc.edu",
  "ccv.edu",
  "brcc.edu",
  "cvcc.vccs.edu",
  "dslcc.edu",
  "dcc.vccs.edu",
  "es.vccs.edu",
  "germanna.edu",
  "jtcc.edu",
  "reynolds.edu",
  "lfcc.edu",
  "mecc.edu",
  "nr.edu",
  "nvcc.edu",
  "patrickhenry.edu",
  "pdc.edu",
  "pvcc.edu",
  "rappahannock.edu",
  "rbc.edu",
  "southside.edu",
  "sw.edu",
  "tncc.edu",
  "tcc.edu",
  "vhcc.edu",
  "virginiawestern.edu",
  "wcc.vccs.edu",
  "bates.ctc.edu",
  "btc.ctc.edu",
  "bigbend.edu",
  "cascadia.edu",
  "clark.edu",
  "cptc.edu",
  "edcc.edu",
  "everettcc.edu",
  "ghc.edu",
  "greenriver.edu",
  "highline.edu",
  "lowercolumbia.edu",
  "pierce.ctc.edu",
  "rtc.edu",
  "shoreline.edu",
  "skagit.edu",
  "spscc.ctc.edu",
  "scc.spokane.edu",
  "spokanefalls.edu",
  "tacomacc.edu",
  "wwcc.edu",
  "wvc.edu",
  "whatcom.ctc.edu",
  "yvcc.edu",
  "blueridgectc.edu",
  "bridgemont.edu",
  "eastern.wvnet.edu",
  "huntingtonjuniorcollege.edu",
  "kvctc.edu",
  "mctc.edu",
  "newriver.edu",
  "pierpont.edu",
  "southernwv.edu",
  "wvncc.edu",
  "blackhawk.edu",
  "cvtc.edu",
  "fvtc.edu",
  "gtc.edu",
  "lco.edu",
  "gotoltc.edu",
  "madisoncollege.edu",
  "mstc.edu",
  "matc.edu",
  "morainepark.edu",
  "nicoletcollege.edu",
  "ntc.edu",
  "nwtc.edu",
  "swtc.edu",
  "uwc.edu",
  "wctc.edu",
  "westerntc.edu",
  "witc.edu",
  "caspercollege.edu",
  "cwc.edu",
  "ewc.wy.edu",
  "lccc.wy.edu",
  "northwestcollege.edu",
  "sheridan.edu",
  "wwcc.wy.edu",
  "freeuni.edu.ge",
  "ui1.es",
  "alumnos.ui1.es",
  "enae.es",
  "viu.es",
  "dhbw.de",
  "heidenheim.dhbw.de",
  "cas.dhbw.de",
  "dhbw-loerrach.de",
  "dhbw-mosbach.de",
  "mosbach.dhbw.de",
  "ravensburg.dhbw.de",
  "dhbw-stuttgart.de",
  "dhbw-vs.de",
  "heilbronn.dhbw.de",
  "dhbw-karlsruhe.de",
  "karlsruhe.dhbw.de",
  "dhbw-mannheim.de",
  "mannheim.dhbw.de",
  "hs-hannover.de",
  "stud.hs-hannover.de",
  "ba-bautzen.de",
  "trinitybiblecollege.edu",
  "umflint.edu",
  "torrens.edu.au",
  "tua.edu.au",
  "mmibordeaux.com",
  "campus-saint-marc.com",
  "csmrouen.com",
  "csmrouen.net",
  "btu.ge",
  "btu.edu.ge",
  "cic-cairo.com",
  "kpc.alaska.edu",
  "matsu.alaska.edu",
  "koc.alaska.edu",
  "alaskapacific.edu",
  "wbu.edu",
  "hust.edu.vn",
  "bhtafe.edu.au",
  "boxhill.edu.au",
  "linuwel.nsw.edu.au",
  "cust.edu.cn",
  "ieec.cust.edu.cn",
  "polk.edu",
  "mohawkcollege.ca",
  "bdeb.qc.ca",
  "brescia.edu",
  "rsu.edu",
  "neumann.edu",
  "scad.edu",
  "student.scad.edu",
  "fanshawec.ca",
  "fanshaweonline.ca",
  "ashford.edu",
  "mcpherson.edu",
  "ccsnh.edu",
  "vaniercollege.qc.ca",
  "defiance.edu",
  "musc.edu",
  "gulfcoast.edu",
  "stu.gcu.edu.cn",
  "xhu.edu.cn",
  "stu.xhu.edu.cn",
  "mail.xhu.edu.cn",
  "academyart.edu",
  "art.edu",
  "lr.edu",
  "utfpr.edu.br",
  "nuist.edu.cn",
  "lynu.edu.cn",
  "pointpark.edu",
  "ontariotechu.ca",
  "ontariotechu.net",
  "torontomu.ca",
  "collegeuniversel.ca",
  "hsu.edu.hk",
  "htl-villach.at",
  "edu.htl-villach.at",
  "htl-wels.at",
  "udb.ac.id",
  "jedlik.eu",
  "students.jedlik.eu",
  "northwood.edu",
  "infnet.edu.br",
  "al.infnet.edu.br",
  "prof.infnet.edu.br",
  "ssst.edu.ba",
  "hbc.edu.cn",
  "thm.de",
  "hwg-lu.de",
  "iiitm.ac.in",
  "leuphana.de",
  "oth-aw.de",
  "fatecie.edu.br",
  "hamk.fi",
  "ktun.edu.tr",
  "uet.vnu.edu.vn",
  "uni-corvinus.hu",
  "uni-obuda.hu",
  "uni-bge.hu",
  "ufn.edu.br",
  "iitbbs.ac.in",
  "srmap.edu.in",
  "sciencespo-rennes.fr",
  "rennes-sb.com",
  "cau.edu",
  "morehouse.edu",
  "audencia.com",
  "bakircay.edu.tr",
  "idu.edu.tr",
  "tinaztepe.edu.tr",
  "usa.edu",
  "gmercyu.edu",
  "calarts.edu",
  "dmu.edu",
  "utahtech.edu",
  "midwestern.edu",
  "iwu.edu",
  "maryvillecollege.edu",
  "endicott.edu",
  "ua.edu",
  "gbcnv.edu",
  "einsteinmed.edu",
  "mdc.edu",
  "fsw.edu",
  "bmhs.org",
  "chaminade.edu",
  "daltonstate.edu",
  "ojaiusd.org",
  "bismarckstate.edu",
  "quadratacademy.com",
  "una.edu",
  "kiet.edu",
  "wakefield.ac.uk",
  "student.uliege.be",
  "helpnet.uliege.be",
  "ddi.uliege.be",
  "uliege.be",
  "student.spsmb.cz",
  "spsmb.cz",
  "dnui.edu.cn",
  "neusoft.edu.cn",
  "mymail.mapua.edu.ph",
  "mapua.edu.ph",
  "faculdadedombosco.edu.br",
  "mnsuet.edu.pk",
  "bellevuecollege.edu",
  "kec.edu.np",
  "uninove.br",
  "uni9.edu.br",
  "upe.poli.br",
  "ecomp.poli.br",
  "poli.br",
  "mh-luebeck.de",
  "musikhochschule-muenchen.de",
  "hmtm.de",
  "udk-berlin.de",
  "hfmt-koeln.de",
  "hfmdd.de",
  "hmdk-stuttgart.de",
  "hfk-bremen.de",
  "hfk2020.de",
  "hmt-leipzig.de",
  "hmtm-hannover.de",
  "hfm-detmold.de",
  "ac-orleans-tours.fr",
  "uni-speyer.de",
  "europa-uni.de",
  "ac-besancon.fr",
  "lehman.cuny.edu",
  "jjay.cuny.edu",
  "hunter.cuny.edu",
  "york.cuny.edu",
  "lis.ac.uk",
  "uca.fr",
  "harrisburg.psu.edu",
  "esade.edu",
  "iese.edu",
  "uclouvain.be",
  "exed.solvay.edu",
  "hult.edu",
  "vlerick.com",
  "luiss.it",
  "alcorn.edu",
  "allenuniversity.edu",
  "abcnash.edu",
  "uapb.edu",
  "arkansasbaptist.edu",
  "b-sc.edu",
  "benedict.edu",
  "bennet.edu",
  "cookman.edu",
  "bluefieldstate.edu",
  "carver.edu",
  "centralstate.edu",
  "claflin.edu",
  "clintoncollege.edu",
  "ewc.edu",
  "fmuniv.edu",
  "fvsu.edu",
  "gadsenstate.edu",
  "gram.edu",
  "hssu.edu",
  "hoodseminary.edu",
  "htu.edu",
  "itc.edu",
  "jarvis.edu",
  "smithseminary.org",
  "kysu.edu",
  "knoxvillecollege.edu",
  "lanecollege.edu",
  "lawsonstate.edu",
  "loc.edu",
  "livingstone.edu",
  "mmc.edu",
  "miles.edu",
  "mlaw.edu",
  "msm.edu",
  "morrisbrown.edu",
  "morris.edu",
  "paine.edu",
  "paulquinn.edu",
  "philander.edu",
  "rustcollege.edu",
  "savannahstate.edu",
  "selmauniversity.edu",
  "shawu.edu",
  "simmonscollegeky.edu",
  "scsu.edu",
  "suno.edu",
  "swcc.edu",
  "st-aug.edu",
  "stillman.edu",
  "talladega.edu",
  "texascollege.edu",
  "tougaloo.edu",
  "vuu.edu",
  "vul.edu",
  "voorhees.edu",
  "wvstate.edu",
  "wileyc.edu",
  "wssu.edu",
  "student.upt.ro",
  "upt.ro",
  "msruas.ac.in",
  "skema.edu",
  "fs.de",
  "fs-students.de",
  "clsbe.lisboa.ucp.pt",
  "eada.edu",
  "imd.org",
  "insead.edu",
  "hff-muenchen.de",
  "unity.edu",
  "catawba.edu",
  "enderuncolleges.com",
  "pctu.edu.vn",
  "ac-mayotte.fr",
  "umit-tirol.at",
  "deraya.edu.eg",
  "uumail.in",
  "universitycollege.ac.in",
  "ltu.edu.tw",
  "uni-nke.hu",
  "gsfcuniversity.ac.in",
  "student.g.nwu.ac.za",
  "tudublin.ie",
  "a2.keio.jp",
  "ace.tamut.edu",
  "ais.edu.sg",
  "akfaryarsiptk.ac.id",
  "alayen.edu.iq",
  "alumnes.ub.edu",
  "aluno.cruzeirodosul.edu.br",
  "aluno.ifsp.edu.br",
  "anurag.edu.in",
  "ateneo.edu",
  "bath.edu",
  "ulster.ac.uk",
  "ulst.ac.uk",
  "belhaven.edu",
  "berry.edu",
  "bhc.edu.in",
  "bicpu.edu.in",
  "bloomfield.edu",
  "bridgew.edu",
  "bs.naist.jp",
  "buc.edu.eg",
  "cabrini.edu",
  "caldwell.edu",
  "campus.fct.unl.pt",
  "campus.udg.edu",
  "cdc.tu.edu.np",
  "cdnis.edu.hk",
  "chiba-u.jp",
  "ciqa.edu.mx",
  "college-de-france.fr",
  "cornellcollege.edu",
  "covenant.edu",
  "csu.edu",
  "csueastbay.edu",
  "csupueblo.edu",
  "panimalar.ac.in",
  "panimalarengineeringcollegechennai.ac.in",
  "rajalakshmi.org",
  "rajagiritech.edu.in",
  "stjosephs.ac.in",
  "stjosephstechnology.ac.in",
  "ssn.edu.in",
  "saveetha.ac.in",
  "svce.ac.in",
  "srmeaswari.ac.in",
  "jeppiaarcollege.org",
  "ritchennai.org",
  "saveethaengineering.com",
  "saveetha.com",
  "kcgcollege.ac.in",
  "hindustanuniv.ac.in",
  "citchennai.edu.in",
  "sairam.edu.in",
  "licet.ac.in",
  "sairamit.edu.in",
  "rmkec.ac.in",
  "rmkcet.ac.in",
  "rmd.ac.in",
  "velammal.edu.in",
  "velammalitech.edu.in",
  "prathyusha.edu.in",
  "alphagroup.edu",
  "saec.ac.in",
  "bau.edu",
  "siena.edu",
  "txwes.edu",
  "howest.be",
  "nu.edu.kz",
  "stgc.uwa.edu.au",
  "u-bourgogne.fr",
  "miva.university",
  "esmad.ipp.pt",
  "www.esmad.ipp.pt",
  "univesp.br",
  "ac-bordeaux.fr",
  "insead.edu",
  "thk.edu.tr",
  "if-pan.krakow.pl",
  "camk.edu.pl",
  "lamar.org.mx",
  "ehtp.ac.ma",
  "idti.edu",
  "baypath.edu",
  "universite-paris-saclay.fr",
  "mtu.ie",
  "uaysen.cl",
  "snuniv.ac.in",
  "asmildkloster.dk",
  "dkit.ie",
  "videndjurs.dk",
  "snow.edu",
  "scccd.edu",
  "davidsondavie.edu",
  "duytan.edu.vn",
  "berkeleycollege.edu",
  "aybu.edu.tr",
  "floridauniversitaria.es",
  "schiller.edu",
  "uenp.edu.br",
  "en.syau.edu.cn",
  "universidadean.edu.co",
  "univ-jfc.fr",
  "ifmparis.fr",
  "amsterdam.tech",
  "tecmilenio.mx",
  "hanuniversity.com",
  "gordon.ac.il",
  "eigsi.fr",
  "ist-hochschule.de",
  "greensboro.edu",
  "vvsaz.org",
  "uplearnbusinessschool.com",
  "oglethorpe.edu",
  "cambriancollege.ca",
  "dsdt.edu",
  "northcentralcollege.edu",
  "ncl-coll.ac.uk",
  "btc.ac.uk",
  "runshaw.ac.uk",
  "wsc.ac.uk",
  "umwestern.edu",
  "queens.edu",
  "lmunet.edu",
  "ostimteknik.edu.tr",
  "ankaramedipol.edu.tr",
  "ankarabilim.edu.tr",
  "thk.edu.tr",
  "omu.edu.tr",
  "ieu.edu.tr",
  "greenville.edu",
  "suny.oneonta.edu",
  "nmc.edu",
  "adrian.edu",
  "cuw.edu",
  "delval.edu",
  "dewv.edu",
  "eckerd.edu",
  "email.uagm.edu",
  "email.uscupstate.edu",
  "fairfield.edu",
  "fhda.edu",
  "g.fmarion.edu",
  "georgefox.edu",
  "grandview.edu",
  "uf3ceu.es",
  "usj.es",
  "hesperides.edu.es",
  "uneatlantico.es",
  "usms.edu.pk",
  "cusit.edu.pk",
  "cityuniversity.edu.pk",
  "cup.edu.pk",
  "tcioe.edu.np",
  "univ-rennes2.fr",
  "univ-fcomte.fr",
  "paris-sorbonne.fr",
  "univ-paris-diderot.fr",
  "parisnanterre.fr",
  "u-pec.fr",
  "unimes.fr",
  "univ-jfc.fr",
  "upmf-grenoble.fr",
  "abac.edu",
  "stallions.abac.edu",
  "capital.edu",
  "oneonta.edu",
  "spjain.org",
  "umo.edu",
  "olivetcollege.edu",
  "westminsteru.edu",
  "dom.edu",
  "gandakiuniversity.edu.np",
  "gpu.edu.np",
  "ara.ac.nz",
  "moodle.ara.ac.nz",
  "myara.ara.ac.nz",
  "u-pec.fr",
  "jisuniversity.ac.in",
  "jiscollege.ac.in",
  "nit.ac.in",
  "gnit.ac.in",
  "gnihm.ac.in",
  "gnipst.ac.in",
  "gnidsr.ac.in",
  "surtech.edu.in",
  "jisiasr.org",
  "jismsr.org",
  "jisiop.org",
  "jissp.ac.in",
  "gkcem.ac.in",
  "chandilpolytechnic.org",
  "golapolytechnic.org",
  "aecwb.edu.in",
  "claremont.edu",
  "rcbc.edu",
]);
