import { Avatar, Button, Drawer, Dropdown, Layout, Statistic } from "antd";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../auth";
import "./default.css";

import { UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import rehypeRaw from "rehype-raw";
// @ts-ignore
import heading from "mdast-util-heading-range";
import { toString } from "mdast-util-to-string/lib";

import config from "../config";

const { Header } = Layout;
const { Countdown } = Statistic;

export default function HeaderDTL() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [showLogOut, setShowLogOut] = React.useState<boolean>(false);
  const [showReadme, setShowReadme] = React.useState<boolean>(false);
  useNavigate();
  const [readmeContent, setReadmeContent] = React.useState<string>("");

  const contestStartDate = auth.contestStartDate;
  const contestEndDate: any = auth.contestEndDate;

  useEffect(() => {}, []);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="btn-header">
          <div className="btn-group">
            <Button
              onClick={() =>
                auth.signout(
                  () => {},
                  () => {
                    console.log("logout");
                  },
                )
              }
            >
              Logout
            </Button>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetch(`${config.API_BASE_URL}/django_static/markdown/README.md`)
      .then((response) => response.text())
      .then((data) => setReadmeContent(data))
      .catch((error) => console.error("Error fetching readme.md:", error));
  }, []);

  const onCloseReadme = () => {
    setShowReadme(false);
  };

  const onFinishCountdown = () => {
    navigate("/quant/submissions/");
  };
  const currentDate = new Date();
  const showHeader = contestStartDate < currentDate;
  const gracePeriodEndDate = auth.contestGracePeriodEndDate;
  const hasGracePeriodEnded: boolean = new Date() > gracePeriodEndDate;

  if (auth.isAuthenticated) {
    return (
      <Header
        style={{
          background: "#000",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          padding: "0 16px",
          height: "90px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <a
            href="https://www.dytechlab.com/"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ width: "250px", margin: "auto" }}
              src={`${config.API_BASE_URL}/django_static/images/DTL_white_logo-01.png`}
              alt="Logo"
            />
          </a>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2 style={{ fontWeight: "bold", color: "#fff" }}>
            DTL Quant Challenge@2024
          </h2>
        </div>
        <div style={{ visibility: showHeader ? "visible" : "hidden" }}>
          <Countdown
            title=""
            value={contestEndDate}
            onFinish={onFinishCountdown}
            valueStyle={{
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
              fontSize: "20px",
            }}
            format="DD:HH:mm:ss"
          />
        </div>
        <div style={{ visibility: showHeader ? "visible" : "hidden" }}>
          <Link className="ant-btn-link" to="/quant/contest">
            <strong>Code Editor</strong>
          </Link>
        </div>
        <div style={{ visibility: showHeader ? "visible" : "hidden" }}>
          <Link className="ant-btn-link" to="/quant/submissions">
            <strong>Submissions</strong>
          </Link>
        </div>
        <div style={{ visibility: showHeader ? "visible" : "hidden" }}>
          <Link
            className="ant-btn-link"
            to={
              hasGracePeriodEnded
                ? "/quant/private_leaderboard"
                : "/quant/public_leaderboard"
            }
          >
            <strong>Leaderboard</strong>
          </Link>
        </div>
        <div style={{ visibility: showHeader ? "visible" : "hidden" }}>
          <span
            className="ant-btn-link"
            style={{ cursor: "pointer" }}
            onClick={() => setShowReadme((x) => !x)}
          >
            <strong>Readme</strong>
          </span>
        </div>
        <Drawer
          size="large"
          closable={true}
          onClose={onCloseReadme}
          open={showReadme}
          width="1500" // pick a width that can fit the content
        >
          <ReactMarkdown
            remarkPlugins={[remarkMath, remarkCollapse]}
            // @ts-ignore
            // rehypeRaw is needed for remarkCollapse (because remarkCollapse generates HTML within markdown)
            // our markdown source is trusted, so it is safe
            rehypePlugins={[rehypeHighlight, rehypeKatex, rehypeRaw]}
            components={{
              img(props) {
                const src = props.src?.replace(
                  "API_BASE_URL",
                  config.API_BASE_URL,
                );

                let width: number | undefined = undefined;
                let height: number | undefined = undefined;

                const pattern = new RegExp(/^ =(\d+)x(\d+)$/);
                const match = props.title?.match(pattern);
                if (match) {
                  width = Number(match[1]);
                  height = Number(match[2]);
                }

                return (
                  <figure className="figure">
                    <img
                      className="figure-img"
                      src={src}
                      alt={props.alt}
                      width={width}
                      height={height}
                    />
                    <figcaption>{props.alt ?? ""}</figcaption>
                  </figure>
                );
              },
            }}
          >
            {readmeContent}
          </ReactMarkdown>
        </Drawer>

        <div
          style={{ marginLeft: "20px", display: "flex", alignItems: "center" }}
        >
          <div
            style={{ fontWeight: "bold", color: "#fff", marginRight: "10px" }}
          >
            {auth.userName}
          </div>
          <Dropdown
            menu={{ items }}
            placement="bottom"
            arrow={{ pointAtCenter: true }}
          >
            <Avatar
              size={{ xs: 20, sm: 23, md: 28, lg: 35, xl: 45, xxl: 60 }}
              style={{ backgroundColor: "#4db8ff" }}
              icon={<UserOutlined />}
              onClick={() => {
                setShowLogOut(!showLogOut);
              }}
            />
          </Dropdown>
        </div>
      </Header>
    );
  } else {
    return (
      <Header
        style={{
          background: "#000",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          padding: "0 16px",
          height: "90px",
        }}
      >
        <a
          href="https://www.dytechlab.com/"
          style={{ marginRight: "0", marginLeft: "0", padding: "0" }}
        >
          <img
            style={{
              height: "200px",
              marginTop: "30px",
              marginBottom: "20px",
              marginRight: "0px",
              marginLeft: "0px",
              padding: "0",
            }}
            src={`${config.API_BASE_URL}/django_static/images/DTL_white_logo-01.png`}
            alt="Logo"
          />
        </a>
        <div>
          <h1 style={{ fontWeight: "bold", color: "#fff", margin: 0 }}>
            DTL Quant Challenge@2024
          </h1>
        </div>
      </Header>
    );
  }
}

// Modified version of: https://github.com/Rokt33r/remark-collapse/blob/master/index.js
// * Added support for converting ALL headings (remark-collapse stops after the first one).
function remarkCollapse(_: any) {
  const summarizeFn = (_: string) => "Click here to collapse/expand section";

  // https://github.com/syntax-tree/mdast-util-heading-range?tab=readme-ov-file#testfunction

  return (node: any) => {
    const seen = new Set();

    const testFn = (_: string, node: any) => {
      if (seen.has(node)) {
        return false;
      }

      const { depth } = node;
      return depth === 2;
    };

    let oldChildrenCount = node.children.length;

    // iteratively generate all the sections
    while (true) {
      heading(node, testFn, (start: any, nodes: any, end: any) => {
        seen.add(start);

        return [
          start,
          {
            type: "paragraph",
            children: [
              {
                type: "html",
                value: "<details open>",
              },
              {
                type: "html",
                value: "<summary>",
              },
              {
                type: "text",
                value: summarizeFn(toString(start)),
              },
              {
                type: "html",
                value: "</summary>",
              },
            ],
          },
          ...nodes,
          {
            type: "paragraph",
            children: [
              {
                type: "html",
                value: "</details>",
              },
            ],
          },
          end,
        ];
      });

      const newChildrenCount = node.children.length;
      if (newChildrenCount === oldChildrenCount) {
        // stop once there are no more changes
        break;
      }
      oldChildrenCount = newChildrenCount;
    }
  };
}
