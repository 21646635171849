import { CountdownProps, Input, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import {
  Alert,
  Card,
  Col,
  Row,
  Statistic,
  Switch,
  Table,
  Tabs,
  Tag,
} from "antd";
import Layout, { Content } from "antd/es/layout/layout";

import { Line } from "@ant-design/plots";
import { Button, Empty, Modal } from "antd";

import {
  CodeViewProps,
  ListViewProps,
  ObjectButton,
  SubmissionListItem,
  SubmissionResultViewProps,
  SubmissionTableViewProps,
} from "./models";
import "./styles.css";

import { python } from "@codemirror/lang-python";
import { indentUnit } from "@codemirror/language";
import { EditorView } from "@codemirror/view";
import { monokai } from "@uiw/codemirror-theme-monokai";
import CodeMirror from "@uiw/react-codemirror";
import { Panel, PanelGroup } from "react-resizable-panels";

import type { TabsProps } from "antd";

// for markdown for guidelines
import { AxiosError } from "axios";
import { AlignType } from "rc-table/lib/interface";
import { Link, useNavigate } from "react-router-dom";
import FooterDTL from "../../layout/footer";
import Header from "../../layout/header";
import axiosErrorHandler from "../../utilities/axios-error-handler";
import {
  insampleSubmission,
  outsampleSubmissionLeft,
  statusSubmissionTasks,
} from "../views/resources";

import { EditorState } from "@codemirror/state";
import { basicSetup } from "@uiw/codemirror-extensions-basic-setup";
import { SortOrder } from "antd/es/table/interface";
import moment from "moment";
import { useAuth } from "../../auth";
import { LineConfig } from "@ant-design/charts";

function getSorter<T>(field: keyof T) {
  return (a: T, b: T, sortOrder?: SortOrder) => {
    if (sortOrder === null || sortOrder === undefined) {
      // don't sort
      return 0;
    }

    const x = a[field];
    const y = b[field];

    const xNull = x === null || x === undefined;
    const yNull = y === null || y === undefined;

    // force null / undefined to always be at the bottom
    if (xNull && yNull) {
      return 0;
    } else if (xNull) {
      return sortOrder === "ascend" ? 1 : -1;
    } else if (yNull) {
      return sortOrder === "ascend" ? -1 : 1;
    }

    return Number(x) - Number(y);
  };
}

const { Countdown } = Statistic;
const CodeView: React.FC<CodeViewProps> = ({ props }) => {
  const auth = useAuth();

  const [code, setCode] = useState<string>();
  const [notes, setNotes] = useState<string>(props.data?.notes ?? "");
  const [submissionName, setSubmissionName] = useState<string>("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [
    isConfirmMarkAsFinalSubmissionModelOpen,
    setIsConfirmMarkAsFinalSubmissionModelOpen,
  ] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loadingButtonInsample, setLoadingButtonInsample] = useState(false);
  const [loadingButtonOutsample, setLoadingButtonOutsample] = useState(false);
  const [loadingButtonFinal, setLoadingButtonFinal] = useState(false);
  const [disableButtonOutsample, setDisableButtonOutsample] = useState(false);
  const [disableMarkAsFinalButton, setDisableMarkAsFinalButton] =
    useState(false);
  const [countSubmitOutsample, setCountSubmitOutsample] = useState<number>(0);

  const { outsampleSubmissionLeft: propsOutsampleSubmissionLeft } = props;
  const allowEdit = ["contest"].includes(props.view);

  const hasContestEnded: boolean = new Date() > auth.contestEndDate;
  const gracePeriodEndDate = auth.contestGracePeriodEndDate;
  const hasGracePeriodEnded: boolean = new Date() > gracePeriodEndDate;
  const isGracePeriod: boolean = hasContestEnded && !hasGracePeriodEnded;

  const getOutsampleSubmissionLeft = useCallback(async () => {
    outsampleSubmissionLeft
      .retrieve({})
      .then(({ data }) => {
        setCountSubmitOutsample(data.outsample_submission_left);
        if (propsOutsampleSubmissionLeft) {
          propsOutsampleSubmissionLeft(data.outsample_submission_left);
        }
        if (data?.outsample_submission_left <= 0) {
          setDisableButtonOutsample(true);
        }
      })
      .catch((err) => {
        axiosErrorHandler(err);
      });
  }, [propsOutsampleSubmissionLeft]);

  useEffect(() => {
    const savedCode = localStorage.getItem(`${props.username}Code`);
    const notes = localStorage.getItem(`${props.username}Notes`);
    const savedSubmissionName = localStorage.getItem(
      `${props.username}SubmissionName`,
    );
    const submissionName: string =
      savedSubmissionName ?? `${props.username}-${Date.now()}`;
    if (savedSubmissionName === null) {
      localStorage.setItem(`${props.username}SubmissionName`, submissionName);
    }

    if (savedCode && allowEdit) {
      setCode(savedCode);
    } else {
      setCode(props.default);
    }
    if (notes !== null && props.view === "contest") {
      setNotes(notes);
    }
    if (props.view === "submission" && props.data?.notes) {
      setNotes(props.data?.notes);
    }

    if (allowEdit) {
      setSubmissionName(submissionName);
    } else if (!allowEdit) {
      setSubmissionName(props.data?.name!);
    }
    getOutsampleSubmissionLeft();
  }, [
    getOutsampleSubmissionLeft,
    allowEdit,
    props.view,
    props.data?.name,
    props.data?.notes,
    props.default,
    props.username,
  ]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onClickUpdateNotes = () => {
    if (props.data?.id === undefined) {
      return;
    }

    insampleSubmission
      .postDetailAction({
        pk: props.data?.id,
        action: "update_notes",
        data: {
          notes: notes,
        },
      })
      .then(() => {
        message.success("Updated submission's notes.");
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const onClickMarkAsFinalSubmission = () => {
    if (hasGracePeriodEnded) {
      message.error(
        "Grace period is over! You are no longer allowed to amend your submissions.",
      );
      return;
    }

    setIsConfirmMarkAsFinalSubmissionModelOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = React.useCallback(
    (value: any, viewUpdate: any) => {
      setCode(value);
      localStorage.setItem(`${props.username}Code`, value);
      localStorage.setItem(
        `${props.username}SubmissionName`,
        `${props.username}-${Date.now()}`,
      );
    },
    [props.username],
  );
  EditorState.create({
    doc: "my source code",
    extensions: [
      basicSetup({
        foldGutter: false,
        dropCursor: false,
        allowMultipleSelections: false,
        indentOnInput: false,
        autocompletion: true,
      }),
    ],
  });

  async function markAsFinalSubmission(id: string, code: string, name: string) {
    if (hasGracePeriodEnded) {
      message.error(
        "Grace period is over! You are no longer allowed to amend your submissions.",
      );
      return;
    }

    setDisableMarkAsFinalButton(true);
    setLoadingButtonFinal(true);
    setDisableButton(true);

    if (name === "") {
      // `submissionName` was not populated, but should never happen
      message.error("Something went wrong, please reload the page.");
      return;
    }
    try {
      await insampleSubmission.postDetailAction({
        pk: id,
        action: "mark_as_final_submission",
        data: {
          code: code,
          name: submissionName,
        },
      });
      message.success("Submitted successfully!");
      setLoadingButtonFinal(false);
      setDisableButton(false);
    } catch (error) {
      setDisableMarkAsFinalButton(false);
      setDisableButton(false);
      console.error("Error submitting outsample submission: ", error);
      const err = error as AxiosError;
      axiosErrorHandler(err);
    }
  }

  async function submissionData(
    code: string,
    name: string,
    notes: string,
    typeSubmit: string,
  ) {
    if (name === "") {
      // `submissionName` was not populated, but should never happen
      message.error("Something went wrong, please reload the page.");
      return;
    }
    setDisableButton(true);
    let action: string;
    if (typeSubmit && typeSubmit === "outsample") {
      setLoadingButtonOutsample(true);
      setIsModalOpen(false);
      if (props.typeSubmit) {
        props.typeSubmit("outsample");
      }
      action = "submit_outsample";
    } else {
      setLoadingButtonInsample(true);
      if (props.typeSubmit) {
        props.typeSubmit("insample");
      }
      action = "submit";
    }
    if (props.loading_submit) {
      props.loading_submit(true);
    }
    try {
      const { data } = await insampleSubmission.postListAction({
        action: action,
        data: { code: code, notes: notes, name: submissionName },
      });

      setNotes("");
      localStorage.setItem(`${props.username}Notes`, "");

      message.success("Submitted successfully!");

      let idSubmission: string;
      idSubmission = data ? data.id : null;
      let taskId: string;
      taskId = data ? data.task_id : null;
      if (taskId) {
        const checkStatus = async () => {
          const { data } = await statusSubmissionTasks.getDetailAction({
            pk: String(taskId),
            action: "status",
          });
          let statusTask: boolean;
          statusTask = data ? data.success : false;
          if (statusTask) {
            const { data } = await insampleSubmission.retrieve({
              pk: String(idSubmission),
            });
            setDisableButton(false);
            if (props.loading_submit) {
              props.loading_submit(false);
            }
            if (props.dataSubmission) {
              props.dataSubmission(data);
            }
            if (props.backToResults) {
              props.backToResults();
            }
            setLoadingButtonInsample(false);
            setLoadingButtonOutsample(false);
            getOutsampleSubmissionLeft();
            return;
          }
          setTimeout(checkStatus, 3 * 1000);
        };

        checkStatus();
      }
    } catch (error) {
      if (props.loading_submit) {
        props.loading_submit(false);
      }
      setDisableButton(false);
      setLoadingButtonInsample(false);
      setLoadingButtonOutsample(false);
      console.error("Error submitting outsample sybmission: ", error);
      const err = error as AxiosError;
      axiosErrorHandler(err);
    }
  }

  function editCode() {
    if (!allowEdit) {
      localStorage.setItem(`${props.username}Code`, props.default!);
      localStorage.setItem(
        `${props.username}SubmissionName`,
        `${props.data?.name!}-${Date.now()}`,
      );
      localStorage.setItem(`${props.username}Notes`, props.data?.notes ?? "");
      navigate("/quant/contest");
    }
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {isGracePeriod && (
        <Row
          style={{
            // top padding of 0 to align with SubmissionTable page
            padding: "0 20px 20px 20px",
          }}
        >
          <Alert
            message={
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <div className="ant-alert-message">Grace Period</div>
                <Countdown
                  title=""
                  value={gracePeriodEndDate.getTime()}
                  onFinish={() => {
                    window.location.reload();
                  }}
                  valueStyle={{
                    fontSize: "16px",
                  }}
                  format="mm:ss [left]" // uses dayjs formatting: https://day.js.org/docs/en/display/format
                />
              </div>
            }
            description="This is your last chance to select or change your Final Submission."
            type="warning"
            showIcon
          />
        </Row>
      )}
      <CodeMirror
        value={code}
        theme={monokai}
        height={"100%"}
        extensions={[python(), EditorView.lineWrapping, indentUnit.of("    ")]}
        onChange={onChange}
        readOnly={!allowEdit}
        basicSetup={{
          syntaxHighlighting: true,
          lintKeymap: true,
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "5px",
            gap: "5px",
            height: "35px",
          }}
        >
          <label>Notes: </label>
          <Input
            style={{ width: "64ch" }}
            value={notes}
            onChange={(e) => {
              e.preventDefault();
              setNotes(e.target.value);
              if (props.view === "contest") {
                localStorage.setItem(`${props.username}Notes`, e.target.value);
              }
            }}
            placeholder="Notes for this submission (optional)"
          />
          {props.view === "submission" && (
            <Button
              style={{
                height: "35px",
                border: "none",
              }}
              type="primary"
              disabled={disableButton || hasContestEnded}
              onClick={() => onClickUpdateNotes()}
              iconPosition={"end"}
            >
              Update Notes
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "5px",
            gap: "5px",
            height: "35px",
          }}
        >
          {!hasGracePeriodEnded && props.data?.id && (
            <Button
              style={{
                height: "35px",
                border: "none",
              }}
              type="primary"
              disabled={
                props.data?.is_evaluate_submitted ||
                disableMarkAsFinalButton ||
                disableButton ||
                hasGracePeriodEnded
              }
              onClick={() => onClickMarkAsFinalSubmission()}
              loading={loadingButtonFinal}
              iconPosition={"end"}
            >
              Mark As Final Submission
            </Button>
          )}
          {!hasContestEnded && props.data?.submission_type !== "outsample" && (
            <Button
              style={{
                height: "35px",
                border: "none",
              }}
              type="primary"
              disabled={disableButton}
              onClick={() => showModal()}
              loading={loadingButtonOutsample}
              iconPosition={"end"}
            >
              Submit Outsample
            </Button>
          )}
          {!hasContestEnded && props.view === "contest" && (
            <Button
              style={{
                height: "35px",
                border: "none",
              }}
              disabled={disableButton}
              type="primary"
              onClick={() =>
                submissionData(String(code), submissionName, notes, "insample")
              }
              loading={loadingButtonInsample}
              iconPosition={"end"}
            >
              Run Insample
            </Button>
          )}
          {!hasContestEnded && props.view === "submission" && (
            <Button
              style={{
                height: "35px",
                border: "none",
              }}
              type="primary"
              onClick={editCode}
              disabled={disableButton}
            >
              Reuse This Code
            </Button>
          )}
        </div>
      </div>
      {!hasContestEnded && (
        <div style={{ padding: "0px 5px" }}>
          {countSubmitOutsample > 0 ? (
            <p
              style={{
                textAlign: "right",
                margin: "0px 0px 15px 0px",
                color: "#999",
              }}
            >
              You have {countSubmitOutsample} outsample submission(s) remaining.
            </p>
          ) : (
            <p
              style={{
                textAlign: "right",
                margin: "0px 0px 15px 0px",
                color: "#999",
              }}
            >
              You have no more Outsample submissions remaining.
            </p>
          )}
        </div>
      )}
      <Modal
        title="Submit To Outsample"
        open={isModalOpen}
        onOk={() =>
          submissionData(String(code), submissionName, notes, "outsample")
        }
        onCancel={handleCancel}
        okText={"Yes, Submit"}
        cancelText={"No, Cancel"}
        okButtonProps={{ disabled: disableButtonOutsample }}
      >
        {disableButtonOutsample ? (
          <div>
            <p>You have no more Outsample submissions remaining.</p>
          </div>
        ) : (
          <div>
            <p>
              You have {countSubmitOutsample} outsample submission(s) remaining.
            </p>
            <p>Do you still want to submit?</p>
          </div>
        )}
      </Modal>
      <Modal
        title="Confirm Mark As Final Submission"
        open={isConfirmMarkAsFinalSubmissionModelOpen}
        onOk={() =>
          markAsFinalSubmission(
            String(props.data?.id),
            String(code),
            submissionName,
          )
            .then(() => setIsConfirmMarkAsFinalSubmissionModelOpen(false))
            .catch(() => setIsConfirmMarkAsFinalSubmissionModelOpen(false))
        }
        onCancel={() => setIsConfirmMarkAsFinalSubmissionModelOpen(false)}
        okText={"Yes, Submit"}
        cancelText={"No, Cancel"}
      >
        {props.data?.is_outsample_submitted ? (
          <p>Confirm submission?</p>
        ) : (
          <p>
            This submission has not been tested in outsample. It could perform
            poorly in full data due to overfitting in insample. Mark this as
            final only if you are sure about its performance
          </p>
        )}
      </Modal>
    </div>
  );
};

const SubmissionResultView: React.FC<SubmissionResultViewProps> = ({
  props,
}) => {
  const [data, setData] = useState<
    { day_index: number; cumulative_returns: number }[]
  >([]);
  useEffect(() => {
    if (props.cumpnl) {
      const chartData = props.cumpnl.map((value, index) => ({
        day_index: index + 1,
        cumulative_returns: Number(value.toFixed(5)),
      }));
      setData(chartData);
    } else {
      setData([]);
    }
  }, [props.cumpnl]);

  const chartConfig: LineConfig = {
    data,
    xField: "day_index",
    yField: "cumulative_returns",
    xAxis: {
      alias: "Date Index",
      title: {}, // a non-null value will let display the title
      tickCount: 1,
    },
    yAxis: {
      alias: "Cumulative Returns %",
      title: {}, // a non-null value will let display the title
    },
    slider: {
      start: 0.0,
      end: 1.0,
    },
    height: 500,
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "SCORE",
      children: (
        <Card
          bodyStyle={{ height: "100%" }}
          style={{
            color: "green",
            fontWeight: "bold",
            height: "100%",
          }}
        >
          {props.score?.toFixed(5)}
        </Card>
      ),
      style: { height: "100%" },
    },
    {
      key: "2",
      label: "STDOUT",
      children: (
        <Card
          bodyStyle={{ height: "100%" }}
          style={{ color: "gray", height: "100%" }}
        >
          {props.stdout}
        </Card>
      ),
      style: { height: "100%" },
    },
    {
      key: "3",
      label: "STDERR",
      children: (
        <Card
          bodyStyle={{ height: "100%" }}
          style={{ color: "red", height: "100%", overflow: "auto" }}
        >
          {props.stderr}
        </Card>
      ),
      style: { height: "100%" },
    },
  ];

  function SubmissionRunning() {
    return (
      <p style={{ paddingLeft: "10px" }}>
        The code is still running. You may want to refresh the page for the
        result.
      </p>
    );
  }

  return (
    <PanelGroup
      autoSaveId="example"
      direction="vertical"
      style={{ overflow: "hidden !important", height: "auto !important" }}
    >
      {props?.title && (
        <h3 style={{ backgroundColor: "#fff", padding: "10px" }}>
          {props?.title}
        </h3>
      )}
      {props.is_running ? (
        <SubmissionRunning />
      ) : (
        <>
          {props.has_data && (
            <>
              <Panel
                defaultSize={80}
                style={{ maxHeight: "500px", overflow: "inherit !important" }}
              >
                {props.score && (
                  <h4
                    style={{
                      color: "#25A3D8",
                      marginTop: "0px",
                      paddingLeft: "20px",
                    }}
                  >
                    Score: {props.score?.toFixed(5)}
                  </h4>
                )}
                <Card style={{ height: "100%" }} bodyStyle={{ height: "100%" }}>
                  {data.length ? (
                    <Line {...chartConfig} />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Card>
              </Panel>

              <Panel style={{ overflow: "inherit !important" }}>
                <Card bodyStyle={{ height: "100%" }} style={{ height: "100%" }}>
                  {props?.show_log && (
                    <div>
                      <h4>Console log</h4>
                      <div className="display-linebreak scrollable monospace">
                        {props.stdout}
                        {props.stderr}
                      </div>
                    </div>
                  )}
                  {props?.show_tab && (
                    <Tabs
                      style={{ height: "100%" }}
                      items={items}
                      defaultActiveKey="1"
                    />
                  )}
                </Card>
              </Panel>
            </>
          )}
        </>
      )}
    </PanelGroup>
  );
};

const CountDownView: React.FC<ListViewProps> = ({ props }) => {
  const deadline: any = props.deadline;

  const onFinish: CountdownProps["onFinish"] = () => {
    // reload the page here
    window.location.reload();
  };
  return (
    <Layout style={{ height: "100vh" }}>
      <Header></Header>
      <Content
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row justify="center">
          <Col span={24}>
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#777",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              Contest will start in
            </h1>
            <Countdown
              title=""
              value={deadline}
              onFinish={onFinish}
              valueStyle={{
                fontSize: "96px",
                fontWeight: "bold",
                color: "#777",
                textAlign: "center",
              }}
              format="HH:mm:ss"
            />
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#777",
                textAlign: "center",
                marginBottom: "24px",
              }}
            >
              All the best!
            </h1>
          </Col>
        </Row>
      </Content>
      <FooterDTL />
    </Layout>
  );
};

const ErrorView: React.FC<ListViewProps> = ({ props }) => {
  return (
    <Alert
      message="Error Text"
      showIcon
      closable
      description="Error Description Error Description Error Description Error Description"
      type="error"
      action={
        <Button size="small" danger>
          Detail
        </Button>
      }
    />
  );
};

const StatusTransformTable: { [key: string]: string } = {
  "Complete Insample": "Insample Completed",
  "Complete Outsample": "Outsample Completed",
  "Complete Evaluate": "Evaluate Completed",
};

const SubmissionTableView: React.FC<SubmissionTableViewProps> = ({ props }) => {
  const auth = useAuth();

  const [countSubmitOutsample, setCountSubmitOutsample] = useState<number>(0);
  const [outsampleSubmission, setOutsampleSubmission] = useState<{
    id: string;
    code: string;
    name: string;
  } | null>(null);
  const [modalFinalVisible, setModalFinalVisible] = useState(false);
  const [objectButton, setObjectButton] = useState<ObjectButton>({});
  const [dataSubmit, setDataSubmit] = useState<{
    id: string;
    code: string;
    name: string;
    is_outsample_submitted: boolean;
  }>();
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const navigate = useNavigate();

  const hasContestEnded: boolean = new Date() > auth.contestEndDate;
  const gracePeriodEndDate = auth.contestGracePeriodEndDate;
  const hasGracePeriodEnded: boolean = new Date() > gracePeriodEndDate;
  const isGracePeriod: boolean = hasContestEnded && !hasGracePeriodEnded;

  const handleSwitch = (value: boolean): void => {
    setCheckedSwitch(value);
    if (value) {
      const params = { is_outsample_submitted: true };
      fetchData(params);
    } else {
      fetchData();
    }
  };

  const handleSubmitOutsample = () => {
    if (outsampleSubmission === null) {
      return;
    }

    insampleSubmission
      .postListAction({
        action: "submit_outsample",
        data: {
          code: outsampleSubmission.code,
          name: outsampleSubmission.name,
        },
      })
      .then((_) => {
        message.success("Submitted outsample successfully!");

        navigate(`/quant/submissions/${outsampleSubmission.id}`);
      })
      .catch((err) => {
        axiosErrorHandler(err);
      });
  };

  const handleMarkFinalSubmissionButton = (
    id: string,
    code: string,
    name: string,
    is_outsample_submitted: boolean,
  ): void => {
    if (hasGracePeriodEnded) {
      message.error(
        "Grace period is over! You are no longer allowed to amend your submissions.",
      );
      return;
    }

    setModalFinalVisible(true);
    setDataSubmit((prevState) => ({
      ...prevState,
      id: id,
      code: code,
      name: name,
      is_outsample_submitted: is_outsample_submitted,
    }));
  };

  const setCloseModal = () => {
    setObjectButton({});
    setModalFinalVisible(false);
  };

  async function MarkAsFinalSubmission() {
    setModalFinalVisible(false);

    if (hasGracePeriodEnded) {
      message.error(
        "Grace period is over! You are no longer allowed to amend your submissions.",
      );
      return;
    }

    setLoading(true);
    if (dataSubmit && dataSubmit.id) {
      let idSubmission: string;
      idSubmission = dataSubmit.id;
      setObjectButton({
        [idSubmission]: {
          loading: true,
          disable: true,
        },
      });
    }

    try {
      await insampleSubmission.postDetailAction({
        pk: dataSubmit?.id,
        action: "mark_as_final_submission",
        data: {
          code: dataSubmit?.code,
          name: dataSubmit?.name,
        },
      });

      fetchData();
      setObjectButton({});
    } catch (error) {
      console.error("Error submitting outsample sybmission: ", error);
      const err = error as AxiosError;
      axiosErrorHandler(err);
    }
  }

  const columns = [
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string, record: { id: string }) => (
        <Link to={`/quant/submissions/${record.id}`}>
          {moment(text).format("YYYY-MM-DD HH:mm:ss")}
        </Link>
      ),
      align: "left" as AlignType,
      fixed: "left" as const,
    },
    {
      title: "Status",
      dataIndex: "submission_status",
      key: "submission_status",
      render: (text: SubmissionListItem["submission_status"]) => {
        if (text === undefined) {
          return "Submitted";
        }

        // to human-readable title case
        const status = text
          .replaceAll("_", " ")
          .replaceAll(
            /\w\S*/g,
            (text) =>
              text.charAt(0).toUpperCase() + text.substring(1).toLowerCase(),
          );

        return StatusTransformTable[status] ?? status;
      },
    },
    {
      title: "Insample Runtime",
      dataIndex: "runtime",
      key: "runtime",
      align: "left" as AlignType,
      render: (runtime: string) => {
        if (runtime) return Number(runtime).toFixed(3) + " s";
        return "NA";
      },
      sorter: getSorter<SubmissionListItem>("runtime"),
    },
    {
      title: "Insample Score",
      dataIndex: "score",
      key: "score",
      align: "left" as AlignType,
      render: (score: number) => {
        if (score !== undefined && score !== null) return score.toFixed(3);
        return "NA";
      },
      sorter: getSorter<SubmissionListItem>("score"),
    },
    {
      title: "Outsample Runtime",
      dataIndex: "outsample_runtime",
      key: "outsample_runtime",
      align: "left" as AlignType,
      render: (runtime: string, record: SubmissionListItem) => {
        if (
          record.submission_status !== "failed" &&
          record.submission_type !== "outsample"
        ) {
          if (
            hasContestEnded ||
            record.submission_status !== "complete_insample"
          ) {
            return "";
          }

          return (
            <Button
              style={{
                border: "none",
                width: "100%",
                height: "100%",
                textWrap: "wrap",
              }}
              type="primary"
              size={"small"}
              onClick={() =>
                setOutsampleSubmission({
                  id: record.id,
                  code: record.code,
                  name: record.name,
                })
              }
              loading={objectButton[record.id]?.loading}
              iconPosition={"end"}
              disabled={
                objectButton[record.id]?.disable || countSubmitOutsample <= 0
              }
            >
              Submit Outsample
            </Button>
          );
        }

        if (runtime) return Number(runtime).toFixed(3) + " s";
        return "NA";
      },
      sorter: getSorter<SubmissionListItem>("outsample_runtime"),
    },
    {
      title: "Outsample Score",
      key: "outsample_score",
      dataIndex: "outsample_score",
      render: (outsample_score: number, record: SubmissionListItem) => {
        if (
          record.submission_status !== "failed" &&
          record.submission_type !== "outsample"
        ) {
          return "";
        }

        if (outsample_score !== null && outsample_score !== undefined)
          return outsample_score.toFixed(3);
        return "NA";
      },
      sorter: getSorter<SubmissionListItem>("outsample_score"),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      // width: "64ch", // this gets applied to the col in colgroup attributes (not CSS style) -> this is effectively minimum width of that column
      textWrap: "word-break",
      ellipsis: true,
      onHeaderCell: () => {
        // applies attributes on the `th`
        // * used for adding max-width

        const maxNotesLength = Math.max(
          ...tableData.map((x) => x.notes.length),
        );
        const width = Math.min(maxNotesLength, 64);
        if (width < 24) {
          // don't force the width
          return {};
        }

        return { style: { width: `${width}ch` } };
      },
    },
    {
      title: "Is Final Submission",
      key: "is_evaluate_submitted",
      dataIndex: "is_evaluate_submitted",
      render: (_: any, record: SubmissionListItem) => {
        return record.is_evaluate_submitted ? (
          <Tag color={"green"}>Yes</Tag>
        ) : hasGracePeriodEnded ? (
          <span></span>
        ) : (
          <Button
            style={{
              border: "none",
              width: "100%",
              height: "100%",
              textWrap: "wrap",
            }}
            type="primary"
            size={"small"}
            onClick={() =>
              handleMarkFinalSubmissionButton(
                record.id,
                record.code,
                record.name,
                record.is_outsample_submitted ?? false,
              )
            }
            loading={objectButton[record.id]?.loading}
            iconPosition={"end"}
            disabled={objectButton[record.id]?.disable}
          >
            Mark As Final Submission
          </Button>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState<SubmissionListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (params?: object) => {
    setLoading(true);
    try {
      const { data } = await insampleSubmission.getListAction({
        action: "user_submissions",
        params,
      });
      setTableData(data);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      const err = error as AxiosError;
      axiosErrorHandler(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCountSubmitOutsample = () => {
    outsampleSubmissionLeft
      .retrieve({})
      .then(({ data }) => {
        setCountSubmitOutsample(data.outsample_submission_left);
      })
      .catch((err) => {
        axiosErrorHandler(err);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCountSubmitOutsample();
  }, []);

  return (
    <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      {isGracePeriod && (
        <Row style={{ padding: "20px" }}>
          <Alert
            message={
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                }}
              >
                <div className="ant-alert-message">Grace Period</div>
                <Countdown
                  title=""
                  value={gracePeriodEndDate.getTime()}
                  onFinish={() => {
                    window.location.reload();
                  }}
                  valueStyle={{
                    fontSize: "16px",
                  }}
                  format="mm:ss [left]" // uses dayjs formatting: https://day.js.org/docs/en/display/format
                />
              </div>
            }
            description="This is your last chance to select or change your Final Submission."
            type="warning"
            showIcon
          />
        </Row>
      )}
      <Row style={{ padding: "20px" }}>
        <Switch
          checkedChildren="Outsample Submissions"
          unCheckedChildren="All Submissions"
          defaultChecked={checkedSwitch}
          onChange={() => handleSwitch(!checkedSwitch)}
        />
      </Row>
      <Table
        loading={loading}
        columns={columns}
        pagination={{ position: ["bottomCenter"], pageSize: 20 }}
        dataSource={tableData}
        rowKey={(row) => row.id}
        tableLayout="fixed"
      />
      <Modal
        title="Submit To Outsample"
        open={outsampleSubmission !== null}
        onOk={handleSubmitOutsample}
        onCancel={() => setOutsampleSubmission(null)}
        okText={"Yes, Submit"}
        cancelText={"No, Cancel"}
        okButtonProps={{ disabled: countSubmitOutsample <= 0 }}
      >
        {countSubmitOutsample <= 0 ? (
          <div>
            <p>You have no more Outsample submissions remaining.</p>
          </div>
        ) : (
          <div>
            <p>
              You have {countSubmitOutsample} outsample submission(s) remaining.
            </p>
            <p>Do you still want to submit?</p>
          </div>
        )}
      </Modal>
      <Modal
        title="Confirm Mark As Final Submission"
        open={modalFinalVisible}
        onOk={() => MarkAsFinalSubmission()}
        onCancel={() => setCloseModal()}
        okText={"Yes, Submit"}
        cancelText={"No, Cancel"}
      >
        {dataSubmit?.is_outsample_submitted ? (
          <p>Confirm submission?</p>
        ) : (
          <p>
            This submission has not been tested in outsample. It could perform
            poorly in full data due to overfitting in insample. Mark this as
            final only if you are sure about its performance
          </p>
        )}
      </Modal>
    </div>
  );
};

export {
  CodeView,
  CountDownView,
  ErrorView,
  SubmissionResultView,
  SubmissionTableView,
};
