import { restful } from "../utilities";

import { IBasicAuth, IToken, IRegistrationData } from "./models";

export const basicAuthRestful = restful<IBasicAuth>("login", "api/users");
export const basicLogoutRestful = restful<IRegistrationData>(
  "logout",
  "api/users",
);
export const tokenRestful = restful<IToken>("user", "api/users");
export const registerRestful = restful<IRegistrationData>(
  "register",
  "api/users",
);

export const registrationDetailsRestful = restful<null>("config_entry", "api");
