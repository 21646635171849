import { restful } from "../../utilities";

import { SubmissionProps, ContestFeedback } from "./models";

export const insampleSubmission = restful<SubmissionProps>(
  "submissions",
  "api",
);
export const statusSubmissionTasks = restful<SubmissionProps>("tasks", "api");
export const outsampleSubmissionLeft = restful<SubmissionProps>(
  "outsample_submission_left",
  "api",
);
export const contestFeedbackSubmission = restful<ContestFeedback>(
  "feedback",
  "api/contest",
);
