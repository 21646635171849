import { restful } from "../../utilities";

import { Leaderboard } from "./models";

export const publicLeaderboard = restful<Leaderboard>(
  "public_leaderboard",
  "api",
);
export const privateLeaderboard = restful<Leaderboard>(
  "private_leaderboard",
  "api",
);
