import { Layout } from "antd";
import "./default.css";

const { Footer } = Layout;

export default function FooterDTL() {
  return (
    <Footer
      style={{
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        height: "50px",
      }}
    >
      <span>DTL © 2024 Created by DTL</span>
    </Footer>
  );
}
