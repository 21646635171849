import React from "react";
import { Table, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { Leaderboard } from "../views/models";
import { LeaderboardProps } from "./models";
import "./styles.css";

const columns: ColumnsType<Leaderboard> = [
  {
    title: "Username",
    dataIndex: "owner",
    key: "owner",
    render: (text, record) => <Space>{text}</Space>,
    align: "left" as const,
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
    align: "left" as const,
    render: (score: number) => {
      if (score !== undefined && score !== null) return score.toFixed(3);
      return "NA";
    },
  },
  {
    title: "Rank",
    dataIndex: "rank",
    key: "rank",
    align: "left" as const,
  },
];

const LeaderboardView: React.FC<LeaderboardProps> = ({ data }) => {
  const rowClassName = (record: Leaderboard) => {
    return record.is_owner ? "user-row" : "";
  };
  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={data}
      rowClassName={rowClassName}
      rowKey={(row) => row.rank.toString()}
    />
  );
};

export { LeaderboardView };
