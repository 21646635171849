import { ConfigProvider } from "antd";
import axios from "axios";
import React from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import { AuthProvider, RegisterProvider, useAuth } from "./auth";
import View404 from "./layout/error-codes";
import LogInPage from "./layout/login";
import RegistrationPage from "./layout/registration";
import {
  PrivateLeaderboard,
  PublicLeaderboard,
} from "./leaderboard/views/views";
import {
  ContestView,
  SubmissionList,
  SubmissionView,
} from "./submission/views/views";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

axios.interceptors.request.use(function (config) {
  return config;
});

function PrivateRoute({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  if (auth.isAuthenticated == null) {
    return <div />;
  }

  if (!auth.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={`/login`} state={{ from: location }} replace />;
  }

  return children;
}

const App: React.FC = () => {
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          colorPrimary: "#38A4D2",
        },
      }}
    >
      <AuthProvider>
        <RegisterProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/quant">
                <Route path="" element={<Navigate to="login" replace />} />
                <Route path="login" element={<LogInPage />} />
                <Route path="register" element={<RegistrationPage />} />
                <Route
                  path={``}
                  element={
                    <PrivateRoute>
                      <Outlet />
                    </PrivateRoute>
                  }
                >
                  <Route path="contest" element={<ContestView />} />
                  <Route
                    path="public_leaderboard"
                    element={<PublicLeaderboard />}
                  />
                  <Route
                    path="private_leaderboard"
                    element={<PrivateLeaderboard />}
                  />
                  <Route path="submissions" element={<SubmissionList />} />
                  <Route path="submissions/:id" element={<SubmissionView />} />
                </Route>
                <Route path="*" element={<View404 />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/quant/login" replace />}
              />
            </Routes>
          </BrowserRouter>
        </RegisterProvider>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
