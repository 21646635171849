import { Button, Checkbox, Form, Input, Layout, Spin } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { IBasicAuth } from "../auth/models";

import { useAuth } from "../auth";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import HeaderDTL from "./header";
import FooterDTL from "./footer";

const { Content } = Layout;

const App: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const from = Object(location.state)?.from?.pathname || "/quant/contest";

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [navigate, from, auth.isAuthenticated]);

  const onFinish = (values: IBasicAuth) => {
    auth.signin(
      values,
      () => setLoading(true),
      () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        setLoading(false);
        if (auth.isAuthenticated) {
          navigate(from, { replace: true });
        }
      },
    );
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <HeaderDTL />
      <Spin spinning={loading} />
      <Content>
        <div className="login-page">
          <div className="login-box">
            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <div className="already-signed-in">
                Haven't registered yet?{" "}
                <Link to="/quant/register" className="login-link">
                  Register
                </Link>
              </div>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  SUBMIT
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Content>
      <FooterDTL />
    </Layout>
  );
};

export default App;
